import React, { useState } from "react";
import { APIConfig } from "../../utils/constants/api.constants";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import GridLoader from "../common/GridLoader";
import { getLabelFromEnum } from "../../utils/helpers";

const UserCouponLogs = ({ mobileNumber, couponCategory }) => {
  const baseUrl =
    process.env.REACT_APP_PRICING_BASE_URL_NEW +
    APIConfig.couponManagement.getCustomerCouponLogs;
  const [couponLogs, setCouponLogs] = useState({
    data: [],
    total: 0,
  });

  const [dataState, setDataState] = useState({
    take: 10,
    skip: 0,
    otherParams: {
      mobileNumber,
      couponCategory,
    },
  });
  let columns;
  if (couponCategory != "C2B_COUPON") {
    columns = [
      {
        id: "couponName",
        field: "couponName",
        title: "Coupon Name",
      },
      {
        id: "issuedDate",
        field: "issuedDate",
        title: "Issue Date",
      },
      {
        id: "status",
        field: "status",
        title: "Status",
      },
      {
        id: "amountRedeemed",
        field: "amountRedeemed",
        title: "Amount Redeemed",
      },
      {
        id: "redemptionDate",
        field: "redemptionDate",
        title: "Redemption Date",
      },
      {
        id: "reason",
        field: "reason",
        title: "Reason",
      },
      {
        id: "subReason",
        field: "subReason",
        title: "Sub Reason",
      },
      {
        id: "issuedBy",
        field: "issuedBy",
        title: "Issued By",
      },
    ];
  } else {
    columns = [
      {
        id: "couponName",
        field: "couponName",
        title: "Coupon Name",
      },
      {
        id: "issuedDate",
        field: "issuedDate",
        title: "Issue Date",
      },
      {
        id: "status",
        field: "status",
        title: "Status",
      },
      {
        id: "amountRedeemed",
        field: "amountRedeemed",
        title: "Amount Redeemed",
      },
      {
        id: "redemptionDate",
        field: "redemptionDate",
        title: "Redemption Date",
      },
      {
        id: "issuedBy",
        field: "issuedBy",
        title: "Issued By",
      },
    ];
  }

  const dataStateChange = (e) => {
    setDataState({
      ...e.dataState,
      otherParams: {
        mobileNumber,
        couponCategory,
      },
    });
  };
  const dataReceived = (dataObj) => {
    const updatedLogs = dataObj.data?.data.map((log) => {
      const updatedLogDetails = getLabelFromEnum(
        ["reason", "subReason"],
        log,
        "coupon"
      );
      return updatedLogDetails;
    });
    setCouponLogs({ ...dataObj, data: updatedLogs });
  };
  return (
    <div className="trip-details-table">
      <Grid
        filterable={false}
        sortable={false}
        pageable={true}
        {...dataState}
        data={couponLogs}
        onDataStateChange={dataStateChange}
      >
        {columns.map((column) => (
          <Column
            key={column?.id}
            field={column?.field}
            title={column?.title}
          />
        ))}
      </Grid>
      <GridLoader
        baseUrl={baseUrl}
        columns={columns}
        dataState={dataState}
        onDataReceived={dataReceived}
      />
    </div>
  );
};

export default UserCouponLogs;
