import React, { useEffect, useState } from "react";
import CardLayout from "../components/common/CardLayout";
import DriverProfilesTable from "../components/driver-management/driver-profiles/DriverProfilesTable";
import { useLocation, useParams } from "react-router-dom";
import DriverManagementNavigation from "../components/driver-management/DriverManagementNavigation";
import { useDispatch, useSelector } from "react-redux";
import { updateDriverRegionFilter } from "../slices/regionSlice";
import { getFromLocalStorage } from "../utils/helpers";
import { DropdownSearchSingleSelect } from "../utils/forms/NewFormComponents";

const DriverManagement = () => {
  const location = useLocation();
  const { driverId } = useParams();
  const regions = useSelector((state) => state.region?.mainRegions);
  const selectedDriverRegionFilters = useSelector(
    (state) => state.region?.driver
  );
  const dispatch = useDispatch();

  const [dataState, setDataState] = useState({
    take: 10,
    skip: 0,
    otherParams:
      getFromLocalStorage("regionFilters") &&
      getFromLocalStorage("regionFilters")?.driver
        ? {
            region_id:
              getFromLocalStorage("regionFilters")?.driver?.regionId +
              ":in:string",
          }
        : {},
  });
  const onDriverRegionFilterChange = (e) => {
    dispatch(updateDriverRegionFilter(e));
    setDataState({
      ...dataState,
      skip: 0,
      take: 10,
      otherParams: {
        region_id: e.regionId + ":in:string",
        onboarding_stage: "ONBOARDING_FORM_COMPLETE:eq:string",
        bgv_status: "SUCCESS:eq:string",
        test_drive_status: "SUCCESS:eq:string",
      },
    });
  };
  const updatedRegions = regions?.slice()?.map((item, index) => ({
    ...item,
    label: item.child
      ? `${item.regionName} (${item.parentRegionName})`
      : item.regionName,
  }));

  const DriverRegionFilterDiv = () => (
    <div>
      <DropdownSearchSingleSelect
        options={updatedRegions}
        textField="label"
        onChange={onDriverRegionFilterChange}
        value={selectedDriverRegionFilters}
        backupMessage="No Options Available"
        parentClass="tw-relative tw-w-64"
      />
    </div>
  );

  return (
    <CardLayout
      title="Driver Management"
      ComponentRender={DriverRegionFilterDiv}
    >
      {driverId &&
      ["profile", "account", "online-activity"].includes(
        location.pathname.split("/").at(-1)
      ) ? (
        <DriverManagementNavigation />
      ) : (
        <DriverProfilesTable
          dataState={dataState}
          setDataState={setDataState}
        />
      )}
    </CardLayout>
  );
};

export default DriverManagement;
