import React from 'react'
import CardLayout from '../components/common/CardLayout'
import BulkOperationsHomePage from '../components/bulk-operations/BulkOperationsHomePage';

const BulkOperationsManagement = () => {
    return (
        <CardLayout title="Bulk Operations">
            <BulkOperationsHomePage />
        </CardLayout>
    )
}

export default BulkOperationsManagement;