import { Field, Form, FormElement } from "@progress/kendo-react-form";
import React, { useEffect, useState } from "react";
import {
  FormMultiSelect,
  FormNumericTextBox,
} from "../../../../utils/forms/FormComponents";
import { Button } from "@progress/kendo-react-buttons";
import LoadingSpinner from "../../../common/LoadingSpinner";
import { DriverConfig } from "../../../../config/driverConfig";
import axiosService from "../../../../init/axios";
import { APIConfig } from "../../../../utils/constants/api.constants";
import { showErrorMessage, showToastMessage } from "../../../../utils/helpers";

const DriverAdditionalDetails = ({ driverId, driverDetails }) => {
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (dataItem) => {
    setLoading(true);
    const languages = [];
    if(dataItem?.driverKnownLanguages){
        dataItem?.driverKnownLanguages?.forEach(lang => {
            languages.push(lang?.value);
        });
    }
    const formValues = {
      languagesKnown: languages,
      experienceDetails: {
        years : dataItem?.driverExperienceYears || 0,
        months : dataItem?.driverExperienceMonths || 0
      },
    };
    await axiosService
      .put(
        process.env.REACT_APP_DRIVER_BASE_URL +
          APIConfig.driverManagement.saveDriverAdditionalDetails(driverId),
          JSON.stringify(formValues)
      )
      .then((data) => {
        setLoading(false);
        showToastMessage("Driver details updated successfully");
      })
      .catch((error) => {
        setLoading(false);
        showErrorMessage(error);
      });
  };

  return (
    <div className="driver-additional-details-tab">
      <div className="row">
        <div className="col-sm-6">
          <Form
            onSubmit={handleSubmit}
            initialValues={driverDetails}
            key={JSON.stringify(driverDetails)}
            render={(formRenderProps) => (
              <FormElement
                style={{
                  width: "100%",
                  background: "#fafafa",
                  padding: "15px",
                  border: "1px solid rgba(0, 0, 0, 0.08)",
                }}
              >
                <Field
                  id={"driverKnownLanguages"}
                  name={"driverKnownLanguages"}
                  label={"Known Languages"}
                  component={FormMultiSelect}
                  data={DriverConfig.languages}
                  textField="label"
                  dataItemKey="value"
                />
                <div className="row">
                  <div className="col-sm-6">
                  <Field
                    id={"driverExperienceYears"}
                    name={"driverExperienceYears"}
                    label={"Experience (Years)"}
                    component={FormNumericTextBox}
                    defaultValue={0}
                    />
                  </div>
                  <div className="col-sm-6">
                  <Field
                    id={"driverExperienceMonths"}
                    name={"driverExperienceMonths"}
                    label={"(Months)"}
                    component={FormNumericTextBox}
                    defaultValue={0}
                    />
                  </div>
                </div>
                <div className="k-form-buttons">
                  <Button
                    themeColor={"primary"}
                    type={"submit"}
                    disabled={
                      !formRenderProps.valid ||
                      !formRenderProps.modified ||
                      loading
                    }
                  >
                    {loading ? (
                      <LoadingSpinner width={25} height={25} />
                    ) : (
                      "Save"
                    )}
                  </Button>
                </div>
              </FormElement>
            )}
          />
        </div>
      </div>
    </div>
  );
};

export default DriverAdditionalDetails;
