import React from "react";
import { OverlayView } from "@react-google-maps/api";

const CustomMarkerFleetView = ({ position, onClick, children, isClicked, zIndex }) => {
  return (
    <OverlayView
      position={position}
      mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
    >
      <div
        className={`tw-w-fit tw-block ${isClicked ? "clicked" : ""}`}
        onClick={onClick}
        style={{ cursor: "pointer", zIndex: zIndex }}
      >
        {children}
      </div>
    </OverlayView>
  );
};

export default CustomMarkerFleetView;
