import React, { useState, useEffect, useMemo } from "react";
import DriverUniformAdherenceImage from "./DriverUniformAdherenceImage";
import {
  convertEnumToLabel,
  convertTo12HourFormat,
} from "../../../utils/helpers";
import { ACTIVITY_ACCORDIAN_TYPES } from "../../../config/driverConfig";
import { TreeNode } from "./TreeNode";


const createTripDetails = ({ trip }) => {
  return {
    tripDetails:
    {
      start: {
        title: trip.actualTripStartTime
          ? "Trip started at"
          : "Expected trip start time",
        value: convertTo12HourFormat(trip.actualTripStartTime || trip.expectedTripStartTime
        )
      },
      end: {
        title: trip.actualTripEndTime
          ? "Trip ended at"
          : "Expected trip end time",
        value: convertTo12HourFormat(trip.actualTripEndTime || trip.forecastedEndTime || trip.expectedTripEndTime)
      }
    }
  }
}

const createDriverReachingInfo=({trip})=>{

   return {
    driverReachingInfo: {
      start: {
        title: 'Driver started at',
        value: convertTo12HourFormat(
          trip.driverStartedAtTime
        ),
      },
      end: {
        title: 'Driver reached at',
        value: convertTo12HourFormat(
          trip.driverReachedAtTime
        ),
      },
    }
  }
}


export default function DriverAccordionNew({
  sessionDetails = {},
  isUpcoming = false,
  index,
  isOpen,
  onClick,
  getSelectedTrip,
  setActualPathToggle,
  openChild,
  setOpenChild,
}) {
  const {
    sessionStartTime,
    sessionEndTime,
    trips = [],
    imageUrl,
    wearingUniform = false,
  } = sessionDetails;

  const sessionInfo = useMemo(() => {
    if (!isUpcoming) {
      return {
        type: sessionStartTime ? ACTIVITY_ACCORDIAN_TYPES.ONLINE.value : "",
        startTime: `${convertTo12HourFormat(sessionStartTime)}`,
        children: [
          ...trips.map((trip, index) => {
            const tripChildren = [];
            if (trip.driverStartedAtTime) {
              tripChildren.push(createDriverReachingInfo({trip}));
            }
            if (trip.actualTripStartTime || trip.expectedTripStartTime) {
              tripChildren.push(createTripDetails({ trip }));
            }
            if (trip.stage === ACTIVITY_ACCORDIAN_TYPES.TRIP_CANCELED.value) {
              tripChildren.push({
                type: ACTIVITY_ACCORDIAN_TYPES.TRIP_CANCELED.value,
              });
            }
            return {
              name: `Trip ${index + 1}`,
              type: ACTIVITY_ACCORDIAN_TYPES.TRIP.value,
              stage: convertEnumToLabel(trip.stage),
              tripId: trip?.tripId,
              children: tripChildren,
              data: trip,
            };
          }),
          {
            type: sessionEndTime ? ACTIVITY_ACCORDIAN_TYPES.OFFLINE.value : ACTIVITY_ACCORDIAN_TYPES.NOT_OFFLINE.value,
            startTime: convertTo12HourFormat(sessionEndTime),
          },
        ],
      }
    }
    else {
      return {
        name: `Trip ${index + 1}`,
        tripId: sessionDetails.tripId,
        type: ACTIVITY_ACCORDIAN_TYPES.TRIP.value,
        children: [createTripDetails({ trip: sessionDetails })],
        data: sessionDetails,
      }
    }
  }, [sessionDetails, isUpcoming]);



  const handleToggle = (index, child) => {
    setActualPathToggle(false);
    setOpenChild((prevOpenChild) => {
      const newOpenChild = prevOpenChild === index ? null : index;
      if (prevOpenChild != index) {
        getSelectedTrip(child.data);
      } else {
        getSelectedTrip(null);
      }
      return newOpenChild;
    });
  };

  return (
    <div className="tree tw-flex tw-gap-5 tw-text-sm tw-justify-between tw-pr-2 tw-items-start">
      <ul className="tw-w-full tw-max-w-sm">
        <TreeNode
          node={sessionInfo}
          isOpen={isOpen}
          openChild={openChild}
          setOpenChild={setOpenChild}
          onClick={onClick}
          handleSelectedTrip={handleToggle}
        />
      </ul>

      {imageUrl && (
        <DriverUniformAdherenceImage
          status={wearingUniform ? true : false}
          basePath={imageUrl}
        />
      )}
    </div>
  );
}






