export const BlockingConfig = {
  reason: [
    {
      label: "Too many cancellations",
      value: "TOO_MANY_CANCELLATIONS",
    },
    {
      label: "Payment not done",
      value: "PAYMENT_NOT_DONE",
    },
    {
      label: "Misbehaved with driver",
      value: "MISBEHAVED_WITH_DRIVER",
    },
    {
      label: "Fake complaints about the driver",
      value: "FAKE_COMPLAINTS_ABOUT_DRIVER",
    },
    {
      label: "Used the driver for illegal motives",
      value: "USED_DRIVER_FOR_ILLEGAL_MOTIVES",
    },
    {
      label: "Other",
      value: "OTHER",
    },
  ],
};
