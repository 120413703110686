import React from "react";
import { Navigate, Route, Routes } from "react-router";
import { SignIn } from "./pages/SignIn.jsx";
import { Home } from "./pages/Home.jsx";
import { getFromLocalStorage, authorizatedRoutes } from "./utils/helpers.js";
import { useSelector } from "react-redux";
import { routingConfig } from "./config/routingConfig.js";

export const CLIENT_ROUTES = ["/home/trip", "/home/addresses"];
export const ADMIN_ROUTES = [
  "/home/trip",
  "/home/driver",
  "/home/pricing",
  "/home/coupon/grievance",
  "/home/coupon/referral",
  "/home/customer-management",
  "/home/operations",
  "/home/subscriptions",
];

const Routing = () => {
  const clientId = useSelector((state) => state.auth?.clientId);

  const isAuthenticated =
    useSelector((state) => state.auth.userLoggedIn) ||
    getFromLocalStorage("userLoggedIn");

  return (
    <Routes>
      <Route
        path="/login"
        element={
          isAuthenticated ? <Navigate to="/home/trip" replace /> : <SignIn />
        }
      />
      <Route
        path="/"
        element={
          <Navigate to={isAuthenticated ? "/home/trip" : "/login"} replace />
        }
      />
      <Route
        path="/home"
        element={isAuthenticated ? <Home /> : <Navigate to="/login" replace />}
      >
        {routingConfig.map((route) => {
          const Element = route.component;
          if (!authorizatedRoutes(route.path, clientId, isAuthenticated)) {
            return null;
          }
          return (
            <Route
              {...route}
              key={route.path}
              element={isAuthenticated ? <Element /> : <Navigate to="/login" />}
            />
          );
        })}
      </Route>
      <Route
        path="*"
        element={
          <Navigate to={isAuthenticated ? "/home/trip" : "/login"} replace />
        }
      />
    </Routes>
  );
};

export default Routing;
