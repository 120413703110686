import axios from "axios";
import {
  clearLocalStorage,
  getFromLocalStorage,
  showErrorMessage,
} from "../utils/helpers";
import store from "../store/store";
import { logOut } from "../slices/authSlice";

const instance = axios.create();
const { dispatch } = store;

instance.interceptors.request.use(function (config) {
  const accessToken = getFromLocalStorage("accessToken");
  const clientId = true;
  if (accessToken) {
    config.headers["Authorization"] = `Bearer ${accessToken}`;
    if (clientId) {
      config.headers["x-tenant-code"] = `AUTOPILOT`;
      config.headers["x-active-profile"] = `client`;
    }
  } else {
    return showErrorMessage("Please Login");
  }
  return config;
});

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error && error?.response?.status === 403) {
      dispatch(logOut());
      clearLocalStorage();
      setTimeout(() => {
        window.location = "/login?new-user=true";
      }, 500);
    }
    if (error && error?.response?.status === 401) {
      dispatch(logOut());
      clearLocalStorage();
      setTimeout(() => {
        window.location = "/login";
      }, 500);
    }
    throw error;
  }
);

const axiosService = {
  getWithHeaders: (endPoint, headers) => {
    var tempInstance = axios.create();
    tempInstance.interceptors.request.use(function (config) {
      let accessToken = getFromLocalStorage("accessToken");
      if (accessToken) {
        config.headers["Authorization"] = `Bearer ${accessToken}`;
      }
      return config;
    });

    const config = {};
    if (!endPoint) {
      throw Error("endPoint is required params");
    } else {
      config.headers = headers[0];
      return tempInstance.get(endPoint, config);
    }
  },

  get: (endPoint, data, headers = {}, responseType = null) => {
    const config = {};

    if (!endPoint) {
      throw Error("endPoint is required params");
    } else {
      if (data) {
        config.params = data;
      }

      config.headers = headers;
      if (responseType) config.responseType = responseType;
      return instance.get(endPoint, config);
    }
  },

  post: (endPoint, data, headers) => {
    if (!(endPoint || !data)) {
      throw Error("endPoint and data are required params");
    }
    return instance.post(endPoint, data, { headers });
  },

  put: (endPoint, data, headers = { "Content-Type": "application/json" }) => {
    if (!(endPoint || !data)) {
      // throw Error('endPoint and data are required params');
      return instance.put(endPoint);
    }
    return instance.put(endPoint, data, { headers });
  },

  delete: (endPoint, data, headers = {}) => {
    const config = {};
    if (!endPoint) {
      throw Error("endPoint is required params");
    } else {
      config.headers = headers;
      return instance.delete(endPoint, { data: data }, config);
    }
  },
};

export default axiosService;
