import React, { useRef, useState } from "react";
import {
  checkMatchingRoles,
  removeExistingFileOnFileUpload,
  showErrorMessage,
  showToastMessage,
} from "../../../utils/helpers";
import axiosService from "../../../init/axios";
import { APIConfig } from "../../../utils/constants/api.constants";
import { Button } from "@progress/kendo-react-buttons";
import LoadingSpinner from "../../common/LoadingSpinner";
import DriverPayoutApproveModal from "./DriverPayoutApproveModal";
import { useSelector } from "react-redux";
import { userPermissions } from "../../../config/userPermissionConfig";

const DriverPayoutLevelApproval = ({ onRefreshPayoutTable }) => {
  const [loading, setLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const uploadFileRef = useRef();
  const [modalOpen, setModalOpen] = useState(false);
  const [payoutApprovalDetails, setPayoutApprovalDetails] = useState({});
  const userRoles = useSelector((state) => state.auth?.userRoles);

  const onFileChange = (e) => {
    const file = e.target.files[0];
    // If not a csv file selected, return
    if (!(file.type === "text/csv")) {
      showToastMessage(`Uploaded file is not a csv file.`, false);
      setSelectedFile("");
      uploadFileRef.current.value = "";
      return false;
    }
    setSelectedFile(file);
  };

  const onFileClick = (e) => {
    if (!checkMatchingRoles(userRoles, userPermissions.payoutModification)) {
      showToastMessage("User not authorized for this action.", false);
      e.preventDefault();
    }
    removeExistingFileOnFileUpload(e, setSelectedFile, uploadFileRef);
  };

  const openPayoutApproveModal = (id) => {
    setModalOpen(true);
  };

  const closePayoutApproveModal = () => {
    setModalOpen(false);
    uploadFileRef.current.value = "";
    setSelectedFile(null);
    setPayoutApprovalDetails({});
  };

  const onUploadFile = async () => {
    if (!selectedFile) {
      showToastMessage("Please select a file!", false);
      return;
    }
    setLoading(true);
    const formData = new FormData();
    formData.append("file", selectedFile);
    formData.append("tag", "DRIVER_PAYOUT");
    formData.append("processPayouts", false);
    await axiosService
      .post(
        process.env.REACT_APP_DRIVER_BASE_URL +
          APIConfig.driverManagement.uploadDriverPayoutFile,
        formData
      )
      .then((data) => {
        setLoading(false);
        showToastMessage("File validated successfully");
        setPayoutApprovalDetails(data?.data);
        openPayoutApproveModal();
      })
      .catch((error) => {
        setLoading(false);
        showErrorMessage(error);
      });
  };

  const onApproveUploadedFilePayout = async () => {
    if (!selectedFile) {
      showToastMessage("Please select a file!", false);
      return;
    }
    setLoading(true);
    const formData = new FormData();
    formData.append("file", selectedFile);
    formData.append("tag", "DRIVER_PAYOUT");
    formData.append("processPayouts", true);
    await axiosService
      .post(
        process.env.REACT_APP_DRIVER_BASE_URL +
          APIConfig.driverManagement.approvePayoutLevelFile,
        formData
      )
      .then((data) => {
        setLoading(false);
        showToastMessage("Payouts approved successfully");
        closePayoutApproveModal();
        onRefreshPayoutTable();
      })
      .catch((error) => {
        setLoading(false);
        showErrorMessage(error);
        closePayoutApproveModal();
      });
  };

  return (
    <div className="payout-level-approval-div">
      <h5>Payout Level Approval</h5>
      <br />
      <div className="row">
        <div className="col-lg-3">
          <input
            type="file"
            ref={uploadFileRef}
            accept=".csv"
            onChange={onFileChange}
            onClick={onFileClick}
          />
        </div>
        <div className="col-lg-3">
          <Button
            themeColor={"primary"}
            onClick={onUploadFile}
            disabled={!selectedFile || loading}
          >
            {loading ? (
              <LoadingSpinner width={25} height={25} />
            ) : (
              "Validate & Upload"
            )}
          </Button>
        </div>
      </div>
      {modalOpen && (
        <DriverPayoutApproveModal
          loading={loading}
          payoutApprovalDetails={payoutApprovalDetails}
          onClose={closePayoutApproveModal}
          onApprovePayout={onApproveUploadedFilePayout}
        />
      )}
    </div>
  );
};

export default DriverPayoutLevelApproval;
