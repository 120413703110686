import React, { useRef, useState } from "react";
import { FormDropDownList } from "../../utils/forms/FormComponents";
import { Button } from "@progress/kendo-react-buttons";
import PreviousOperations from "./PreviousOperations";
import axiosService from "../../init/axios";
import { APIConfig } from "../../utils/constants/api.constants";
import { BulkOperationsConfig } from "../../config/bulkOperationsConfig";
import {
  removeExistingFileOnFileUpload,
  showErrorMessage,
  showToastMessage,
} from "../../utils/helpers";
import LoadingSpinner from "../common/LoadingSpinner";

const BulkOperationsHomePage = () => {
  const [loading, setLoading] = useState(false);
  const [operationType, setOperationType] = useState(
    BulkOperationsConfig.bulkOperationType[0]
  );
  const [selectedFile, setSelectedFile] = useState(null);
  const [refreshTable, setRefreshTable] = useState(false);
  const uploadFileRef = useRef();
  const [prevOpsDataState, setPrevOpsDataState] = useState({
    take: 10,
    skip: 0,
  });

  const onBulkOperationTypeChange = (e) => {
    //
  };

  const onFileClick = (e) => {
    removeExistingFileOnFileUpload(e, setSelectedFile, uploadFileRef);
  };

  const onFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const onUploadFile = async () => {
    if (!selectedFile) {
      showToastMessage("Please select a file!", false);
      return;
    }
    setLoading(true);
    const formData = new FormData();
    formData.append("file", selectedFile);
    formData.append("action", operationType?.value);
    await axiosService
      .post(
        process.env.REACT_APP_DRIVER_BASE_URL +
          APIConfig.bulkOperationsManagement.uploadBulkOperation,
        formData
      )
      .then((data) => {
        setLoading(false);
        showToastMessage("File uploaded successfully");
        uploadFileRef.current.value = "";
        setSelectedFile(null);
        setPrevOpsDataState({ ...prevOpsDataState });
        setRefreshTable((prevState) => (!prevState ? true : true));
      })
      .catch((error) => {
        setLoading(false);
        showErrorMessage(error);
      });
  };

  return (
    <div className="bulk-operations-homepage">
      <div className="select-operation">
        <h5>Select Operation</h5>
        <div className="row">
          <div className="col-lg-3">
            <FormDropDownList
              data={BulkOperationsConfig.bulkOperationType}
              textField="label"
              dataItemKey="value"
              value={operationType}
              onChange={onBulkOperationTypeChange}
              style={{ width: "200px" }}
            />
          </div>
          <div className="col-lg-3">
            <input
              type="file"
              ref={uploadFileRef}
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              onChange={onFileChange}
              onClick={onFileClick}
            />
          </div>
          <div className="col-lg-3">
            <Button
              themeColor={"primary"}
              onClick={onUploadFile}
              disabled={!selectedFile || loading}
            >
              {loading ? (
                <LoadingSpinner width={25} height={25} />
              ) : (
                "Validate & Upload"
              )}
            </Button>
          </div>
        </div>
      </div>
      <br />
      <div className="previous-operations">
        <h5>Previous Operations</h5>
        <PreviousOperations
          refresh={refreshTable}
          dataState={prevOpsDataState}
          setDataState={setPrevOpsDataState}
        />
      </div>
    </div>
  );
};

export default BulkOperationsHomePage;
