export default function TopFilters({
  statusFilter = "All",
  handleFilterClick,
  filters,
}) {
  return (
    <div className="tw-flex tw-items-center tw-text-sm tw-overflow-x-auto">
      {filters?.map((item) => {
        let className;
        if (statusFilter.label) {
          className = item.label == statusFilter.label ? "clicked" : "";
        } else {
          className = item.label == statusFilter ? "clicked" : "";
        }
        return (
          <button
            key={item.label}
            onClick={() => handleFilterClick(item)}
            className={`tw-whitespace-nowrap tw-px-5 tw-h-10 topFilter ${className}`}
          >
            {item.label} {item.count}
          </button>
        );
      })}
    </div>
  );
}
