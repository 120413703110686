import { useState, useEffect, useRef } from "react";
import { VariableSizeList } from "react-window";

const defaultValues = {
  options: [],
  textField: "label",
  backupMessage: "No results found",
  parentClass: "tw-relative tw-w-64",
};

const getItemSize = () => 35;

const DropdownSearchBase = ({
  textField,
  searchValue,
  setSearchValue,
  isOpen,
  setIsOpen,
  handleOptionClick,
  filteredOptions,
  backupMessage,
  parentClass,
  prevValue = null,
}) => {
  const dropdownRef = useRef(null);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      if (prevValue) {
        setSearchValue(prevValue.current[textField]);
      }
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const Row = ({ index, style }) => (
    <li
      className="tw-block tw-bg-none tw-border-none tw-text-left tw-w-full tw-cursor-pointer tw-p-1.5 hover:tw-bg-primary hover:tw-text-white"
      style={style}
      onClick={() => handleOptionClick(filteredOptions[index])}
    >
      {filteredOptions[index][textField]}
    </li>
  );

  return (
    <div className={parentClass} ref={dropdownRef}>
      <input
        onClick={() => setIsOpen(true)}
        type="search"
        // className="k-input k-input-md k-rounded-md k-input-solid tw-w-full"
        className="tw-rounded-md tw-outline-none tw-border tw-border-primaryBorder tw-px-2 tw-py-1 tw-w-full"
        placeholder="Search"
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
      />
      {isOpen && (
        <div className="tw-absolute tw-shadow-md tw-z-[1000] tw-right-0 tw-top-9 tw-w-full k-input k-input-md k-rounded-md k-input-solid">
          {filteredOptions.length > 0 ? (
            <VariableSizeList
              height={Math.min(
                filteredOptions.length * getItemSize(),
                getItemSize() * 4.5
              )}
              width={"100%"}
              itemCount={filteredOptions.length}
              itemSize={getItemSize}
            >
              {({ index, style }) => <Row index={index} style={style} />}
            </VariableSizeList>
          ) : (
            <div className="tw-p-2 tw-text-center tw-w-full">
              {backupMessage}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export const DropdownSearchSingleSelect = ({
  options = defaultValues.options,
  textField = defaultValues.textField,
  value = {
    label: "",
  },
  onChange,
  backupMessage = defaultValues.backupMessage,
  parentClass = defaultValues.parentClass,
  removeable,
  defaultValue,
}) => {
  const [search, setSearch] = useState(value[textField]);
  const [isOpen, setIsOpen] = useState(false);
  const prevValue = useRef(value);

  useEffect(() => {
    prevValue.current = value;
    setSearch(value[textField]); // Set initial search value to current value's textField
  }, [value]);

  const filteredOptions = options.filter((option) =>
    option[textField]?.toLowerCase()?.includes(search?.toLowerCase() || "")
  );
  const handleOptionClick = (option) => {
    onChange(option);
    setSearch(option[textField]);
    setIsOpen(false);
  };

  return (
    <DropdownSearchBase
      options={options}
      textField={textField}
      searchValue={search}
      setSearchValue={setSearch}
      isOpen={isOpen}
      prevValue={prevValue}
      setIsOpen={setIsOpen}
      handleOptionClick={handleOptionClick}
      filteredOptions={filteredOptions}
      backupMessage={backupMessage}
      parentClass={parentClass}
    />
  );
};

export const DropdownSearchMultiSelect = ({
  options = defaultValues.options,
  textField = defaultValues.textField,
  values,
  onChange,
  backupMessage = defaultValues.backupMessage,
  parentClass = defaultValues.parentClass,
}) => {
  const [search, setSearch] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const prevValues = useRef(values);

  useEffect(() => {
    prevValues.current = values;
  }, [values]);

  const filteredOptions = options.filter((option) =>
    option[textField].toLowerCase().includes(search.toLowerCase())
  );

  const handleOptionClick = (option) => {
    const newValue = [...values, option];
    onChange(newValue);
    setSearch("");
  };

  const handleRemoveOption = (index) => {
    const newValue = [...values];
    newValue.splice(index, 1);
    onChange(newValue);
  };

  return (
    <div className="tw-w-full tw-flex tw-gap-2">
      <DropdownSearchBase
        options={options}
        textField={textField}
        searchValue={search}
        setSearchValue={setSearch}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        handleOptionClick={handleOptionClick}
        filteredOptions={filteredOptions}
        backupMessage={backupMessage}
        parentClass={parentClass}
      />
      <div className="tw-flex tw-gap-2">
        {values.map((value, index) => (
          <span
            key={index}
            onClick={() => handleRemoveOption(index)}
            className="tw-flex tw-text-xs tw-items-center tw-rounded-full tw-cursor-pointer tw-bg-primary tw-text-white tw-pr-1 tw-pl-2"
          >
            {value[textField]}
            <button
              type="button"
              className="tw-text-[10px] active:tw-outline-none tw-text-white tw-font-medium tw-outline-none tw-border-none tw-bg-transparent tw-cursor-pointer"
              onClick={() => handleRemoveOption(index)}
            >
              &#x2715;
            </button>
          </span>
        ))}
      </div>
    </div>
  );
};
