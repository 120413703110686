import React, { useEffect, useState } from "react";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { process } from "@progress/kendo-data-query";
import { useNavigate } from "react-router-dom";
import {
  CategoryFilterCell,
  driverStatusTags,
  getLabelFromEnum,
  initialFilter,
  stringToSentenceCase,
} from "../../../utils/helpers";
import GridLoader from "../../common/GridLoader";
import { APIConfig } from "../../../utils/constants/api.constants";
import { DriverConfig } from "../../../config/driverConfig";
import { useSelector } from "react-redux";
import FleetViewMap from "../../maps/FleetViewMap";
import TopFilters from "../../common/TopFilters";
import LoadingSpinner from "../../common/LoadingSpinner";
import { useFilterData } from "./useFilterData";


function getOtherParamsInitialState({ showAllData = false, region_id,
  onboarding_stage = "ONBOARDING_FORM_COMPLETE:eq:string",
  bgv_status = "SUCCESS:eq:string",
  test_drive_status = "SUCCESS:eq:string", }) {
  if ((!region_id || showAllData)) {
    return {};
  }

  return { region_id: region_id + ":in:string", onboarding_stage, bgv_status, test_drive_status }
}

function getBoundingString({ bounding }) {
  if (!bounding) {
    return;
  }
  return encodeURI(`${bounding.north},${bounding.south},${bounding.east},${bounding.west}`)
}

const DriverProfilesTable = ({ dataState, setDataState, allData = false }) => {
  const baseUrl = process.env.REACT_APP_DRIVER_BASE_URL + APIConfig.driverManagement.getAllDrivers;
  const navigate = useNavigate();
  const selectedDriverRegionFilters = useSelector((state) => state.region?.driver);

  const { regionId } = selectedDriverRegionFilters || {};

  const [mapLoader, setMapLoader] = useState(false);
  const [statusFilter, setStatusFilter] = useState("All");
  const [driverProfiles, setDriverProfiles] = useState({ data: [], total: 0 });
  const [selectedDriver, setSelectedDriver] = useState(null);

  const [filterArr, getFilterData] = useFilterData({ regionId, showAllData: allData });

  const otherParamsInitialState = getOtherParamsInitialState({ region_id: regionId, showAllData: allData });



  const columns = [
    {
      id: "driverId",
      field: "driverId",
      title: "Driver ID",
      filterable: true,
      filterKey: "driver_id",
      filterType: "string",
      defaultFilterOperator: "lk",
      width: 200,
    },
    {
      id: "fullName",
      field: "fullName",
      title: "Driver Name",
      filterable: true,
      filterKey: "full_name",
      filterType: "string",
      defaultFilterOperator: "lk",
      width: 210,
    },
    ...(allData
      ? [
        {
          id: "onboardingStage",
          field: "onboardingStage",
          title: "Onboarding Status",
          filterable: true,
          filterKey: "onboarding_stage",
          filterType: "string",
          defaultFilterOperator: "eq",
          data: DriverConfig.onboardingStage,
          width: 220,
        },
      ]
      : []),
    {
      id: "driverStatus",
      field: "driverStatus",
      title: "Status",
      filterable: false,
      width: 150,
      cell: (cell) => (
        <td
          colSpan={cell.colSpan}
          role="gridcell"
          aria-colindex={cell.ariaColumnIndex}
          aria-selected="false"
          datagridcolindex={cell.columnIndex}
        >
          {allData ? (
            <>{driverStatusTags(cell?.dataItem?.driverStatus)}</>
          ) : (
            <span
              className="tw-cursor-pointer"
              onClick={() => setSelectedDriver(cell?.dataItem)}
            >
              {driverStatusTags(cell?.dataItem?.driverStatus)}
            </span>
          )}
        </td>
      ),
    },
    {
      id: "mobileNumber",
      field: "mobileNumber",
      title: "Mobile Number",
      filterable: true,
      filterKey: "mobile_number",
      filterType: "string",
      defaultFilterOperator: "lk",
      width: 200,
    },
    ...(allData
      ? [
        {
          id: "bgvStatus",
          field: "bgvStatus",
          title: "3rd Party BGV",
          filterable: true,
          filterKey: "bgv_status",
          filterType: "string",
          defaultFilterOperator: "eq",
          data: DriverConfig.bgvStatus,
          width: 200,
        },
      ]
      : []),
    ...(allData
      ? [
        {
          id: "testDriveStatus",
          field: "testDriveStatus",
          title: "Driving Test Status",
          filterable: true,
          filterKey: "test_drive_status",
          filterType: "string",
          defaultFilterOperator: "eq",
          data: DriverConfig.testDriveStatus,
          width: 200,
        },
      ]
      : []),
    {
      id: "driverPayoutType",
      field: "driverPayoutType",
      title: "Earnings Plan",
      filterable: true,
      filterKey: "driver_payout_type",
      filterType: "string",
      defaultFilterOperator: "eq",
      data: DriverConfig.driverPayoutType,
      width: 200,
    },
    {
      id: "regionId",
      field: "regionId",
      title: "Region Id",
      filterable: false,
      width: 200,
    },
    {
      id: "lastLocation",
      field: "lastLocation",
      title: "Last Location",
      filterable: false,
      width: 300,
    },
    {
      id: "updatedAt",
      field: "updatedAt",
      title: "Updated At",
      filterable: false,
      width: 200,
    },
  ];



  useEffect(() => {
    setStatusFilter("All");
    getFilterData();
    if (regionId && !allData) {
      setDataState({
        ...dataState,
        otherParams: { ...otherParamsInitialState },
      });
    }
  }, [regionId]);

  const dataStateChange = (e) => {
    setDataState((prev,) => ({
      ...prev,
      ...e.dataState,
    }));
  };

  const dataReceived = (dataObj) => {
    const arr = dataObj.data.data.map((driver) => {
      const updatedProfileDetails = getLabelFromEnum(
        ["bgvStatus", "testDriveStatus", "onboardingStage", "driverPayoutType"],
        driver,
        "driver"
      );
      const fullName = driver?.fullName
        ? stringToSentenceCase(driver.fullName)
        : "";
      const regionId = driver?.regionIds?.join(", ") || "";
      const lastLocation = `${driver.lastKnownLat},${driver.lastKnownLng}`;

      return {
        ...updatedProfileDetails,
        fullName,
        regionId,
        lastLocation,
      };
    });

    setDriverProfiles({ ...dataObj, data: arr });
  };


  const handleFilterClick = (filter) => {
    setStatusFilter(filter);
    setDataState((prev) => {
      const otherParams = { ...otherParamsInitialState, bounding: prev.bounding };
      if (filter?.filters?.length > 0) {
        const [key, value] = filter.filters[0].split("=");
        otherParams[key] = value;
      }
      return {
        ...prev,
        otherParams,
      }
    });
  };

  const onBoundsChange = (e) => {
    if (allData) return;
    let bounds = e.toJSON();
    setDataState(prev => ({ ...prev, otherParams: { ...prev.otherParams, bounding: getBoundingString({ bounding: bounds }) } }));
  };

  const handleMapLoader = (val) => {
    if (allData) return;
    setMapLoader(val);
  };

  return (
    <>
      <div className="tw-mb-5">
        {filterArr.data ? (
          <TopFilters
            statusFilter={statusFilter}
            handleFilterClick={handleFilterClick}
            filters={filterArr.data}
          />
        ) : (
          <div className="tw-flex tw-items-center tw-gap-0 tw-w-2/4 tw-h-10 tw-animate-pulse topFilter"></div>
        )}
      </div>
      <div className="tw-flex tw-gap-5 tw-h-full">
        <div style={{ width: allData ? "100%" : "60%" }}>
          <Grid
            filter={initialFilter}
            filterable
            sortable={false}
            pageable
            {...dataState}
            data={driverProfiles}
            onDataStateChange={dataStateChange}
            onRowDoubleClick={(item) => navigate(`/home/driver/${item.dataItem.driverId}/profile?driverName=${item.dataItem.fullName}`)}
          >
            {columns.map((column) => (
              <Column
                key={column.id}
                field={column.field}
                title={column.title}
                width={column.width}
                filterable={column.filterable}
                filterCell={
                  column.defaultFilterOperator === "eq" &&
                  ((props) => CategoryFilterCell(props, column, 250))
                }
                cell={column.cell}
              />
            ))}
          </Grid>
          <GridLoader
            loader={handleMapLoader}
            baseUrl={baseUrl}
            columns={columns}
            dataState={dataState}
            onDataReceived={dataReceived}
          />
        </div>
        {!allData && (
          <div className="tw-w-full tw-bg-gray-200 tw-h-[80dvh] tw-relative">
            {mapLoader && (
              <div className="tw-grid tw-bg-black/20 tw-place-content-center tw-absolute tw-top-0 tw-left-0 tw-h-full tw-w-full tw-z-10">
                <LoadingSpinner />
              </div>
            )}
            <FleetViewMap
              setSelectedDriverTabel={setSelectedDriver}
              selectedDriverTable={selectedDriver}
              region={selectedDriverRegionFilters}
              data={driverProfiles.data}
              onBoundsChange={onBoundsChange}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default DriverProfilesTable;
