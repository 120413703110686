import BulkOperationsManagement from "../pages/BulkOperationsManagement.jsx";
import CustomerManagement from "../pages/CustomerManagement.jsx";
import DriverPayoutManagement from "../pages/DriverPayoutManagement.jsx";
import GrievanceCouponManagement from "../pages/coupons/GrievanceCouponManagement.jsx";
import ReferralCouponManagement from "../pages/coupons/ReferralCouponManagement.jsx";
import AssignDriver from "../pages/trip-management/AssignDriver.jsx";
import SubscriptionPackage from "../pages/SubscriptionPackage.jsx";
import SubscriptionDetails from "../components/subscription-package/SubscriptionDetails.jsx";
import AllDriverManagement from "../pages/AllDriverManagement.jsx";
import SubscriptionCycleDetails from "../components/subscription-package/SubscriptionCycleDetails.jsx";
import SubscriptionCycleDayDetails from "../components/subscription-package/SubscriptionCycleDayDetails.jsx";
import TripManagement from "../pages/trip-management/TripManagement.jsx";
import DriverManagement from "../pages/DriverManagement.jsx";
import PricingManagement from "../pages/PricingManagement.jsx";
import AddressesPage from "../pages/AddressesPage.jsx";

export const routingConfig = [
  {
    path: "/home/trip",
    component: TripManagement,
  },
  {
    path: "/home/trip/:tripId/details/",
    component: TripManagement,
  },
  {
    path: "/home/trip/:tripId/assign-driver",
    component: AssignDriver,
  },
  {
    path: "/home/driver",
    component: DriverManagement,
  },
  {
    path: "/home/driver/all",
    component: AllDriverManagement,
  },
  {
    path: "/home/driver/:driverId/profile",
    component: DriverManagement,
  },
  {
    path: "/home/driver/:driverId/account",
    component: DriverManagement,
  },
  {
    path: "/home/driver/:driverId/online-activity",
    component: DriverManagement,
  },
  {
    path: "/home/driver/payout",
    component: DriverPayoutManagement,
  },
  {
    path: "/home/pricing",
    component: PricingManagement,
  },
  {
    path: "/home/coupon/grievance",
    component: GrievanceCouponManagement,
  },
  {
    path: "/home/coupon/referral",
    component: ReferralCouponManagement,
  },
  {
    path: "/home/customer-management",
    component: CustomerManagement,
  },
  {
    path: "/home/operations",
    component: BulkOperationsManagement,
  },
  {
    path: "/home/subscriptions",
    component: SubscriptionPackage,
  },
  {
    path: "/home/subscriptions/:subId/details",
    component: SubscriptionDetails,
  },
  {
    path: "/home/subscriptions/:subId/cycle/:cycleId/details",
    component: SubscriptionCycleDetails,
  },
  {
    path: "/home/subscriptions/:subId/cycle/:cycleId/day/:tripId/details",
    component: SubscriptionCycleDayDetails,
  },
  {
    path: "/home/addresses",
    component: AddressesPage,
  },
];
