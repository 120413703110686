export const TRIP_TYPES = {
    B2B: "B2B",
    C2B: "C2B",
};

export const TRIP_FORM = {
  PRE_TRIP: "PRE_TRIP",
  POST_TRIP: "POST_TRIP",
};

export const MEDIA_TYPE={
  IMAGE:"Image",
  SELFIE:"SELFIE"
}

export const ALLOWED_STAGES ={
    SEARCHING_FOR_DRIVER:"SEARCHING_FOR_DRIVER"
}

export const TIME_SLOTS = [
    { label: "12:00 AM - 1:00 AM", value: "00:00:00" },
    { label: "12:30 AM - 1:30 AM", value: "00:30:00" },
    { label: "1:00 AM - 2:00 AM", value: "01:00:00" },
    { label: "1:30 AM - 2:30 AM", value: "01:30:00" },
    { label: "2:00 AM - 3:00 AM", value: "02:00:00" },
    { label: "2:30 AM - 3:30 AM", value: "02:30:00" },
    { label: "3:00 AM - 4:00 AM", value: "03:00:00" },
    { label: "3:30 AM - 4:30 AM", value: "03:30:00" },
    { label: "4:00 AM - 5:00 AM", value: "04:00:00" },
    { label: "4:30 AM - 5:30 AM", value: "04:30:00" },
    { label: "5:00 AM - 6:00 AM", value: "05:00:00" },
    { label: "5:30 AM - 6:30 AM", value: "05:30:00" },
    { label: "6:00 AM - 7:00 AM", value: "06:00:00" },
    { label: "6:30 AM - 7:30 AM", value: "06:30:00" },
    { label: "7:00 AM - 8:00 AM", value: "07:00:00" },
    { label: "7:30 AM - 8:30 AM", value: "07:30:00" },
    { label: "8:00 AM - 9:00 AM", value: "08:00:00" },
    { label: "8:30 AM - 9:30 AM", value: "08:30:00" },
    { label: "9:00 AM - 10:00 AM", value: "09:00:00" },
    { label: "9:30 AM - 10:30 AM", value: "09:30:00" },
    { label: "10:00 AM - 11:00 AM", value: "10:00:00" },
    { label: "10:30 AM - 11:30 AM", value: "10:30:00" },
    { label: "11:00 AM - 12:00 PM", value: "11:00:00" },
    { label: "11:30 AM - 12:30 PM", value: "11:30:00" },
    { label: "12:00 PM - 1:00 PM", value: "12:00:00" },
    { label: "12:30 PM - 1:30 PM", value: "12:30:00" },
    { label: "1:00 PM - 2:00 PM", value: "13:00:00" },
    { label: "1:30 PM - 2:30 PM", value: "13:30:00" },
    { label: "2:00 PM - 3:00 PM", value: "14:00:00" },
    { label: "2:30 PM - 3:30 PM", value: "14:30:00" },
    { label: "3:00 PM - 4:00 PM", value: "15:00:00" },
    { label: "3:30 PM - 4:30 PM", value: "15:30:00" },
    { label: "4:00 PM - 5:00 PM", value: "16:00:00" },
    { label: "4:30 PM - 5:30 PM", value: "16:30:00" },
    { label: "5:00 PM - 6:00 PM", value: "17:00:00" },
    { label: "5:30 PM - 6:30 PM", value: "17:30:00" },
    { label: "6:00 PM - 7:00 PM", value: "18:00:00" },
    { label: "6:30 PM - 7:30 PM", value: "18:30:00" },
    { label: "7:00 PM - 8:00 PM", value: "19:00:00" },
    { label: "7:30 PM - 8:30 PM", value: "19:30:00" },
    { label: "8:00 PM - 9:00 PM", value: "20:00:00" },
    { label: "8:30 PM - 9:30 PM", value: "20:30:00" },
    { label: "9:00 PM - 10:00 PM", value: "21:00:00" },
    { label: "9:30 PM - 10:30 PM", value: "21:30:00" },
    { label: "10:00 PM - 11:00 PM", value: "22:00:00" },
    { label: "10:30 PM - 11:30 PM", value: "22:30:00" },
    { label: "11:00 PM - 12:00 AM", value: "23:00:00" },
    { label: "11:30 PM - 12:30 AM", value: "23:30:00" },
  ];

  export const CAR_TYPES = [
    {
      value: "Sedan",
      text: "Sedan",
    },
    {
      value: "Hatchback",
      text: "Hatchback",
    },
    {
      value: "SUV",
      text: "SUV",
    },
    {
      value: "Luxury",
      text: "Luxury",
    },
  ];
  
  export const TRANSMISSION_TYPES = [
    {
      value: "Manual",
      text: "Manual",
    },
    {
      value: "Automatic",
      text: "Automatic",
    },
  ];
  
  export const FUEL_TYPES = [
    {
      value: "Petrol",
      text: "Petrol",
    },
    {
      value: "Diesel",
      text: "Diesel",
    },
    {
      value: "CNG",
      text: "CNG",
    },
    {
      value: "Electric",
      text: "Electric",
    },
  ];
 