export const SubscriptionConfig = {
  status: [
    {
      value: "INITIATED",
      label: "Initiated",
    },
    {
      value: "SEARCHING_FOR_DRIVER",
      label: "Searching For Driver",
    },
    {
      value: "DRIVER_ALLOCATED",
      label: "Driver Allocated",
    },
    {
      value: "CHANGE_DRIVER",
      label: "Change Driver",
    },
    {
      value: "IN_PROGRESS",
      label: "In Progress",
    },
    {
      value: "EXPIRED",
      label: "Expired",
    },
    {
      value: "CANCELLED",
      label: "Cancelled",
    },
    {
      value: "REFUNDED",
      label: "Refunded",
    },
  ],

  cycleStatus: [
    {
      description: "Upcoming subscription cycle",
      value: "INITIATED",
      label: "Initiated",
    },
    {
      description: "Subscription cycle in-process",
      value: "IN_PROGRESS",
      label: "In Progress",
    },
    {
      description: "Subscription cycle completed",
      value: "COMPLETED",
      label: "Completed",
    },
    {
      description: "Subscription cycle cancelled or Failed",
      value: "CANCELLED",
      label: "Cancelled",
    },
    {
      description: "Subscription cycle refunded",
      value: "REFUNDED",
      label: "Refunded",
    },
  ],
  paymentStatus: [
    {
      label: "Not Applicable",
      value: "NOT_APPLICABLE",
    },
    {
      label: "Initiated",
      value: "INITIATED",
    },
    {
      label: "Pending",
      value: "PENDING",
    },
    {
      label: "Failed",
      value: "FAILED",
    },
    {
      label: "Success",
      value: "SUCCESS",
    },
  ],
};
