export default function TripManagementIcon({ fill }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M14.745 12.4375C14.6876 12.4699 14.6244 12.4906 14.5589 12.4984C14.4935 12.5062 14.4272 12.5011 14.3637 12.4832C14.3003 12.4653 14.2411 12.435 14.1894 12.3942C14.1377 12.3533 14.0946 12.3026 14.0625 12.245L9.70753 4.5H8.50003V5C8.50003 5.13261 8.44735 5.25979 8.35358 5.35355C8.25981 5.44732 8.13263 5.5 8.00003 5.5C7.86742 5.5 7.74024 5.44732 7.64647 5.35355C7.5527 5.25979 7.50003 5.13261 7.50003 5V4.5H6.29253L1.93753 12.245C1.87255 12.361 1.76414 12.4465 1.63614 12.4826C1.50815 12.5187 1.37106 12.5025 1.25503 12.4375C1.13899 12.3725 1.05353 12.2641 1.01743 12.1361C0.981325 12.0081 0.997548 11.871 1.06253 11.755L5.14503 4.5H1.50003C1.36742 4.5 1.24024 4.44732 1.14647 4.35355C1.0527 4.25979 1.00003 4.13261 1.00003 4C1.00003 3.86739 1.0527 3.74021 1.14647 3.64645C1.24024 3.55268 1.36742 3.5 1.50003 3.5H14.5C14.6326 3.5 14.7598 3.55268 14.8536 3.64645C14.9473 3.74021 15 3.86739 15 4C15 4.13261 14.9473 4.25979 14.8536 4.35355C14.7598 4.44732 14.6326 4.5 14.5 4.5H10.855L14.9375 11.755C14.9699 11.8124 14.9906 11.8756 14.9984 11.9411C15.0063 12.0065 15.0011 12.0729 14.9832 12.1363C14.9653 12.1997 14.9351 12.259 14.8942 12.3107C14.8533 12.3624 14.8026 12.4055 14.745 12.4375ZM8.00003 7C7.86742 7 7.74024 7.05268 7.64647 7.14645C7.5527 7.24021 7.50003 7.36739 7.50003 7.5V8.5C7.50003 8.63261 7.5527 8.75979 7.64647 8.85355C7.74024 8.94732 7.86742 9 8.00003 9C8.13263 9 8.25981 8.94732 8.35358 8.85355C8.44735 8.75979 8.50003 8.63261 8.50003 8.5V7.5C8.50003 7.36739 8.44735 7.24021 8.35358 7.14645C8.25981 7.05268 8.13263 7 8.00003 7ZM8.00003 10.5C7.86742 10.5 7.74024 10.5527 7.64647 10.6464C7.5527 10.7402 7.50003 10.8674 7.50003 11V12C7.50003 12.1326 7.5527 12.2598 7.64647 12.3536C7.74024 12.4473 7.86742 12.5 8.00003 12.5C8.13263 12.5 8.25981 12.4473 8.35358 12.3536C8.44735 12.2598 8.50003 12.1326 8.50003 12V11C8.50003 10.8674 8.44735 10.7402 8.35358 10.6464C8.25981 10.5527 8.13263 10.5 8.00003 10.5Z"
        fill={fill}
      />
    </svg>
  );
}
