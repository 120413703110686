import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";

import React, { useState } from "react";
import {
  CategoryFilterCell,
  CategoryFilterMultiSelectCell,
} from "../../utils/helpers";
import GridLoader from "../common/GridLoader";
import { Button } from "@progress/kendo-react-buttons";
import SavedAddressesModal from "./SavedAddressesModal";
import ViewMapIcon from "../../assets/view-map.svg";
import EditIcon from "../../assets/edit-icon.svg";
import DeleteIcon from "../../assets/delete-icon.svg";

export const modalStates = {
  NEW_ADDRESS: {
    show: true,
    state: "NEW_ADDRESS",
    title: "Add address",
    edit: true,
  },
  VIEW_MAP: (data) => ({
    show: true,
    state: "VIEW_MAP",
    title: "View Map",
    edit: false,
    data: data,
  }),
  EDIT_ADDRESS: (data) => ({
    show: true,
    state: "EDIT_ADDRESS",
    title: "Edit Address",
    edit: true,
    data: data,
  }),
  DELETE_ADDRESS: (data) => ({
    show: true,
    state: "DELETE_ADDRESS",
    title: "Delete Address",
    edit: false,
    data: data,
  }),
};

export default function AddressesTable({ dataState, setDataState, baseUrl }) {
  const initialState = {
    show: false,
    state: "",
  };

  const [savedAddresses, setSavedAddresses] = useState({
    data: [],
    total: 0,
  });

  const [modalState, setModalState] = useState(initialState);

  const columns = [
    // {
    //   id: "placeId",
    //   field: "placeId",
    //   title: "Place ID",
    //   filterable: true,
    //   filterKey: "place_id",
    //   filterType: "string",
    //   defaultFilterOperator: "lk",
    //   width: 150,
    // },
    {
      id: "branchName",
      field: "branchName",
      title: "Branch Name",
      filterable: true,
      filterKey: "branch_name",
      filterType: "string",
      defaultFilterOperator: "lk",
      width: 200,
    },
    {
      id: "formattedAddress",
      field: "formattedAddress",
      title: "Address",
      filterable: true,
      filterKey: "formatted_address",
      filterType: "string",
      defaultFilterOperator: "lk",
    },
    {
      id: "actions",
      field: "actions",
      title: "Actions",
      filterable: false,
      width: 200,
      cell: (e) => (
        <>
          <td className="">
            <div className="tw-flex tw-items-center tw-justify-between">
              <button
                onClick={() => {
                  setModalState(modalStates.VIEW_MAP(e.dataItem));
                }}
                className="tw-bg-transparent tw-text-[#2756B3] tw-border-none"
              >
                <img className="tw-w-5" src={ViewMapIcon} />
              </button>
              <button
                className="tw-bg-transparent tw-text-[#2756B3] tw-border-none"
                onClick={() => {
                  setModalState(modalStates.EDIT_ADDRESS(e.dataItem));
                }}
              >
                <img className="tw-w-5" src={EditIcon} />
              </button>
              <button
                className="tw-bg-transparent tw-text-[#2756B3] tw-border-none"
                onClick={() => {
                  setModalState(modalStates.DELETE_ADDRESS(e.dataItem));
                }}
              >
                <img className="tw-w-5" src={DeleteIcon} />
              </button>
            </div>
          </td>
        </>
      ),
    },
  ];

  const dataReceived = (dataObj) => {
    const arr = dataObj?.data?.addresses;
    setSavedAddresses({ ...dataObj, data: arr, total: arr.length });
  };

  const onClose = ({ updated = false }) => {
    setModalState(false);
    if (updated) {
      setDataState({
        ...dataState,
      });
    }
  };

  const dataStateChange = (e) => {
    const { skip, take, filter, sort } = e.dataState;

    // Update dataState with pagination (skip, take) and other params
    setDataState({
      ...dataState,
      skip: skip || 0,
      take: take || 10,
      filter: filter || [],
      sort: sort || [],
    });
  };

  return (
    <div>
      <Button
        onClick={() => setModalState(modalStates.NEW_ADDRESS)}
        themeColor={"primary"}
        className="tw-ml-auto tw-mb-5 tw-block"
      >
        Add new
      </Button>
      <div>
        <Grid
          filterable={true}
          sortable={false}
          pageable={true}
          {...dataState}
          data={savedAddresses.data}
          total={savedAddresses.total}
          pageSize={dataState.take || 10}
          skip={dataState.skip || 0}
          onDataStateChange={dataStateChange}
        >
          {columns?.map((column) => (
            <Column
              key={column?.id}
              field={column?.field}
              title={column?.title}
              filterable={column?.filterable}
              width={column?.width}
              cell={column?.cell}
              filterCell={
                (column.defaultFilterOperator === "eq" &&
                  ((props) => CategoryFilterCell(props, column))) ||
                (column.defaultFilterOperator === "eqIn" &&
                  ((props) => CategoryFilterMultiSelectCell(props, column)))
              }
            />
          ))}
        </Grid>
        <GridLoader
          baseUrl={baseUrl}
          columns={columns}
          dataState={dataState}
          onDataReceived={dataReceived}
        />
      </div>
      {modalState.show && (
        <SavedAddressesModal modalState={modalState} onClose={onClose} />
      )}
    </div>
  );
}
