import closeBtn from "../../assets/closeButton.svg";
const ModalLayout = ({ title, onClose, children, parentClasses = ""}) => {
  return (
    <>
      <section
        className={`tw-fixed tw-top-0 tw-inset-0 tw-grid tw-place-content-center tw-bg-black/50 tw-backdrop-blur-sm tw-z-[10] ${parentClasses}`}
      >
        <div className="tw-bg-white tw-rounded-md tw-shadow-md">
          <div className="tw-flex tw-px-5 tw-py-3 custom-border-primary-bottom tw-gap-5 tw-items-center tw-justify-between">
            {title && <span className="tw-font-bold tw-text-lg">{title}</span>}
            <button
              onClick={onClose}
              className="tw-p-0 tw-bg-transparent tw-border-none"
            >
              <img src={closeBtn} alt="Close" />
            </button>
          </div>
          <div className="tw-p-5">{children}</div>
        </div>
      </section>
    </>
  );
};

export default ModalLayout;