import React, { useState } from 'react'
import { APIConfig } from '../../../../../utils/constants/api.constants';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import GridLoader from '../../../../common/GridLoader';

const RevenueStream = ({ tripId }) => {
    const baseUrl = process.env.REACT_APP_TRIP_BASE_URL + APIConfig.tripManagement.getRevenueStreamLogs(tripId);
    const [revenueStreamLogs, setRevenueStreamLogs] = useState({
        data: [],
        total: 0
    });

    const [dataState, setDataState] = useState({
        take: 10,
        skip: 0
    });

    const columns = [
        {
            id: "label",
            field: "label",
            title: "Revenue Stream",
            width:150
        },
        {
            id: "serviceProvider",
            field: "serviceProvider",
            title: "Service Provider",
            width:120
        },
        {
            id: "baseAmount",
            field: "baseAmount",
            title: "Base Amount",
            width:120
        },
        {
            id: "gstAmount",
            field: "gstAmount",
            title: "GST Amount",
            width:120
        },
        {
            id: "discount",
            field: "discount",
            title: "Discount",
            width:120
        },
        {
            id: "netBaseAfterDiscount",
            field: "netBaseAfterDiscount",
            title: "Net Base After Discount",
            width:120
        },
        {
            id: "netGstAfterDiscount",
            field: "netGstAfterDiscount",
            title: "Net GST After Discount",
            width:120
        },
        {
            id: "waiver",
            field: "waiver",
            title: "Waiver",
            width:120
        },
        {
            id: "netBaseAfterWaiver",
            field: "netBaseAfterWaiver",
            title: "Net Base After Waiver",
            width:120
        },
        {
            id: "netGstAfterWaiver",
            field: "netGstAfterWaiver",
            title: "Net GST After Waiver",
            width:120
        },
        {
            id: "roundingOff",
            field: "roundingOff",
            title: "Rounding Off",
            width:120
        },
        {
            id: "totalCollectibleAmount",
            field: "totalCollectibleAmount",
            title: "Total Collectible Amount",
            width:120
        },
        {
            id: "amountCollected",
            field: "amountCollected",
            title: "Collected",
            width:120
        },
        {
            id: "pendingAmount",
            field: "pendingAmount",
            title: "Pending",
            width:120
        },
        {
            id: "discountCode",
            field: "discountCode",
            title: "Discount Code",
            width:200
        }
    ];

    const dataStateChange = e => {
        setDataState(e.dataState);
    };
    const dataReceived = dataObj => {
        const revenueStremaData = dataObj?.data?.actualRevenueStream?.revenueConstituents?.length > 0 ?
            dataObj?.data?.actualRevenueStream :
            dataObj?.data?.expectedRevenueStream;
        const updatedLogs = revenueStremaData?.revenueConstituents?.length > 0 &&
            revenueStremaData?.revenueConstituents.map(log => {
                return { ...log, discountCode: revenueStremaData?.couponCode };
            })
        setRevenueStreamLogs({ ...dataObj, data: updatedLogs });
    };

    return (
        <div className='revenue-stream-table'>
            <Grid filterable={false} sortable={false} pageable={false} {...dataState}
                data={revenueStreamLogs}
                onDataStateChange={dataStateChange}>
                {
                    columns.map(column => (
                        <Column key={column?.id} field={column?.field} title={column?.title} width={column?.width} />
                    ))
                }
            </Grid>
            <GridLoader baseUrl={baseUrl} columns={columns} dataState={dataState} onDataReceived={dataReceived} />
        </div>
    )
}

export default RevenueStream