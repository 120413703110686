export default function CouponManagementIcon({ fill }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M14.1994 6.53C14.4253 6.48404 14.6283 6.36146 14.7742 6.18299C14.9201 6.00452 14.9999 5.78114 15 5.55062V4C15 3.73478 14.8946 3.48043 14.7071 3.29289C14.5196 3.10536 14.2652 3 14 3H2C1.73479 3 1.48043 3.10536 1.2929 3.29289C1.10536 3.48043 1 3.73478 1 4V5.55062C1.00013 5.78114 1.07989 6.00452 1.22578 6.18299C1.37167 6.36146 1.57474 6.48404 1.80063 6.53C2.13838 6.60004 2.44167 6.78435 2.65941 7.05188C2.87716 7.31941 2.99604 7.65381 2.99604 7.99875C2.99604 8.34369 2.87716 8.67809 2.65941 8.94562C2.44167 9.21315 2.13838 9.39746 1.80063 9.4675C1.57432 9.51354 1.37093 9.6365 1.22499 9.81548C1.07905 9.99446 0.999551 10.2184 1 10.4494V12C1 12.2652 1.10536 12.5196 1.2929 12.7071C1.48043 12.8946 1.73479 13 2 13H14C14.2652 13 14.5196 12.8946 14.7071 12.7071C14.8946 12.5196 15 12.2652 15 12V10.4494C14.9999 10.2189 14.9201 9.99547 14.7742 9.81701C14.6283 9.63854 14.4253 9.51596 14.1994 9.47C13.8616 9.39996 13.5583 9.21565 13.3406 8.94812C13.1228 8.68059 13.004 8.34619 13.004 8.00125C13.004 7.65631 13.1228 7.32191 13.3406 7.05438C13.5583 6.78685 13.8616 6.60254 14.1994 6.5325V6.53ZM2 10.45C2.56481 10.3349 3.07251 10.0282 3.43715 9.58183C3.80179 9.13542 4.00097 8.57672 4.00097 8.00031C4.00097 7.4239 3.80179 6.8652 3.43715 6.41879C3.07251 5.97238 2.56481 5.66568 2 5.55062V4H5.5V12H2V10.45ZM14 10.45V12H6.5V4H14V5.55C13.4352 5.66506 12.9275 5.97175 12.5629 6.41817C12.1982 6.86458 11.999 7.42328 11.999 7.99969C11.999 8.5761 12.1982 9.1348 12.5629 9.58121C12.9275 10.0276 13.4352 10.3343 14 10.4494V10.45Z"
        fill={fill}
      />
    </svg>
  );
}
