import React, { useState, useEffect } from "react";
import ModalLayout from "../../common/ModalLayout";
import LoadingPanel from "../../common/LoadingPanel";
import { getImgSrc } from "../../../utils/helpers";
import uniformAdherenceImagePlaceholder from "../../../assets/uniform-adherence.png";
import passedAdherence from "../../../assets/passed.svg";
import failedAdherence from "../../../assets/failed.svg";

export default function DriverUniformAdherenceImage({ basePath, status }) {
  const [showImage, setShowImage] = useState(false);

  const handleClose = () => {
    setShowImage(false);
  };
  return (
    <>
      <button
        className="tw-p-0 tw-text-sm tw-rounded-md tw-h-[70px] tw-aspect-square tw-relative tw-bg-transparent custom-border-primary"
        onClick={() => setShowImage(true)}
      >
        <img
          src={status ? passedAdherence : failedAdherence}
          alt="status"
          className="tw-absolute -tw-right-2 -tw-top-2 tw-bg-white"
        />
        <img
          className="tw-h-full tw-w-full tw-object-contain
          tw-rounded-md"
          src={uniformAdherenceImagePlaceholder}
          alt="Uniform Adherence"
        />
        <span className="tw-bg-black tw-rounded-b-md tw-w-full tw-text-xs tw-py-0.5 tw-absolute tw-bottom-0 tw-left-0 text-white">
          View
        </span>
      </button>
      {showImage && <Popup basePath={basePath} handleClose={handleClose} />}
    </>
  );
}

const Popup = ({ basePath, handleClose }) => {
  const [imgUrl, setImageUrl] = useState("");
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const getImage = async () => {
      if (basePath) {
        const imgSrc = await getImgSrc(basePath);
        if (imgSrc) {
          setImageUrl(imgSrc);
          setLoading(false);
        }
      }
    };
    getImage();
  }, [basePath]);
  return (
    <>
      <ModalLayout
        title={"Driver Uniform Adherence Image"}
        onClose={handleClose}
      >
        <div
          style={{
            width: "500px",
            height: "500px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "18px",
            fontWeight: "700",
            position: "relative",
          }}
        >
          {loading ? (
            <LoadingPanel />
          ) : (
            <img
              src={imgUrl}
              alt=""
              style={{
                width: "100%",
                height: "100%",
                objectFit: "contain",
              }}
            />
          )}
        </div>
      </ModalLayout>
    </>
  );
};
