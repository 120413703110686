export default function BulkOperationIcon({ fill, width, height }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M13.98 4.13414L8.48 1.12477C8.33305 1.04357 8.16789 1.00098 8 1.00098C7.83211 1.00098 7.66695 1.04357 7.52 1.12477L2.02 4.13539C1.86293 4.22133 1.73181 4.34787 1.64034 4.50178C1.54888 4.6557 1.50041 4.83135 1.5 5.01039V10.9879C1.50041 11.1669 1.54888 11.3426 1.64034 11.4965C1.73181 11.6504 1.86293 11.777 2.02 11.8629L7.52 14.8735C7.66695 14.9547 7.83211 14.9973 8 14.9973C8.16789 14.9973 8.33305 14.9547 8.48 14.8735L13.98 11.8629C14.1371 11.777 14.2682 11.6504 14.3597 11.4965C14.4511 11.3426 14.4996 11.1669 14.5 10.9879V5.01102C14.4999 4.83166 14.4516 4.65561 14.3601 4.50134C14.2686 4.34706 14.1373 4.22024 13.98 4.13414ZM8 1.99977L13.0212 4.74977L11.1606 5.76852L6.13875 3.01852L8 1.99977ZM8 7.49977L2.97875 4.74977L5.0975 3.58977L10.1187 6.33977L8 7.49977ZM2.5 5.62477L7.5 8.36102V13.7229L2.5 10.9885V5.62477ZM13.5 10.986L8.5 13.7229V8.36352L10.5 7.26914V9.49977C10.5 9.63238 10.5527 9.75955 10.6464 9.85332C10.7402 9.94709 10.8674 9.99977 11 9.99977C11.1326 9.99977 11.2598 9.94709 11.3536 9.85332C11.4473 9.75955 11.5 9.63238 11.5 9.49977V6.72164L13.5 5.62477V10.9854V10.986Z"
        fill={fill}
      />
    </svg>
  );
}
