import React, { useEffect } from "react";
import Routing from "./Routing.js";
import { useDispatch, useSelector } from "react-redux";
import {
  logIn,
  logOut,
  setClientDetails,
  setClientId,
  setUserDetails,
  setUserRoles,
} from "./slices/authSlice.js";
import {
  clearLocalStorage,
  getFromLocalStorage,
  saveToLocalStorage,
  showErrorMessage,
} from "./utils/helpers.js";

import { updateRegions, updateRegionFilter } from "./slices/regionSlice.js";
import axiosService from "./init/axios.js";
import { APIConfig } from "./utils/constants/api.constants.js";
import GoogleMapsProvider from "./context/GoogleWrapper.js";

const App = () => {
  const dispatch = useDispatch();
  const regions = useSelector((state) => state.region?.regions);
  const isAuthenticated =
    useSelector((state) => state.auth.userLoggedIn) ||
    getFromLocalStorage("userLoggedIn");

  const getUserInfo = async () => {
    axiosService
      .get(process.env.REACT_APP_UMS_BASE_URL + APIConfig.ums.getUserDetails)
      .then((e) => {
        const clientId = e.data.data.clientDetails.clientName;
        getClientInfo(clientId);
        dispatch(setUserDetails(e.data.data.userDetails));
        dispatch(setUserRoles(e.data.data.roles));
        dispatch(
          setClientId(
            // TODO: PS this logic
            clientId === "INDIVIDUAL" ? false : clientId
          )
        );
      })
      .catch((err) => {
        showErrorMessage(err);
        dispatch(logOut());
        clearLocalStorage();
      });
  };

  const getClientInfo = async (clientId) => {
    if (!clientId || clientId === "INDIVIDUAL") return;
    axiosService
      .get(
        process.env.REACT_APP_USER_BASE_URL +
          APIConfig.clients.getClientDetails(clientId)
      )
      .then(({ data }) => {
        dispatch(setClientDetails(data));
      })
      .catch((err) => {
        showErrorMessage(err);
        dispatch(logOut());
        clearLocalStorage();
      });
  };

  useEffect(() => {
    if (isAuthenticated) {
      getUserInfo();

      saveToLocalStorage("componentStates", {
        calendarToggle:
          getFromLocalStorage("componentStates")?.calendarToggle || true,
      });
      if (regions?.length === 0) {
        getRegions();
      }
    }
  }, [isAuthenticated]);

  const getRegions = async () => {
    await axiosService
      .get(
        process.env.REACT_APP_TRIP_BASE_URL +
          APIConfig.tripManagement.getRegions +
          "?key=REGION_CONFIG"
      )
      .then((data) => {
        const regions = data?.data?.regions?.map((data) => ({
          ...data,
          label: data?.regionName,
          value: data?.regionId,
        }));
        const subRegions = data?.data?.subRegions?.map((data) => ({
          ...data,
          label: data?.regionName,
          value: data?.regionId,
        }));
        const allRegions = {
          subRegions: subRegions,
          regions: regions,
        };
        dispatch(updateRegions(allRegions));
        if (getFromLocalStorage("regionFilters")) {
          dispatch(updateRegionFilter(getFromLocalStorage("regionFilters")));
        }
      })
      .catch((error) => {
        showErrorMessage(error);
      });
  };

  return (
    <div className="App">
      <GoogleMapsProvider>
        <Routing />
      </GoogleMapsProvider>
    </div>
  );
};

export default App;
