import React, { createContext, useContext } from "react";
import { LoadScript } from "@react-google-maps/api";

const GoogleMapsContext = createContext();

const libraries = ["places"];

const GoogleMapsProvider = ({ children }) => {
  return (
    <LoadScript
      loadingElement={<></>}
      googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API}
      libraries={libraries}
    >
      <GoogleMapsContext.Provider value={{}}>
        {children}
      </GoogleMapsContext.Provider>
    </LoadScript>
  );
};

export const useGoogleMaps = () => useContext(GoogleMapsContext);

export default GoogleMapsProvider;
