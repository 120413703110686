import React, { Fragment, useEffect, useState } from "react";
import CardLayout from "../../components/common/CardLayout";
import TripManagementTable from "../../components/trip-management/TripManagementTable";
import { useLocation, useParams } from "react-router-dom";
import TripDetailPage from "../../components/trip-management/trip-details/TripDetailPage";
import { useDispatch, useSelector } from "react-redux";
import { updateTripRegionFilter } from "../../slices/regionSlice";
import {
  formatDate,
  getFromLocalStorage,
  toDateString,
} from "../../utils/helpers";
import { DropdownSearchMultiSelect } from "../../utils/forms/NewFormComponents";
import ToggleSwitch from "../../components/common/Components";
import { updateCalendarToggle } from "../../slices/componentStatesSlice";

const TripManagement = () => {
  const location = useLocation();
  const { tripId } = useParams();

  const clientDetails = useSelector((state) => state.auth?.clientDetails);

  const [tripDetailsSelected, setTripDetailsSelected] = useState(false);
  const [statusFilter, setStatusFilter] = useState("All");
  const regions = useSelector((state) => state.region?.mainRegions);
  const selectedTripRegionFilters = useSelector((state) => state.region?.trip);
  const dispatch = useDispatch();
  const [calendarDate, setCalendarDate] = useState(toDateString(new Date()));
  const [calendarToggle, setCalendarToggle] = useState(
    getFromLocalStorage("componentStates")?.calendarToggle
  );

  const [dataState, setDataState] = useState({
    take: 10,
    skip: 0,
    otherParams:
      getFromLocalStorage("regionFilters") &&
      getFromLocalStorage("regionFilters")?.trip?.length > 0
        ? {
            parent_region_id:
              getFromLocalStorage("regionFilters")
                ?.trip?.reduce((acc, curr) => (acc += curr?.value + ","), "")
                .slice(0, -1) + ":in:string",
            scenario: "AP_PANEL_HIDE_B2C_SUBS",
            ...(calendarToggle && {
              expected_trip_start_time: calendarDate + ":eq:date",
            }),
          }
        : {
            ...(calendarToggle && {
              expected_trip_start_time: calendarDate + ":eq:date",
            }),
            scenario: "AP_PANEL_HIDE_B2C_SUBS",
          },
  });

  useEffect(() => {
    if (tripId && location.pathname.split("/").at(-1) === "details") {
      setTripDetailsSelected(true);
    } else {
      setTripDetailsSelected(false);
    }
  }, [location]);

  const onTripRegionFilterChange = (e) => {
    dispatch(updateTripRegionFilter([...e]));
    let otherParams = {
      ...(calendarToggle && {
        expected_trip_start_time: calendarDate + ":eq:date",
      }),
      scenario: "AP_PANEL_HIDE_B2C_SUBS",
    };
    if (statusFilter?.filters?.length > 0) {
      const [key, value] = statusFilter.filters[0].split("=");
      otherParams[key] = value;
    }
    if (e.length > 0) {
      const regionQueryStr = e?.reduce(
        (acc, curr) => (acc += curr?.value + ","),
        ""
      );

      otherParams["parent_region_id"] =
        regionQueryStr.slice(0, -1) + ":in:string";

      setDataState({
        ...dataState,
        skip: 0,
        take: 10,
        otherParams: otherParams,
      });
    } else {
      if (
        dataState?.otherParams &&
        Object.keys(dataState?.otherParams)?.length > 0
      ) {
        setDataState({
          ...dataState,
          skip: 0,
          take: 10,
          otherParams: otherParams,
        });
      }
    }
  };

  const updatedRegions = regions
    ?.slice()
    ?.map((item) => {
      const isSelected = selectedTripRegionFilters.some(
        (selectedItem) => selectedItem.regionId === item.regionId
      );

      if (isSelected) {
        return null;
      }

      return {
        ...item,
        label: item.child
          ? `${item.regionName} (${item.parentRegionName})`
          : item.regionName,
      };
    })
    .filter((item) => item !== null);

  const handleCalendarDateChange = (e) => {
    if (e.target.value) {
      setCalendarDate(e.target.value);
      let otherParams = {
        ...(calendarToggle && {
          expected_trip_start_time: e.target.value + ":eq:date",
        }),
        scenario: "AP_PANEL_HIDE_B2C_SUBS",
      };
      if (statusFilter?.filters?.length > 0) {
        const [key, value] = statusFilter.filters[0].split("=");
        otherParams[key] = value;
      }

      setDataState({
        ...dataState,
        skip: 0,
        take: 10,
        otherParams: otherParams,
      });
    }
  };

  const handleCalendarToggle = (e) => {
    setCalendarToggle(e.target.checked);
    dispatch(updateCalendarToggle(e.target.checked));
    let otherParams = {
      ...(e.target.checked && {
        expected_trip_start_time: calendarDate + ":eq:date",
      }),
      scenario: "AP_PANEL_HIDE_B2C_SUBS",
    };
    if (statusFilter?.filters?.length > 0) {
      const [key, value] = statusFilter.filters[0].split("=");
      otherParams[key] = value;
    }

    setDataState({
      ...dataState,
      skip: 0,
      take: 10,
      otherParams: otherParams,
    });
  };

  const TripTopBar = () => (
    <div
      className={`${
        !clientDetails?.clientId ? "tw-justify-between" : "tw-justify-end"
      } tw-flex tw-items-center`}
    >
      {!clientDetails?.clientId && (
        <DropdownSearchMultiSelect
          options={updatedRegions}
          textField="label"
          values={selectedTripRegionFilters}
          onChange={onTripRegionFilterChange}
        />
      )}
      <div className="tw-flex tw-items-center tw-gap-5">
        <div className="tw-relative tw-flex tw-items-center">
          <input
            type="date"
            name="calendar"
            id="calendar"
            required
            disabled={!calendarToggle}
            value={calendarDate}
            onChange={handleCalendarDateChange}
            className="tw-opacity-0 tw-pointer-events-none customDatePicker"
          />
          <label
            className={`custom-border-primary tw-block tw-text-nowrap tw-rounded-md tw-text-sm tw-px-3 tw-py-1.5 ${
              !calendarToggle
                ? "tw-opacity-40 tw-cursor-not-allowed"
                : " tw-cursor-pointer"
            }`}
            htmlFor="calendar"
            onClick={() => document.getElementById("calendar").showPicker()}
          >
            {formatDate(calendarDate)}
          </label>
        </div>
        <div className="tw-mt-1">
          <ToggleSwitch
            type="checkbox"
            checked={calendarToggle}
            onChange={handleCalendarToggle}
          />
        </div>
      </div>
    </div>
  );

  const isTripDetailsPage =
    tripId && location.pathname.split("/").at(-1) === "details";

  return (
    <>
      {isTripDetailsPage ? (
        <TripDetailPage setTripDetailsSelected={setTripDetailsSelected} />
      ) : (
        <CardLayout title="Trip Management" ComponentRender={TripTopBar}>
          <TripManagementTable
            calendarToggle={calendarToggle}
            calendarDate={calendarDate}
            setStatusFilter={setStatusFilter}
            statusFilter={statusFilter}
            setTripDetailsSelected={setTripDetailsSelected}
            dataState={dataState}
            setDataState={setDataState}
          />
        </CardLayout>
      )}
    </>
  );
};

export default TripManagement;
