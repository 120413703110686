import React, { useState } from "react";
import ModalLayout from "../common/ModalLayout";
import { FormDropDownList } from "../../utils/forms/FormComponents";
import { CouponConfig } from "../../config/couponConfig";
import { Button } from "@progress/kendo-react-buttons";

const CouponSubmissionModal = ({
  onClose,
  handleSubmit,
  isReferral = false,
}) => {
  const [selectedCouponIssueReason, setSelectedCouponIssueReason] = useState({
    label: "Customer Call - Grievance",
    value: "CUSTOMER_CALL_GRIEVANCE",
  });
  const [selectedCouponIssueSubReason, setSelectedCouponIssueSubReason] =
    useState({
      label: "Rash Driving",
      value: "RASH_DRIVING",
    });

  return (
    <ModalLayout title={"Please Confirm"} onClose={onClose}>
      <div
        className="add-coupon-div"
        style={{ minWidth: !isReferral ? 700 : 300 }}
      >
        {!isReferral ? (
          <>
            <div className="row">
              <div className="col-sm-3">Select Reason</div>
              <div className="col-sm-5">
                <FormDropDownList
                  data={CouponConfig.reason}
                  textField="label"
                  dataItemKey="value"
                  value={selectedCouponIssueReason}
                  onChange={(e) => setSelectedCouponIssueReason(e.target.value)}
                  style={{ width: "200px" }}
                />
              </div>
            </div>
            <div className="row my-2">
              <div className="col-sm-3">Select SubReason</div>
              <div className="col-sm-5">
                <FormDropDownList
                  data={CouponConfig.subReason}
                  textField="label"
                  dataItemKey="value"
                  value={selectedCouponIssueSubReason}
                  onChange={(e) =>
                    setSelectedCouponIssueSubReason(e.target.value)
                  }
                  style={{ width: "200px" }}
                />
              </div>
            </div>
          </>
        ) : (
          <></>
        )}
        {!isReferral ? (
          <Button
            themeColor={"primary"}
            type={"button"}
            onClick={(e) =>
              handleSubmit(
                selectedCouponIssueReason?.value,
                selectedCouponIssueSubReason?.value
              )
            }
          >
            Activate
          </Button>
        ) : (
          <Button
            themeColor={"primary"}
            type={"button"}
            style={{
              marginLeft: "auto",
              marginRight: "auto",
              display: "block",
            }}
            onClick={(e) => handleSubmit()}
          >
            Activate
          </Button>
        )}
      </div>
    </ModalLayout>
  );
};

export default CouponSubmissionModal;
