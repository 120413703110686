import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import GoogleMapsWrapper from "../maps/GoogleMapsWrapper";
import { mapDefaults } from "../maps/defaults";
import startPointSymbol from "../../assets/start-point-2.png";
import { ConfirmationDialog } from "../maps/AddTaskMap";
import LoadingSpinner from "../common/LoadingSpinner";

export default function AddressMap({ initialPickup, setAddress, editable }) {
  const containerStyle = { width: "100%", height: "100%" };
  const mapRef = useRef(null);
  const onLoad = useCallback((map) => {
    mapRef.current = map;
  }, []);
  const [mapLoaded, setMapLoaded] = useState(false);

  const onTilesLoaded = (e) => {
    setMapLoaded(true);
  };
  const center = useMemo(() => mapDefaults.addTaskMap.center, []);
  const options = useMemo(() => mapDefaults.addTaskMap.options, []);

  const [dragConfirmation, setDragConfirmation] = useState(false);
  const [draggingMarker, setDraggingMarker] = useState(null);
  const [previousPosition, setPreviousPosition] = useState(null);
  const [newPosition, setNewPosition] = useState(null);

  const markerRef = useRef(null);

  const createMarker = useCallback(
    (position, icon, draggable = false) => {
      return new window.google.maps.Marker({
        position,
        map: mapRef.current,
        icon: {
          url: icon,
          scaledSize: new window.google.maps.Size(30, 30),
        },
        draggable: editable ? draggable : false,
      });
    },
    [initialPickup]
  );

  const updateMarkerPosition = useCallback(
    (marker, position) => {
      marker.setPosition(position);
      mapRef.current.panTo(position);
    },
    [initialPickup]
  );

  const removeMarker = useCallback((markerRef) => {
    if (markerRef.current) {
      markerRef.current.setMap(null);
      markerRef.current = null;
    }
  }, []);

  const geocodeLatLng = useCallback((lat, lng) => {
    return new Promise((resolve, reject) => {
      const geocoder = new window.google.maps.Geocoder();
      const latlng = { lat, lng };

      geocoder.geocode({ location: latlng }, (results, status) => {
        if (status === "OK") {
          if (results[0]) {
            resolve(results[0]);
          } else {
            resolve({ description: "No results found" });
          }
        } else {
          reject("Geocoder failed due to: " + status);
        }
      });
    });
  }, []);

  //   const recenterMap = useCallback(() => {
  //     if (!markerRef.current) return null;
  //     const bounds = new window.google.maps.LatLngBounds();

  //     if (markerRef.current) {
  //       bounds.extend(markerRef.current.getPosition());
  //     }
  //     mapRef.current.fitBounds(bounds);
  //   }, []);

  const handleDragEnd = async (marker, type) => {
    const newPosition = marker.getPosition();
    setDraggingMarker(marker);
    setPreviousPosition(marker.getPosition());
    setNewPosition(newPosition);
    setDragConfirmation(true);
  };

  const confirmDragEnd = async () => {
    setDragConfirmation(false);
    const result = await geocodeLatLng(newPosition.lat(), newPosition.lng());

    let pincode = "";
    if (result.address_components) {
      const postalCodeComponent = result.address_components.find((component) =>
        component.types.includes("postal_code")
      );
      if (postalCodeComponent) {
        pincode = postalCodeComponent.long_name;
      }
    }

    setAddress((prev) => ({
      ...prev,
      pickup: {
        description: result.formatted_address,
        coordinates: { lat: newPosition.lat(), lng: newPosition.lng() },
        place_id: result.place_id,
        reference: result.place_id,
        structured_formatting: {
          main_text:
            result.address_components.find((component) =>
              component.types.includes("locality")
            )?.long_name || "",
          secondary_text:
            result.address_components.find((component) =>
              component.types.includes("administrative_area_level_1")
            )?.long_name || "",
        },
        terms: result.address_components.map((component) => ({
          offset: 0,
          value: component.long_name,
        })),
        types: result.types,
        pincode,
        city:
          result.address_components.find((component) =>
            component.types.includes("locality")
          )?.long_name || "",
        state:
          result.address_components.find((component) =>
            component.types.includes("administrative_area_level_1")
          )?.long_name || "",
      },
    }));
  };

  const cancelDragEnd = () => {
    setDragConfirmation(false);
    draggingMarker.setPosition(previousPosition);
  };

  useEffect(() => {
    if (mapLoaded && initialPickup && initialPickup.coordinates) {
      const { lat, lng } = initialPickup.coordinates;
      const position = new window.google.maps.LatLng(lat, lng);
      if (markerRef.current) {
        updateMarkerPosition(markerRef.current, position);
      } else {
        markerRef.current = createMarker(position, startPointSymbol, true);
        mapRef.current.panTo(position);
        markerRef.current.addListener("dragend", () =>
          handleDragEnd(markerRef.current, "pickup")
        );
      }
    } else {
      removeMarker(markerRef);
    }
  }, [initialPickup, mapLoaded]);

  return (
    <>
      {!mapLoaded && (
        <div className="tw-bg-black/20 tw-absolute tw-top-0 tw-h-full tw-w-full tw-left-0 tw-z-[1] tw-grid tw-place-content-center">
          <LoadingSpinner />
        </div>
      )}
      <GoogleMapsWrapper
        containerStyle={containerStyle}
        options={options}
        center={center}
        zoom={mapDefaults.addTaskMap.zoom}
        onLoad={onLoad}
        onTilesLoaded={onTilesLoaded}
        // mapRef={mapRef.current}
      />
      <ConfirmationDialog
        show={dragConfirmation}
        onConfirm={confirmDragEnd}
        onCancel={cancelDragEnd}
      />
    </>
  );
}
