import React from "react";
import { ColorRing } from "react-loader-spinner";

const LoadingSpinner = ({ height, width, color }) => {
  return (
    <ColorRing
      visible={true}
      height={height ? height : "80"}
      width={width ? width : "80"}
      ariaLabel="blocks-loading"
      wrapperStyle={{ margin: "0 auto" }}
      wrapperClass="blocks-wrapper"
      colors={
        color
          ? new Array(5).fill(color)
          : ["#fff", "#fff", "#fff", "#fff", "#fff"]
      }
    />
  );
};

export default LoadingSpinner;
