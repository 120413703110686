import { Field, Form, FormElement } from "@progress/kendo-react-form";
import React, { useEffect, useRef, useState } from "react";
import { FormNumericTextBox } from "../../utils/forms/FormComponents";
import { Button } from "@progress/kendo-react-buttons";
import axiosService from "../../init/axios";
import { APIConfig } from "../../utils/constants/api.constants";
import {
  handleOnWheel,
  showErrorMessage,
  showToastMessage,
} from "../../utils/helpers";
import LoadingPanel from "../common/LoadingPanel";
import { useSelector } from "react-redux";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { debounce } from "lodash";
import ModalLayout from "../common/ModalLayout";
import { ChartView } from "./PricingConfigHome";

const PricingConfigurationForm = ({
  tripConfigState,
  gridData,
  setGridData,
}) => {
  const [viewGraph, setViewGraph] = useState(false);
  const [loading, setLoading] = useState(false);
  const numericTextBoxesRef = useRef([]);
  const [selectedConfigDetails, setSelectedConfigDetails] = useState();
  const selectedPricingRegionFilter = useSelector(
    (state) => state.region?.pricing
  );

  const [gridDataOriginal, setGridDataOriginal] = useState([]);

  useEffect(() => {
    if (selectedPricingRegionFilter)
      getPricingDetails(
        tripConfigState?.tripType.value,
        tripConfigState?.journeyMode?.value,
        tripConfigState?.durationType?.value
      );
  }, [tripConfigState, selectedPricingRegionFilter]);

  // onWheel scroll stop
  useEffect(() => {
    const refs = numericTextBoxesRef?.current;
    for (let i = 0; i < refs.length; i++) {
      refs[i]?.element.addEventListener("wheel", handleOnWheel);
    }
    return () => {
      for (let i = 0; i < refs.length; i++) {
        refs[i]?.element.removeEventListener("wheel", handleOnWheel);
      }
    };
  });

  const getPricingDetails = async (tripType, tripSubType, durationType) => {
    setLoading(true);
    const baseUrl = process.env.REACT_APP_PRICING_BASE_URL;
    const endpoint = APIConfig.pricingManagement.getPricingConfig;
    let url = `${baseUrl}${endpoint}?derivedTripType=${tripType}&journeyMode=${tripSubType}&durationType=${durationType}&regionId=${selectedPricingRegionFilter?.value}`;
    if (selectedPricingRegionFilter?.child) {
      url += `&parentRegionId=${selectedPricingRegionFilter?.parentId}`;
    }
    await axiosService
      .get(url)
      .then((data) => {
        const configDetails = data?.data?.pricingAndDiluterConfigV2;
        setSelectedConfigDetails(configDetails?.pricingConfig);
        const diluterConfig = configDetails?.diluterConfig || [];

        if (
          diluterConfig.length > 0 &&
          diluterConfig[diluterConfig.length - 1].evaluationType ===
            "PERCENTAGE"
        ) {
          diluterConfig[diluterConfig.length - 1]["evaluatedValue"] =
            diluterConfig[diluterConfig.length - 1]["originalBaseRate"] -
            (diluterConfig[diluterConfig.length - 1]["discountPercentage"] *
              diluterConfig[diluterConfig.length - 1]["originalBaseRate"]) /
              100;
        }

        setGridData(diluterConfig);
        setGridDataOriginal(diluterConfig);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        showErrorMessage(error);
      });
  };

  const handleSubmit = async (data) => {
    if (gridData[gridData.length - 1].evaluationType === "PERCENTAGE") {
      gridData[gridData.length - 1]["evaluatedValue"] =
        gridData[gridData.length - 1]["discountPercentage"];
    }

    const payload = {
      derivedTripType: tripConfigState?.tripType.value,
      journeyMode: tripConfigState?.journeyMode?.value,
      durationType: tripConfigState?.durationType?.value,
      regionId: selectedPricingRegionFilter?.value,
      fareConfigValue: {
        pricingConfig: {
          baseRate: data?.valueGetter("baseRate") || 0,
          maxOneWayCharges: data?.valueGetter("maxOneWayCharges") || 0,
          nightCharges: data?.valueGetter("nightCharges") || 0,
          oneWayPerKmCharges: data?.valueGetter("oneWayPerKmCharges") || 0,
          outstationCharges: data?.valueGetter("outstationCharges") || 0,
          platformFees: data?.valueGetter("platformFees") || 0,
          weekendCharges: data?.valueGetter("weekendCharges") || 0,
        },
        diluterConfig: gridData,
      },
    };

    setLoading(true);

    await axiosService
      .post(
        `${process.env.REACT_APP_PRICING_BASE_URL}${APIConfig.pricingManagement.savePricingConfig}`,
        payload,
        { "Content-Type": "application/json" }
      )
      .then((response) => {
        setLoading(false);
        setViewGraph(false);
        showToastMessage("Pricing configuration updated successfully");
        getPricingDetails(
          tripConfigState?.tripType.value,
          tripConfigState?.journeyMode?.value,
          tripConfigState?.durationType?.value
        );
      })
      .catch((error) => {
        setLoading(false);
        showErrorMessage(error);
      });
  };

  const handleFinalRateChange = (event, dataItem) => {
    const finalRate = event.value;
    const baseRate = dataItem.originalBaseRate;

    const pricingDiluter = baseRate
      ? ((baseRate - finalRate) / baseRate) * 100
      : 0;

    const updatedData = gridData.map((item) =>
      item === dataItem
        ? { ...item, evaluatedValue: finalRate, pricingDiluter: pricingDiluter }
        : item
    );

    setGridData(updatedData);
    // setGridDataOriginal(updatedData);
  };

  const handlePricingDiluterChange = (event, dataItem) => {
    const baseRate = dataItem.originalBaseRate;
    const percentageDiluter = event.value;

    const newFinalRate = baseRate - (percentageDiluter * baseRate) / 100;

    const updatedData = gridData.map((item) =>
      item === dataItem
        ? {
            ...item,
            evaluatedValue: newFinalRate,
            discountPercentage: event.value,
          }
        : item
    );

    setGridData(updatedData);
    // setGridDataOriginal(updatedData);
  };

  const handleBaseRateChange = (event) => {
    const newBaseRate = Number(event.target.value);
    setGridData(() => {
      const newData = gridDataOriginal.map((item, index) => {
        return {
          ...item,
          originalBaseRate: newBaseRate * index + newBaseRate,
        };
      });

      return newData;
    });
  };

  const handleSaveDisabled = (formRenderProps) => {
    const formModified = formRenderProps?.modified;

    // Check if grid data is different from the original grid data
    const gridModified = !compareObjectsByKeys(gridData, gridDataOriginal, [
      "originalBaseRate",
      "evaluatedValue",
      "discountPercentage",
    ]);

    // Ensure all base rates are valid
    const baseRatesValid = checkBaseRates(gridData);

    // Enable Save button if either form or grid data is modified and base rates are valid
    if ((formModified || gridModified) && baseRatesValid) {
      return false;
    }
    return true;
  };

  return (
    <div className="pricing-config">
      <Form
        initialValues={{
          ...selectedConfigDetails,
        }}
        key={JSON.stringify(selectedConfigDetails)}
        render={(formRenderProps) => (
          <>
            {loading ? (
              <LoadingPanel />
            ) : (
              <FormElement className="tw-grid tw-grid-cols-2 tw-gap-5">
                <FormInputComponent
                  onChange={handleBaseRateChange}
                  id={"baseRate"}
                  label={"Base Rate"}
                  numericTextBoxesRef={numericTextBoxesRef}
                  value={selectedConfigDetails?.baseRate}
                  refIndex={0}
                />

                <FormInputComponent
                  className="tw-col-span-1 tw-col-start-1"
                  id={"oneWayPerKmCharges"}
                  label={"One Way Per Km Charge"}
                  numericTextBoxesRef={numericTextBoxesRef}
                  value={selectedConfigDetails?.oneWayPerKmCharges}
                  refIndex={1}
                />

                <FormInputComponent
                  id={"maxOneWayCharges"}
                  label={"Max One Way Charge"}
                  numericTextBoxesRef={numericTextBoxesRef}
                  value={selectedConfigDetails?.maxOneWayCharges}
                  refIndex={2}
                />

                <FormInputComponent
                  id={"platformFees"}
                  label={"Platform Fees"}
                  numericTextBoxesRef={numericTextBoxesRef}
                  value={selectedConfigDetails?.platformFees}
                  refIndex={3}
                />

                <FormInputComponent
                  id={"weekendCharges"}
                  label={"Weekend Charges"}
                  numericTextBoxesRef={numericTextBoxesRef}
                  value={selectedConfigDetails?.weekendCharges}
                  refIndex={4}
                />

                <FormInputComponent
                  id={"nightCharges"}
                  label={"Night Charges"}
                  numericTextBoxesRef={numericTextBoxesRef}
                  value={selectedConfigDetails?.nightCharges}
                  refIndex={5}
                />

                <FormInputComponent
                  id={"outstationCharges"}
                  label={"Outstation Fees"}
                  numericTextBoxesRef={numericTextBoxesRef}
                  value={selectedConfigDetails?.outstationCharges}
                  refIndex={6}
                />

                <div className="tw-col-span-2">
                  <Grid
                    data={gridData}
                    filterable={false}
                    sortable={false}
                    pageable={false}
                  >
                    <Column cell={RangeCell} title="Range" />
                    <Column field="originalBaseRate" title="Base Rate" />
                    <Column
                      cell={(props) => (
                        <FinalRateCell
                          props={props}
                          onChange={(event) =>
                            handleFinalRateChange(event, props.dataItem)
                          }
                        />
                      )}
                      title="Final Rate"
                    />
                    <Column
                      title="Pricing Diluter (%)"
                      cell={(props) => (
                        <PricingPercentageCell
                          onChange={handlePricingDiluterChange}
                          props={props}
                        />
                      )}
                    />
                  </Grid>
                </div>
                <div className="tw-col-span-2">
                  <Button
                    onClick={() => setViewGraph(true)}
                    themeColor={"primary"}
                    type={"submit"}
                    disabled={handleSaveDisabled(formRenderProps)}
                  >
                    Save
                  </Button>
                  {viewGraph && (
                    <ModalLayout
                      onClose={() => setViewGraph(false)}
                      title={"Pricing Logic Graph"}
                    >
                      <div className="tw-w-[60vw] tw-p-5">
                        <ChartView data={gridData} />
                        <div className="tw-mt-10 tw-flex tw-justify-end">
                          <Button
                            onClick={() => handleSubmit(formRenderProps)}
                            themeColor={"primary"}
                            type={"submit"}
                          >
                            Confirm
                          </Button>
                        </div>
                      </div>
                    </ModalLayout>
                  )}
                </div>
              </FormElement>
            )}
          </>
        )}
      />
    </div>
  );
};

export const FormInputComponent = ({
  className = "",
  label,
  id,
  value,
  numericTextBoxesRef,
  refIndex,
  charges = true,
  onChange,
}) => {
  return (
    <div
      className={`tw-flex tw-items-center tw-justify-between tw-w-2/3 ${className}`}
    >
      <span className="">{label}</span>
      <span className="tw-flex tw-items-center tw-gap-2">
        {charges && <span className="">{"₹"}</span>}
        <Field
          onChange={onChange}
          id={id}
          name={id}
          value={value}
          format={"n0"}
          component={FormNumericTextBox}
          defaultValue={0}
          min={0}
          refP={(el) => (numericTextBoxesRef.current[refIndex] = el)}
        />
      </span>
    </div>
  );
};

const RangeCell = (props) => {
  return (
    <td>
      <span className="tw-capitalize">
        {props?.dataItem?.min} - {props?.dataItem?.max}{" "}
        {props?.dataItem?.timeUnit.toLowerCase()}
      </span>
    </td>
  );
};

const FinalRateCell = ({ props, onChange }) => {
  const baseRate = props.dataItem.originalBaseRate;
  const initialRate =
    baseRate - (props.dataItem.discountPercentage * baseRate) / 100;

  const initialValue = props?.dataItem?.evaluatedValue
    ? props.dataItem.evaluatedValue
    : initialRate;

  const [value, setValue] = useState(initialValue);
  const isNotEditable = props?.dataItem?.evaluationType === "PERCENTAGE";

  const debouncedOnChange = useRef(
    debounce((newFinalRate, dataItem) => {
      onChange({ value: newFinalRate }, dataItem);
    }, 1200)
  ).current;

  const handleRateChange = (event) => {
    const newFinalRate = parseFloat(event.target.value) || 0;
    setValue(newFinalRate);
    debouncedOnChange(newFinalRate, props.dataItem);
  };

  return (
    <td>
      <FormNumericTextBox
        style={
          baseRate < value && { background: "#f8c7c090", borderColor: "red" }
        }
        value={value}
        onChange={handleRateChange}
        disabled={isNotEditable}
      />
    </td>
  );
};

const PricingPercentageCell = ({ props, onChange }) => {
  const [value, setValue] = useState(props.dataItem.discountPercentage);
  const debouncedOnChange = useRef(
    debounce((newPercentageValue, dataItem) => {
      onChange({ value: newPercentageValue }, dataItem);
    }, 1200)
  ).current;

  const handlePriceChange = (event) => {
    if (isNotEditable) return;

    const inputValue = event.target.value;
    const newPercentageValue = inputValue !== "" ? parseFloat(inputValue) : 0;

    setValue(inputValue);
    if (!isNaN(newPercentageValue)) {
      debouncedOnChange(newPercentageValue, props.dataItem);
    }
  };

  const baseRate = props?.dataItem?.originalBaseRate;
  const finalRate = props?.dataItem?.evaluatedValue;
  const isNotEditable = props?.dataItem?.evaluationType === "FLAT";

  const pricingDiluter = (() => {
    if (isNotEditable) {
      if (baseRate === 0) {
        return 0;
      }
      const percentage = Math.max(((baseRate - finalRate) / baseRate) * 100, 0);
      return isNaN(percentage) ? 0 : percentage;
    }
    return value;
  })();

  return (
    <td>
      <FormNumericTextBox
        onChange={handlePriceChange}
        value={pricingDiluter}
        step={0.01} // Allow decimal steps
        format="n2" // Ensures two decimal points are displayed
        min={0} // Set a minimum value if needed
        disabled={isNotEditable}
      />
    </td>
  );
};

function checkBaseRates(data) {
  return data.every((item) => {
    return (
      item?.evaluatedValue === undefined ||
      item?.originalBaseRate >= item?.evaluatedValue
    );
  });
}

const compareObjectsByKeys = (data, originalData, keysToCompare = null) => {
  const compare = (obj1, obj2, keys) => {
    const keysToCheck = keys || Object.keys(obj1);
    return keysToCheck.every((key) => obj1[key] === obj2[key]);
  };
  if (Array.isArray(data) && Array.isArray(originalData)) {
    if (data.length !== originalData.length) return false;
    return data.every((dataItem, index) =>
      compare(dataItem, originalData[index], keysToCompare)
    );
  }
  if (typeof data === "object" && typeof originalData === "object") {
    return compare(data, originalData, keysToCompare);
  }

  return false;
};

export default PricingConfigurationForm;
