import React from 'react'
import ModalLayout from '../../common/ModalLayout';
import { Button } from '@progress/kendo-react-buttons';
import LoadingSpinner from '../../common/LoadingSpinner';

const DriverPayoutApproveModal = ({loading,payoutApprovalDetails,onApprovePayout,onClose}) => {
    
    const onSubmit = () => {
        onApprovePayout();
    }

    return (
        <ModalLayout title='Payout Confirmation' onClose={onClose}>
            <div className='uploaded-payout-details' style={{minWidth:500}}>
                <h6>Payouts to be approved : {payoutApprovalDetails?.approvedTransactionCount ? payoutApprovalDetails?.approvedTransactionCount : 0}</h6>
                <h6>Payouts to be rejected : {payoutApprovalDetails?.rejectedTransactionCount ? payoutApprovalDetails?.rejectedTransactionCount : 0}</h6>
                <br />
                <Button
                    themeColor={"primary"}
                    type={"button"}
                    disabled={loading}
                    onClick={onSubmit}
                >
                    {loading ? <LoadingSpinner width={25} height={25} /> : 'Confirm'}
                </Button>
                &nbsp;&nbsp;
                <Button
                    themeColor={"primary"}
                    type={"button"}
                    disabled={loading}
                    onClick={onClose}
                >
                    Cancel
                </Button>
            </div>
        </ModalLayout>
    )
}

export default DriverPayoutApproveModal