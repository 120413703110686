import React, { useState } from "react";
import { APIConfig } from "../../../utils/constants/api.constants";
import {
  convertLabelToEnum,
  getLabelFromEnum,
  showErrorMessage,
} from "../../../utils/helpers";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import GridLoader from "../../common/GridLoader";
import axiosService from "../../../init/axios";
import PricingChangeDiffModal from "./PricingChangeDiffModal";
import { PricingConfig } from "../../../config/pricingConfig";
import { useSelector } from "react-redux";

const PricingChangeLogsTable = () => {
  const selectedPricingRegionFilter = useSelector(
    (state) => state.region?.pricing
  );
  const baseUrl =
    process.env.REACT_APP_PRICING_BASE_URL +
    APIConfig.pricingManagement.getPricingLogs;
  const [modalOpen, setModalOpen] = useState(false);
  const [changedConfigData, setChangedConfigData] = useState([]);
  const [pricingChangeLogs, setPricingChangeLogs] = useState({
    data: [],
    total: 0,
  });

  const [dataState, setDataState] = useState({
    take: 10,
    skip: 0,
    otherParams: {
      regionId: selectedPricingRegionFilter?.value,
    },
  });

  const columns = [
    {
      id: "fareConfigType",
      field: "fareConfigType",
      title: "Change Type",
    },
    {
      id: "updatedBy",
      field: "updatedBy",
      title: "Updated By",
    },
    {
      id: "updatedAt",
      field: "updatedAt",
      title: "Updated At",
    },
    {
      id: "derivedTripType",
      field: "derivedTripType",
      title: "Trip Type",
    },
    {
      id: "journeyMode",
      field: "journeyMode",
      title: "Journey Mode",
    },
    {
      id: "durationType",
      field: "durationType",
      title: "Duration Type",
    },
    {
      id: "regionId",
      field: "regionId",
      title: "Region ID",
    },
  ];

  const dataStateChange = (e) => {
    setDataState(e.dataState);
  };

  const dataReceived = (dataObj) => {
    const updatedLogs = dataObj.data.data.map((log) => {
      const updatedLogDetails = getLabelFromEnum(
        ["derivedTripType", "journeyMode", "durationType"],
        log,
        "trip"
      );
      return updatedLogDetails;
    });
    setPricingChangeLogs({ ...dataObj, data: updatedLogs });
  };

  const getPricingDifference = async (item) => {
    await axiosService
      .get(
        process.env.REACT_APP_PRICING_BASE_URL +
          APIConfig.pricingManagement.getConfigDifference(
            item?.dataItem?.fareConfigType,
            item?.dataItem?.configVersion
          ) +
          "?derivedTripType=" +
          convertLabelToEnum(item?.dataItem?.derivedTripType) +
          "&journeyMode=" +
          convertLabelToEnum(item?.dataItem?.journeyMode) +
          "&durationType=" +
          convertLabelToEnum(item?.dataItem?.durationType) +
          "&regionId=" +
          convertLabelToEnum(item?.dataItem?.regionId)
      )
      .then((data) => {
        let configDataArray = [];
        if (item?.dataItem?.fareConfigType === "PRICING_CONFIG") {
          if (
            data?.data?.oldConfig &&
            Object.keys(data?.data?.oldConfig)?.length > 0 &&
            data?.data?.newConfig &&
            Object.keys(data?.data?.newConfig)?.length > 0
          ) {
            configDataArray = Object.keys(data?.data?.oldConfig).map((key) => {
              let field = PricingConfig.pricing.filter(
                (item) => item.value === key
              )[0]?.label;
              return {
                field: field,
                oldConfig: data?.data?.oldConfig[key],
                newConfig: data?.data?.newConfig[key],
              };
            });
          }
        }

        if (item?.dataItem?.fareConfigType === "GCD_CONFIG") {
          if (
            data?.data?.oldConfig &&
            data?.data?.oldConfig?.length > 0 &&
            data?.data?.newConfig &&
            data?.data?.newConfig?.length > 0
          ) {
            configDataArray = (data?.data?.oldConfig).map((item, index) => {
              return {
                field: item?.min + "-" + item?.max + " " + item?.timeUnit,
                oldConfig: item?.discountPercentage,
                newConfig: data?.data?.newConfig[index]?.discountPercentage,
              };
            });
          }
        }
        setChangedConfigData(configDataArray);
        openPricingDifferenceModal();
      })
      .catch((error) => {
        showErrorMessage(error);
      });
  };

  const openPricingDifferenceModal = () => {
    setModalOpen(true);
  };

  const closePricingDifferenceModal = () => {
    setModalOpen(false);
    setChangedConfigData([]);
  };

  return (
    <div className="pricing-change-logs-table">
      <Grid
        filterable={false}
        sortable={false}
        pageable={true}
        {...dataState}
        data={pricingChangeLogs}
        onDataStateChange={dataStateChange}
        onRowDoubleClick={getPricingDifference}
      >
        {columns.map((column) => (
          <Column
            key={column?.id}
            field={column?.field}
            title={column?.title}
          />
        ))}
      </Grid>
      <GridLoader
        baseUrl={baseUrl}
        columns={columns}
        dataState={dataState}
        onDataReceived={dataReceived}
      />
      {modalOpen && (
        <PricingChangeDiffModal
          changedConfigData={changedConfigData}
          onClose={closePricingDifferenceModal}
        />
      )}
    </div>
  );
};

export default PricingChangeLogsTable;
