import React, { useState } from 'react'
import { APIConfig } from '../../../../utils/constants/api.constants';
import GridLoader from '../../../common/GridLoader';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';

const PennyDropLogs = ({driverId}) => {
    const baseUrl = process.env.REACT_APP_DRIVER_BASE_URL + APIConfig.driverManagement.getPennyDropLogs(driverId);
    const [pennyDropLogs, setPennyDropLogs] = useState({
        data: [],
        total: 0
    });

    const [dataState, setDataState] = useState({
        take: 10,
        skip: 0
    });
    const columns = [
        {
            id: "bankAccountNumber",
            field: "bankAccountNumber",
            title: "Bank Account Number",
        },
        {
            id: "ifsc",
            field: "ifsc",
            title: "IFSC",
        },
        {
            id: "accountHolderName",
            field: "accountHolderName",
            title: "Account Holder Name",
        },
        {
            id: "initiatedBy",
            field: "initiatedBy",
            title: "Initiated By",
        },
        {
            id: "initiatedAt",
            field: "initiatedAt",
            title: "Initiated At",
        },
        {
            id: "status",
            field: "status",
            title: "Success/Failure",
        },
        {
            id: "reason",
            field: "reason",
            title: "Failure Reason",
        }
    ];

    const dataStateChange = e => {
        setDataState(e.dataState);
    };
    const dataReceived = dataObj => {
        setPennyDropLogs({ data:dataObj?.data?.pennyDropLogs, total:0 });
    };
    return (
        <div className='penny-drop-table'>
            <Grid filterable={false} sortable={false} pageable={false} {...dataState}
                data={pennyDropLogs}
                onDataStateChange={dataStateChange}>
                {
                    columns.map(column => (
                        <Column key={column?.id} field={column?.field} title={column?.title} />
                    ))
                }
            </Grid>
            <GridLoader baseUrl={baseUrl} columns={columns} dataState={dataState} onDataReceived={dataReceived} />
        </div>
    )
}

export default PennyDropLogs;