import { Button } from "@progress/kendo-react-buttons";
import AddTaskMap from "../../maps/AddTaskMap";
import AddTaskForm from "../AddTaskForm";
import DestinationAutoComplete from "../../maps/DestinationAutoComplete";
import { LocationInputLoading } from "./LocationLoading";
import { SavedAddressModal } from "./SavedAddressModal";
import closeBtn from "../../../assets/closeButton.svg";

export const PickDropForm = ({
  address,
  loading,
  setAddressCallback,
  savedAddresses,
  formDetails,
  checkListIds,
  handleFinalProceed,
  setFormDetails,
  setAddressModal,
  addressModal,
}) => {
  const handleCheckboxChange = (section) => (e) => {
    setFormDetails((prevState) => ({
      ...prevState,
      [section]: {
        ...prevState[section],
        saveAddress: e.target.checked,
      },
    }));
  };
  return (
    <div className="tw-w-[95vw]">
      <div className="tw-flex tw-mb-2 tw-gap-5">
        <div className="tw-w-1/4 tw-h-[80dvh] tw-relative tw-pr-5 tw-overflow-y-auto">
          <h5 className="tw-text-base tw-font-semibold">
            Enter your Pickup details
          </h5>
          <div className="">
            <div>
              {address?.pickup?.description ? (
                <>
                  <div className="tw-flex tw-items-center tw-justify-between custom-border-primary tw-rounded-md tw-p-3 tw-gap-2 tw-text-sm tw-mb-2">
                    <span>{address?.pickup?.description}</span>
                    <button
                      onClick={() => {
                        setAddressCallback({
                          ...address,
                          pickup: "",
                        });
                        setFormDetails((prevState) => ({
                          ...prevState,
                          pickupDetails: {
                            ...prevState["pickupDetails"],
                            saveAddress: false,
                          },
                        }));
                      }}
                      className="tw-bg-transparent tw-border-none"
                    >
                      <img src={closeBtn} alt="Close" className="tw-w-6" />
                    </button>
                  </div>
                  {!address?.pickup.savedAddress && (
                    <>
                      <input
                        type="checkbox"
                        name="pickupAdd"
                        id="pickupAdd"
                        checked={formDetails.pickupDetails.saveAddress}
                        onChange={handleCheckboxChange("pickupDetails")}
                      />
                      <label htmlFor="pickupAdd" className="tw-text-sm tw-ml-1">
                        Save this address
                      </label>
                    </>
                  )}
                </>
              ) : (
                <>
                  <div className="tw-flex tw-gap-5 tw-items-center tw-relative">
                    {loading.locationInput ? (
                      <LocationInputLoading />
                    ) : (
                      <>
                        <DestinationAutoComplete
                          address={address}
                          pickup
                          setAddress={setAddressCallback}
                        />
                        <SavedAddressModal
                          savedAddresses={savedAddresses}
                          pickup
                          setAddressModal={setAddressModal}
                          addressModal={addressModal}
                          setAddress={setAddressCallback}
                        />
                      </>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="tw-mt-4">
            <AddTaskForm
              formDetails={formDetails}
              setFormDetails={setFormDetails}
              isPickupForm={true}
              checkListData={checkListIds}
            />
          </div>
        </div>
        <div className="tw-w-1/4 tw-h-[80dvh] tw-relative tw-pr-5 tw-overflow-y-auto">
          <h5 className="tw-text-base tw-font-semibold">
            Enter your drop details
          </h5>
          <div className="">
            <div>
              {address?.drop?.description ? (
                <>
                  <div className="tw-flex tw-items-center tw-justify-between custom-border-primary tw-rounded-md tw-p-3 tw-gap-2 tw-text-sm tw-mb-2">
                    <span>{address?.drop?.description}</span>
                    <button
                      onClick={() => {
                        setAddressCallback({
                          ...address,
                          drop: "",
                        });
                        setFormDetails((prevState) => ({
                          ...prevState,
                          dropDetails: {
                            ...prevState["dropDetails"],
                            saveAddress: false,
                          },
                        }));
                      }}
                      className="tw-bg-transparent tw-border-none"
                    >
                      <img src={closeBtn} alt="Close" className="tw-w-6" />
                    </button>
                  </div>
                  {!address?.drop.savedAddress && (
                    <>
                      <input
                        type="checkbox"
                        name="dropAdd"
                        id="dropAdd"
                        checked={formDetails.dropDetails.saveAddress}
                        onChange={handleCheckboxChange("dropDetails")}
                      />
                      <label htmlFor="dropAdd" className="tw-text-sm tw-ml-1">
                        Save this address
                      </label>
                    </>
                  )}
                </>
              ) : (
                <>
                  <div className="tw-flex tw-gap-5 tw-items-center tw-relative">
                    {loading.locationInput ? (
                      <LocationInputLoading />
                    ) : (
                      <>
                        <DestinationAutoComplete
                          address={address}
                          setAddress={setAddressCallback}
                        />
                        <SavedAddressModal
                          savedAddresses={savedAddresses}
                          setAddressModal={setAddressModal}
                          addressModal={addressModal}
                          setAddress={setAddressCallback}
                        />
                      </>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="tw-mt-4">
            <AddTaskForm
              formDetails={formDetails}
              setFormDetails={setFormDetails}
              checkListData={checkListIds}
            />
          </div>
        </div>
        <div className="tw-w-2/4 tw-h-[80dvh] tw-relative custom-border-primary tw-overflow-hidden tw-rounded-md">
          <AddTaskMap
            initialPickup={address.pickup}
            initialDrop={address.drop}
            setAddress={setAddressCallback}
          />
        </div>
      </div>

      <Button
        onClick={handleFinalProceed}
        themeColor="primary"
        className="tw-inline-block tw-mt-4 tw-w-[100px]"
      >
        Next
      </Button>
    </div>
  );
};
