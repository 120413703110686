import { useState } from "react";
import { useSelector } from "react-redux";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import ModalLayout from "../../common/ModalLayout";
import { APIConfig } from "../../../utils/constants/api.constants";
import {
  CategoryFilterCell,
  driverStatusTags,
  getLabelFromEnum,
  initialFilter,
  stringToSentenceCase,
} from "../../../utils/helpers";
import GridLoader from "../../common/GridLoader";

export default function BroadcastingModal({
  closeModal,
  handleBroadcast,
  tripId,
}) {
  const baseUrl =
    process.env.REACT_APP_TRIP_BASE_URL +
    APIConfig.driverManagement.getBroadcastedDrivers(tripId);

  const [driverProfiles, setDriverProfiles] = useState({
    data: [],
    total: 0,
  });
  const [dataState, setDataState] = useState({
    take: 10,
    skip: 0,
  });

  const columns = [
    {
      id: "driverId",
      field: "driverId",
      title: "Driver ID",
      filterable: true,
      filterKey: "driverId",
      filterType: "string",
      defaultFilterOperator: "eq",
    },
    {
      id: "fullName",
      field: "fullName",
      title: "Full Name",
      filterable: false,
    },
    {
      id: "driverMobileNumber",
      field: "driverMobileNumber",
      title: "Mobile Number",
      filterable: false,
    },
    {
      id: "driverStatus",
      field: "driverStatus",
      title: "Status",
      filterable: false,
      cell: (cell) => (
        <td
          colSpan={cell.colSpan}
          role="gridcell"
          aria-colindex={cell.ariaColumnIndex}
          aria-selected="false"
          datagridcolindex={cell.columnIndex}
        >
          {driverStatusTags(cell?.dataItem?.driverStatus)}
        </td>
      ),
    },
  ];

  const dataStateChange = (e) => {
    if (
      dataState?.otherParams &&
      Object.keys(dataState?.otherParams)?.length > 0
    ) {
      setDataState({
        ...e.dataState,
        otherParams: {},
      });
    } else {
      setDataState(e.dataState);
    }
  };

  const dataReceived = (dataObj) => {
    const arr = dataObj.data.data.map((driver) => {
      const updatedProfileDetails = getLabelFromEnum(
        ["bgvStatus", "testDriveStatus", "onboardingStage", "driverPayoutType"],
        driver,
        "driver"
      );
      let driverObj = {};

      if (driver?.fullName) {
        driverObj = {
          ...driverObj,
          fullName: stringToSentenceCase(driver?.fullName),
        };
      } else {
        driverObj = { ...driverObj, fullName: "" };
      }
      return {
        ...updatedProfileDetails,
        ...driverObj,
        lastLocation: driver.lastKnownLat + "," + driver.lastKnownLng,
      };
    });
    setDriverProfiles({ ...dataObj, data: arr });
  };

  return (
    <ModalLayout title={"Broadcast"} onClose={closeModal}>
      <div className="tw-w-[600px]">
        <Grid
          filter={initialFilter}
          filterable={true}
          sortable={false}
          pageable={true}
          {...dataState}
          data={driverProfiles}
          onDataStateChange={dataStateChange}
          //   onRowClick={handleRowClick}
        >
          {columns.map((column) => (
            <Column
              key={column?.id}
              field={column?.field}
              title={column?.title}
              width={column?.width}
              filterable={column?.filterable}
              cell={column?.cell}
              //   filterCell={
              //     column.defaultFilterOperator === "eq" &&
              //     ((props) => CategoryFilterCell(props, column, 250))
              //   }
            />
          ))}
        </Grid>
        <GridLoader
          //   loadingDisabled
          baseUrl={baseUrl}
          columns={columns}
          dataState={dataState}
          onDataReceived={dataReceived}
        />
        {/* <Button
          onClick={() =>
            handleRescheduleTrip({ selectedTimeSlot, selectedDate })
          }
          disabled={!reasons.reason || !reasons.subReason || !selectedTimeSlot}
          className="tw-mt-6"
          themeColor={"primary"}
          type={"button"}
        >
          Reschedule Trip
        </Button> */}
      </div>
    </ModalLayout>
  );
}
