import React from "react";
import { useSelector } from "react-redux";
import ModalLayout from "../../common/ModalLayout";
import { FormDropDownList } from "../../../utils/forms/FormComponents";
import { CancellationConfig } from "../../../config/cancellationConfig";
import { Button } from "@progress/kendo-react-buttons";

export default function CancelTrip({
  closeModal,
  reasons,
  setReasons,
  handleCancelTrip,
}) {
  const user = useSelector((state) => state.auth?.userDetails);
  return (
    <ModalLayout title={"Cancel Trip"} onClose={closeModal}>
      <div className="tw-w-[400px]">
        <span className="tw-mb-1">Select Reason</span>
        <FormDropDownList
          data={CancellationConfig.reason}
          textField="label"
          dataItemKey="value"
          value={reasons.reason}
          onChange={(e) =>
            setReasons({
              reason: e.target.value,
              subReason: "",
            })
          }
          style={{ width: "400px" }}
        />
        <span className="tw-mb-1 tw-mt-3 tw-block">Select Sub Reason</span>
        <FormDropDownList
          data={CancellationConfig.subReason.filter(
            (sub) => sub.reason === reasons.reason.value
          )}
          textField="label"
          dataItemKey="value"
          value={reasons.subReason}
          onChange={(e) =>
            setReasons((prev) => ({
              ...prev,
              subReason: e.target.value,
            }))
          }
          style={{ width: "400px" }}
        />
        <Button
          onClick={() => handleCancelTrip(user.email)}
          disabled={!reasons.reason || !reasons.subReason}
          className="tw-mt-6"
          themeColor={"primary"}
          type={"button"}
        >
          Cancel Trip
        </Button>
      </div>
    </ModalLayout>
  );
}
