import React from "react";
import CardLayout from "../components/common/CardLayout";
import PricingConfigHome from "../components/pricing-management/PricingConfigHome";
import { useDispatch, useSelector } from "react-redux";
import { updatePricingRegionFilter } from "../slices/regionSlice";
import { DropdownSearchSingleSelect } from "../utils/forms/NewFormComponents";

const PricingManagement = () => {
  const regions = useSelector((state) => state.region?.regions);
  const selectedPricingRegionFilter = useSelector(
    (state) => state.region?.pricing
  );
  const dispatch = useDispatch();

  const onPricingRegionFilterChange = (e) => {
    dispatch(updatePricingRegionFilter(e));
  };
  const updatedRegions = regions.slice().map((item, index) => ({
    ...item,
    label: item.child
      ? `${item.regionName} (${item.parentRegionName})`
      : item.regionName,
  }));
  
  const PricingRegionFilterDiv = () => (
    <div className="tw-w-full">
      <DropdownSearchSingleSelect
        options={updatedRegions}
        textField="label"
        onChange={onPricingRegionFilterChange}
        value={selectedPricingRegionFilter}
        backupMessage="No Options Available"
        parentClass="tw-relative tw-w-64"
      />
    </div>
  );

  return (
    <CardLayout
      title="Pricing Logic Management"
      ComponentRender={PricingRegionFilterDiv}
    >
      <PricingConfigHome />
    </CardLayout>
  );
};

export default PricingManagement;
