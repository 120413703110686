import { createSlice } from "@reduxjs/toolkit";
import { saveToLocalStorage } from "../utils/helpers";

const componentStatesSlice = createSlice({
  name: "componentStates",
  initialState: {
    calendarToggle: true,
  },
  reducers: {
    updateCalendarToggle: (state, action) => {
      state.calendarToggle = action.payload;
      saveToLocalStorage("componentStates", {
        ...state,
        calendarToggle: action.payload,
      });
    },
  },
});

export const { updateCalendarToggle } = componentStatesSlice.actions;
export default componentStatesSlice.reducer;
