import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import React, { useState } from "react";
import { process } from "@progress/kendo-data-query";
import { APIConfig } from "../../../../../utils/constants/api.constants";
import GridLoader from "../../../../common/GridLoader";
import { Button } from "@progress/kendo-react-buttons";
import RefundLogs from "./RefundLogs";
import AddOrUpdateRefund from "./AddOrUpdateRefund";

const PaymentLogs = ({ tripId, stage }) => {
  const baseUrl =
    process.env.REACT_APP_TRIP_BASE_URL +
    APIConfig.tripManagement.getPaymentLogs(tripId);
  const [paymentLogs, setPaymentLogs] = useState({
    data: [],
    total: 0,
  });
  const [dataState, setDataState] = useState({
    take: 10,
    skip: 0,
  });
  const [modalOpen, setModalOpen] = useState(false);
  // const [refreshTable, setRefreshTable] = useState(false);
  const [selectedRefundDetails, setSelectedRefundDetails] = useState();
  const [refundStatusUpdate, setRefundStatusUpdate] = useState(false);

  const [refundDataState, setRefundDataState] = useState({
    take: 10,
    skip: 0,
  });
  const [refresh, setRefresh] = useState(false);

  const columns = [
    {
      id: "orderId",
      field: "orderId",
      title: "Order Id",
    },
    {
      id: "paymentMode",
      field: "paymentMode",
      title: "Payment Mode",
    },
    {
      id: "createdAt",
      field: "createdAt",
      title: "Created At",
    },
    {
      id: "updatedAt",
      field: "updatedAt",
      title: "Updated At",
    },
    {
      id: "paymentStatus",
      field: "paymentStatus",
      title: "Payment Status",
    },
  ];

  const dataStateChange = (e) => {
    setDataState(e.dataState);
  };
  const dataReceived = (dataObj) => {
    setPaymentLogs(dataObj.data.paymentDetails);
  };

  const openRefundModal = () => {
    setModalOpen(true);
  };

  const closeRefundModal = () => {
    setModalOpen(false);
    setSelectedRefundDetails({});
    setRefundStatusUpdate(false);
    // setRefreshTable(!refreshTable);
    setRefundDataState({ ...refundDataState });
    setRefresh((prevState) => (!prevState ? true : true));
  };

  const onUpdateRefund = (e, props) => {
    setRefundStatusUpdate(true);
    setSelectedRefundDetails(props?.dataItem);
    // setSelectedRefundDetails()
    openRefundModal();
  };

  return (
    <div className="payment-logs-table">
      <h6>Payment Logs</h6>
      <Grid
        filterable={false}
        sortable={false}
        pageable={false}
        {...dataState}
        data={paymentLogs}
        onDataStateChange={dataStateChange}
      >
        {columns.map((column) => (
          <Column
            key={column?.id}
            field={column?.field}
            title={column?.title}
          />
        ))}
      </Grid>

      <GridLoader
        baseUrl={baseUrl}
        columns={columns}
        dataState={dataState}
        onDataReceived={dataReceived}
      />
      {stage === "TRIP_COMPLETED" && (
        <div className="refund-section my-4">
          <Button
            themeColor={"primary"}
            type={"submit"}
            onClick={openRefundModal}
          >
            + Add Refund
          </Button>
          <br />
          <div className="refund-logs my-3">
            <h6>Refund Logs</h6>
            <RefundLogs
              tripId={tripId}
              refresh={refresh}
              dataState={refundDataState}
              setDataState={setRefundDataState}
              closeRefundModal={closeRefundModal}
              openRefundModal={openRefundModal}
              onUpdateRefund={onUpdateRefund}
            />
          </div>
        </div>
      )}
      {modalOpen && (
        <AddOrUpdateRefund
          selectedRefundDetails={selectedRefundDetails}
          tripId={tripId}
          refundStatusUpdate={refundStatusUpdate}
          onClose={closeRefundModal}
        />
      )}
    </div>
  );
};

export default PaymentLogs;
