import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import React, { useEffect, useState } from 'react'
import { process } from '@progress/kendo-data-query';
import { APIConfig } from '../../../../../utils/constants/api.constants';
import GridLoader from '../../../../common/GridLoader';
import { getLabelFromEnum, replaceNullValues, stringToSentenceCase } from '../../../../../utils/helpers';

const CandidateDrivers = ({tripId,lat,lng,tripBookingMode,stage}) => {
  const latLngObj = {
    'lat':lat,
    'lng':lng
  };
  const requiredStageValues = ['SEARCHING_FOR_DRIVER','DRIVER_ALLOCATED','DRIVER_NOT_FOUND','DRIVER_ON_THE_WAY','DRIVER_ARRIVED'];
  const baseUrl = process.env.REACT_APP_DRIVER_BASE_URL + APIConfig.tripManagement.getCandidateDrivers;
  const [showTable,setShowTable] = useState(false);

  useEffect(()=>{
    if(tripBookingMode === 'SCHEDULED' && requiredStageValues.includes(stage)){
      setShowTable(true);
    }
  },[])

  const [candidateDrivers, setCandidateDrivers] = useState({
    data: [],
    total: 0
  });
  const [dataState, setDataState] = useState({
    take: 10,
    skip: 0
  });

  const columns = [
    {
      id: "driverId",
      field: "driverId",
      title: "Driver Id",
    },
    {
      id: "driverName",
      field: "driverName",
      title: "Driver Name",
    },
    {
      id: "displacement",
      field: "displacement",
      title: "Displacement from origin",
    },
    {
      id: "location",
      field: "location",
      title: "Last Location",
    },
    {
      id: "driverStatus",
      field: "driverStatus",
      title: "Driver Status",
    },
    {
      id: "scheduledTrip",
      field: "scheduledTrip",
      title: "Next Scheduled Trip ",
    },
  ];

  const dataStateChange = e => {
    setDataState(e.dataState);
  };
  const dataReceived = dataObj => {
    const arr = dataObj.data.candidateDrivers.map(driver => {
      const updatedDriverDetails = getLabelFromEnum(['driverStatus'],driver,'driver');
      let driverObj = {};
      if (driver?.driverName) {
        driverObj = { ...driverObj, driverName: stringToSentenceCase(driver?.driverName) }
      } else {
        driverObj = { ...driverObj, driverName: '' }
      }
      return { ...updatedDriverDetails, ...driverObj, location: driver.driverLat + ', ' + driver.driverLng, scheduledTrip: driver?.scheduledTripDetails?.tripStartTime ? driver?.scheduledTripDetails?.tripStartTime : '' };
    });
    setCandidateDrivers({ ...dataObj, data: arr });
  };
  return (
    <div className='candidate-drivers-table'>
      {
        showTable ? (
          <>
          <Grid filterable={false} sortable={false} pageable={false} {...dataState}
        data={candidateDrivers} 
        onDataStateChange={dataStateChange}>
        {
          columns.map(column => (
            <Column key={column?.id} field={column?.field} title={column?.title} />
          ))
        }
      </Grid>
      <GridLoader baseUrl={baseUrl} columns={columns} dataState={dataState} onDataReceived={dataReceived} method='put' body={JSON.stringify({...latLngObj})} />
          </>
        ) : (
          <div>
            Not applicable as trip booking mode is: <b>{replaceNullValues(getLabelFromEnum(['tripBookingMode'],{tripBookingMode:tripBookingMode},'trip').tripBookingMode)}</b> and trip stage is: <b>{replaceNullValues(getLabelFromEnum(['stage'],{stage:stage},'trip').stage)}</b>
          </div>
        )
      }
    </div>
  )
}

export default CandidateDrivers;