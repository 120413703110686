import React, {
  useCallback,
  useMemo,
  useRef,
  useState,
  useEffect,
} from "react";
import { debounce } from "lodash";
import GoogleMapsWrapper from "./GoogleMapsWrapper";
import CustomMarkerFleetView from "./Marker";
import { mapDefaults } from "./defaults";
import {
  calculateTimeAgo,
  driverStatusTags,
  driverStatusTagsMap,
} from "../../utils/helpers";
import { InfoWindow } from "@react-google-maps/api";
import { Link } from "react-router-dom";

export default function FleetViewMap({
  data = [],
  onBoundsChange,
  region,
  setSelectedDriverTabel,
  selectedDriverTable,
}) {
  const containerStyle = { width: "100%", height: "100%" };
  const mapRef = useRef(null);
  const [selectedDriver, setSelectedDriver] = useState(null);
  const [initialLoad, setInitialLoad] = useState(true);

  useEffect(() => {
    if (!selectedDriverTable?.driverId) return;
    setSelectedDriver(selectedDriverTable);
  }, [selectedDriverTable]);

  useEffect(() => {
    handleInfoWindowClose();
  }, [data]);

  const center = useMemo(() => {
    const newCenter = {
      lat: region?.centre?.latitude,
      lng: region?.centre?.longitude,
    };
    if (!newCenter.lat) return mapDefaults.fleetViewMap.center;
    return newCenter;
  }, [region]);

  const options = useMemo(() => mapDefaults.fleetViewMap.options, []);

  const onLoad = useCallback(
    (map) => {
      mapRef.current = map;
      if (data.length > 0) {
        fitBoundsToMarkers(map);
      }
    },
    [data]
  );

  const handleBoundsChanged = useCallback(() => {
    if (mapRef.current && !initialLoad) {
      const bounds = mapRef.current.getBounds();
      if (bounds && onBoundsChange) {
        onBoundsChange(bounds);
      }
    }
  }, [onBoundsChange, initialLoad]);

  const onBoundsChanged = useMemo(
    () => debounce(handleBoundsChanged, 300),
    [handleBoundsChanged]
  );

  const fitBoundsToMarkers = useCallback(
    (map) => {
      const bounds = new window.google.maps.LatLngBounds();
      data.forEach((item) => {
        if (item.lastKnownLat && item.lastKnownLng) {
          bounds.extend(
            new window.google.maps.LatLng(item.lastKnownLat, item.lastKnownLng)
          );
        }
      });

      if (!bounds.isEmpty()) {
        map.fitBounds(bounds);
        const maxZoomLevel = 15;
        const listener = window.google.maps.event.addListener(
          map,
          "idle",
          () => {
            if (map.getZoom() > maxZoomLevel) {
              map.setZoom(maxZoomLevel);
            }
            window.google.maps.event.removeListener(listener);
          }
        );
      }
    },
    [data]
  );

  useEffect(() => {
    if (initialLoad && data.length > 0 && mapRef.current) {
      setInitialLoad(false);
    }
  }, [data, fitBoundsToMarkers, initialLoad]);

  const handleMarkerClick = (driver) => {
    setSelectedDriver(driver);
    // setClickedMarkerId(driver.driverId);
  };

  const handleInfoWindowClose = () => {
    setSelectedDriver(null);
    setSelectedDriverTabel(null);
    // setClickedMarkerId(null);
  };

  return (
    <GoogleMapsWrapper
      containerStyle={containerStyle}
      options={options}
      center={center}
      zoom={mapDefaults.fleetViewMap.zoom}
      onLoad={onLoad}
      mapRef={mapRef.current}
      onDragEnd={onBoundsChanged}
      onZoomChanged={onBoundsChanged}
    >
      {data.map((item) => {
        if (!item.lastKnownLat) return null;
        return (
          <CustomMarkerFleetView
            key={item.driverId}
            position={{ lat: item.lastKnownLat, lng: item.lastKnownLng }}
            onClick={() => handleMarkerClick(item)}
            isClicked={selectedDriver?.driverId === item.driverId}
          >
            {driverStatusTagsMap(item.driverStatus)}
          </CustomMarkerFleetView>
        );
      })}
      {selectedDriver && (
        <InfoWindow
          position={{
            lat: selectedDriver.lastKnownLat,
            lng: selectedDriver.lastKnownLng,
          }}
          onCloseClick={handleInfoWindowClose}
        >
          <div className="tw-max-w-60 tw-overflow-hidden">
            {driverStatusTags(selectedDriver.driverStatus)}
            <Link
              to={`/home/driver/${selectedDriver?.driverId}/online-activity?driverName=${selectedDriver?.fullName}`}
              className="tw-font-medium tw-mt-2 tw-block tw-text-black hover:tw-text-black tw-max-w-fit tw-text-base tw-overflow-hidden tw-text-ellipsis tw-whitespace-nowrap"
            >
              {selectedDriver.fullName}
            </Link>
            <p>
              Last Seen {calculateTimeAgo(selectedDriver.lastKnownLocationAt)}
            </p>
          </div>
        </InfoWindow>
      )}
    </GoogleMapsWrapper>
  );
}
