import React, { useCallback, useState, useRef, useEffect } from "react";
import ModalLayout from "../common/ModalLayout";
import { Input } from "@progress/kendo-react-inputs";
import AddressMap from "./AddressMap";
import closeBtn from "../../assets/closeButton.svg";
import DestinationAutoComplete from "../maps/DestinationAutoComplete";
import { Button } from "@progress/kendo-react-buttons";
import axiosService from "../../init/axios";
import { showErrorMessage, showToastMessage } from "../../utils/helpers";
import { APIConfig } from "../../utils/constants/api.constants";
import { useSelector } from "react-redux";

function parseAddress(originalObj) {
  if (!originalObj) return;
  return {
    city: originalObj.city,
    coordinates: {
      lat: originalObj.lat,
      lng: originalObj.lng,
    },
    description: originalObj.formattedAddress,
    matched_substrings: [],
    pincode: originalObj.pincode || "",
    place_id: originalObj.placeId,
    reference: originalObj.placeId,
    state: originalObj.state,
  };
}

export default function SavedAddressesModal({ onClose, modalState }) {
  const clientId = useSelector((state) => state.auth.clientId);
  const updateUrl =
    process.env.REACT_APP_USER_BASE_URL +
    APIConfig.clients.getClientUpdateAddress(
      clientId,
      modalState?.data?.addressId
    );
  const createUrl =
    process.env.REACT_APP_USER_BASE_URL +
    APIConfig.clients.getClientsCreateAddress(clientId);

  const [branchName, setBranchName] = useState(
    modalState?.data?.branchName || ""
  );
  const [address, setAddress] = useState({
    pickup: parseAddress(modalState?.data),
  });
  const [loading, setLoading] = useState(false);

  const preparePayload = (placeData) => {
    return {
      placeId: placeData.place_id || modalState?.data?.placeId,
      locality: "",
      formattedAddress: placeData.description,
      pincode: placeData.pincode,
      city: placeData.city,
      state: placeData.state,
      lat: placeData.coordinates.lat,
      lng: placeData.coordinates.lng,
      label: "",
      regionId: placeData?.regionId || "",
      branchName: branchName.trim() || "",
    };
  };

  const setAddressCallback = useCallback((newAddress) => {
    setAddress(newAddress);
  }, []);

  const updateAddress = async () => {
    setLoading(true);
    const payload = preparePayload(address.pickup);
    await axiosService
      .put(updateUrl, payload)
      .then((data) => {
        showToastMessage("Address successfully updated");
        onClose({ updated: true });
        setLoading(false);
      })
      .catch((e) => showErrorMessage(e));
  };

  const deleteAddress = async () => {
    setLoading(true);
    await axiosService
      .delete(updateUrl)
      .then((data) => {
        showToastMessage("Address successfully deleted");
        onClose({ updated: true });
        setLoading(false);
      })
      .catch((e) => showErrorMessage(e));
  };

  const createAddress = async () => {
    setLoading(true);
    const data = preparePayload(address.pickup);
    await axiosService
      .post(createUrl, data)
      .then((data) => {
        showToastMessage("Address successfully saved");
        onClose({ updated: true });
        setLoading(false);
      })
      .catch((e) => showErrorMessage(e));
  };

  return (
    <ModalLayout title={modalState?.title} onClose={onClose}>
      {modalState?.state !== "DELETE_ADDRESS" ? (
        <div className="tw-w-[800px] tw-min-h-[500px] tw-gap-5 tw-grid tw-grid-cols-5">
          <div className="tw-col-span-2 tw-flex tw-flex-col tw-justify-between">
            <div>
              {branchName || modalState?.edit ? (
                <>
                  {/* Branch name input */}
                  <label className="tw-text-sm tw-block tw-text-[#848995]">
                    Branch name
                  </label>
                  <Input
                    value={branchName}
                    onChange={(e) => setBranchName(e.target.value)}
                    className="tw-block"
                    placeholder="Enter branch name"
                    disabled={!modalState.edit}
                  />
                </>
              ) : null}
              <br />
              <div>
                <label className="tw-text-sm tw-block tw-text-[#848995]">
                  Address
                </label>
                {address?.pickup?.description ? (
                  <div className="tw-flex tw-items-center tw-justify-between custom-border-primary tw-rounded-md tw-p-3 tw-gap-2 tw-text-sm">
                    <span>{address?.pickup?.description}</span>
                    {modalState.edit && (
                      <button
                        disabled={!modalState.edit}
                        onClick={() =>
                          setAddressCallback({
                            ...address,
                            pickup: "",
                          })
                        }
                        className="tw-bg-transparent tw-border-none"
                      >
                        <img src={closeBtn} alt="Close" className="tw-w-6" />
                      </button>
                    )}
                  </div>
                ) : (
                  <DestinationAutoComplete
                    disabled={false}
                    pickup
                    placeholder="Enter new address"
                    address={address}
                    setAddress={setAddressCallback}
                  />
                )}
              </div>
            </div>
            {modalState.edit && (
              <div className="tw-flex tw-items-center tw-gap-5">
                <Button
                  className="tw-w-full tw-py-2"
                  themeColor={"none"}
                  onClick={onClose}
                >
                  Cancel
                </Button>
                <Button
                  disabled={loading || !address.pickup} // Check if current exists
                  className="tw-w-full tw-py-2"
                  themeColor={"primary"}
                  onClick={
                    modalState?.state === "EDIT_ADDRESS"
                      ? updateAddress
                      : createAddress
                  }
                >
                  {modalState?.state === "EDIT_ADDRESS" ? "Update" : "Save"}
                </Button>
              </div>
            )}
          </div>

          <div className="tw-col-span-3 tw-relative">
            {/* Conditionally render the map component if necessary */}
            <AddressMap
              editable={modalState.edit}
              initialPickup={address.pickup}
              setAddress={setAddressCallback}
            />
          </div>
        </div>
      ) : (
        <>
          <div className="tw-space-y-5 tw-min-w-80">
            <h5 className="tw-text-base tw-text-center">Are you sure?</h5>
            <div className="tw-flex tw-gap-5">
              <Button className="tw-w-full" themeColor="none" onClick={onClose}>
                No
              </Button>
              <Button
                disabled={loading}
                className="tw-w-full"
                themeColor="primary"
                onClick={deleteAddress}
              >
                Yes
              </Button>
            </div>
          </div>
        </>
      )}
    </ModalLayout>
  );
}
