export function formatAddress(address) {
    if (!address) return;
    return {
      description: address.formattedAddress,
      place_id: address.placeId,
      reference: address.placeId, // Same value as place_id
      structured_formatting: {
        main_text: address.formattedAddress.split(",")[0], // Extracting the first part as main_text
        secondary_text: address.formattedAddress
          .split(",")
          .slice(1)
          .join(", ")
          .trim(), // Rest of the address as secondary_text
      },
      terms: address.formattedAddress.split(",").map((term, index) => ({
        offset: address.formattedAddress.indexOf(term),
        value: term.trim(),
      })),
      types: ["establishment", "point_of_interest"], // Default values, change if necessary
      coordinates: {
        lat: address.lat,
        lng: address.lng,
      },
      pincode: address.pincode,
      city: address.city,
      state: address.state,
    };
  }