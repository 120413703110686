import React, { useCallback, useEffect, useState } from "react";
import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout";
import { Link, useNavigate, useParams } from "react-router-dom";
import CandidateDrivers from "./trip-details-panel/candidate-drivers/CandidateDrivers";
import PaymentLogs from "./trip-details-panel/payment-logs/PaymentLogs";
import InsuranceDetails from "./trip-details-panel/insurance-details/InsuranceDetails";
import TripStatusLogs from "./trip-details-panel/trip-status-logs/TripStatusLogs";
import AllocationLogs from "./trip-details-panel/allocation-logs/AllocationLogs";
import Pricing from "./trip-details-panel/pricing/Pricing";
import axiosService from "../../../init/axios";
import { APIConfig } from "../../../utils/constants/api.constants";
import {
  conditionalRendering,
  convertEnumToLabel,
  showErrorMessage,
  showToastMessage,
  timeConverter,
} from "../../../utils/helpers";
import LoadingPanel from "../../common/LoadingPanel";
import Waivers from "./trip-details-panel/waivers/Waivers";
import ReserveDrivers from "./trip-details-panel/reserve-drivers/ReserveDrivers";
import TripInvoices from "./trip-details-panel/trip-invoices/TripInvoices";
import RevenueStream from "./trip-details-panel/revenue-stream/RevenueStream";
import B2BTripManagementPage from "../../../pages/trip-management/B2BTripManagementPage";
import B2CTripManagementPage from "../../../pages/trip-management/B2CTripManagementPage";
import CardLayout from "../../common/CardLayout";
import { Button } from "@progress/kendo-react-buttons";
import RescheduleTrip from "./RescheduleTrip";
import CancelTrip from "./CancelTrip";
import {
  BROADCASTING_DRIVER_BUTTON,
  CANCEL_TRIP_BUTTON,
  RESCHEDULE_TRIP_BUTTON,
} from "../../../config/statusPermissions";
import { useSelector } from "react-redux";
import { ALLOWED_STAGES, TRIP_TYPES } from "../shared/trip-enums";
import TagsList from "../Tagslist";
import BackArrowIcon from "../../../assets/icons/BackArrowIcon";
import BroadcastingModal from "./BroadcastingModal";
import ExotelLog from "./trip-details-panel/exotel/ExotelLog";
import CallHistoryAudioModal from "./trip-details-panel/exotel/CallHistoryAudioModal";

const initialReasons = { reason: "", subReason: "" };
const initialModal = {
  cancelledModal: false,
  rescheduleModal: false,
  broadcastingModal: false,
  callHistoryAudioModal: false,
};
const TripDetailPage = ({ setTripDetailsSelected }) => {
  const clientId = useSelector((state) => state.auth?.clientId);
  const { tripId } = useParams();
  const navigate = useNavigate();
  const [expanded, setExpanded] = useState([""]);
  const [tripDetails, setTripDetails] = useState();
  const [refreshTripDetails, setRefreshTripDetails] = useState(false);
  const [refreshTripCalled, setRefreshTripCalled] = useState(false);
  const [loading, setLoading] = useState("");
  const tripEditUrl =
    process.env.REACT_APP_TRIP_BASE_URL +
    APIConfig.tripManagement.rescheduleTrip(tripId);
  const [modalOpen, setModalOpen] = useState(initialModal);
  const [reasons, setReasons] = useState(initialReasons);
  const [selectedRow, setSelectedRow] = useState(null);
  const allowedClientTypes = [TRIP_TYPES.B2B, TRIP_TYPES.C2B];
  const allowedStages = [ALLOWED_STAGES.SEARCHING_FOR_DRIVER];

  const isAllowed =
    allowedClientTypes.includes(tripDetails?.clientType) &&
    allowedStages.includes(tripDetails?.stage);

    const handleModalOpener = ( key ) => {
      setModalOpen({
        ...initialModal,
        [key]: true,
      });
    };
  const panelJson = [
    {
      title: "Revenue Stream",
      props: { tripId },
      component: <RevenueStream />,
    },
    {
      title: "Trip Invoices",
      props: { tripId, stage: tripDetails?.stage },
      component: <TripInvoices />,
    },
    {
      title: "Reserve Drivers",
      props: {
        tripId,
        lat: tripDetails?.expectedSourceLocation?.lat,
        lng: tripDetails?.expectedSourceLocation?.lng,
        tripBookingMode: tripDetails?.tripBookingMode,
        stage: tripDetails?.stage,
        refreshTripDetails,
        setRefreshTripDetails,
        refreshTripCalled,
        setRefreshTripCalled,
      },
      component: <ReserveDrivers />,
    },
    {
      title: "Payment Logs",
      props: { tripId, stage: tripDetails?.stage },
      component: <PaymentLogs />,
    },
    {
      title: "Insurance Details",
      props: { tripId, insuranceOpted: tripDetails?.insuranceOpted },
      component: <InsuranceDetails />,
    },
    {
      title: "Call History",
      props: { tripId},
      component: (
        <ExotelLog
          handleModalOpener={handleModalOpener}
          setSelectedRow={setSelectedRow}
          tripId={tripId}
        />
      ),
    },
    {
      title: "Trip Status Logs",
      props: { tripId },
      component: <TripStatusLogs />,
    },
    {
      title: "Allocation Logs",
      props: { tripId },
      component: <AllocationLogs />,
    },
    {
      title: "Waivers",
      props: { tripId },
      component: <Waivers />,
    },
  ];

  useEffect(() => {
    //API call to fetch trip details
    getTripDetails();
  }, [tripId]);

  useEffect(() => {
    if (refreshTripCalled) {
      getTripDetails();
    }
  }, [refreshTripDetails]);

  const getTripDetails = async () => {
    setLoading(true);
    await axiosService
      .get(
        process.env.REACT_APP_TRIP_BASE_URL_NEW +
          APIConfig.tripManagement.getTripDetails(tripId)
      )
      .then((data) => {
        setLoading(false);
        setTripDetails(data.data);
      })
      .catch((error) => {
        setLoading(false);
        showErrorMessage(error);
      });
  };

  const handleSelect = (event) => {
    if (event.expandedItems) {
      setExpanded(event.expandedItems);
    }
  };

  const closeModal = () => {
    setModalOpen(initialModal);
    setReasons(initialReasons);
  };

  const onGoBack = () => {
    setTripDetailsSelected(false);
    navigate("/home/trip");
  };

  const isAllowedB2B =
    allowedClientTypes.includes(tripDetails?.clientType) &&
    allowedStages.includes(tripDetails?.stage);

  const isAllowedB2C =
    ["B2C"].includes(tripDetails?.clientType) &&
    allowedStages.includes(tripDetails?.stage);

  const handleRescheduleTrip = (slot) => {
    let payload = {
      action: "RESCHEDULE",
      actionDetails: {
        actionBy: "",
        reason: reasons.reason?.value,
        subReason: reasons.subReason?.value || "",
        pickupSlotStartTime: `${slot.selectedDate} ${slot.selectedTimeSlot.value}`,
      },
    };
    axiosService
      .put(tripEditUrl, payload)
      .then((e) => {
        setModalOpen(initialModal);
        navigate(`/home/trip/${e.data.tripId}/details`);
        showToastMessage("Trip Rescheduled Successfully");
      })
      .catch((err) => showErrorMessage(err));
  };

  const handleBroadcast = () => {};

  const handleCallHistoryModal = (row) => {
    setSelectedRow(row ? row : null);
  };

  const handleCancelTrip = useCallback((email) => {
    const payload = {
      action: "CANCEL",
      actionDetails: {
        actionBy: email,
        reason: reasons.reason?.value,
        subReason: reasons.subReason?.value || "",
      },
    };
    axiosService
      .put(tripEditUrl, payload)
      .then(() => {
        setModalOpen(initialModal);
        navigate(`/home/trips`);
        showToastMessage("Trip Cancelled Successfully");
      })
      .catch((err) => console.log(err));
  }, []);

  const renderModal = (
    <>
      {modalOpen.rescheduleModal && (
        <RescheduleTrip
          closeModal={closeModal}
          reasons={reasons}
          setReasons={setReasons}
          handleRescheduleTrip={handleRescheduleTrip}
        />
      )}
      {modalOpen.cancelledModal && (
        <CancelTrip
          closeModal={closeModal}
          reasons={reasons}
          setReasons={setReasons}
          handleCancelTrip={handleCancelTrip}
        />
      )}
      {modalOpen.broadcastingModal && (
        <BroadcastingModal
          tripId={tripId}
          closeModal={closeModal}
          handleBroadcast={handleBroadcast}
        />
      )}
      {modalOpen.callHistoryAudioModal && (
        <CallHistoryAudioModal
          closeModal={closeModal}
          selectedRow={selectedRow}
          handleCallHistoryModal={handleCallHistoryModal}
        />
      )}
    </>
  );

  const renderB2CTripDetails = (
    <PanelBarItem key="pricing" title="Pricing">
      <div className="custom-template">
        <Pricing
          tripId={tripId}
          journeyMode={tripDetails?.journeyMode}
          actualJourneyMode={tripDetails?.actualJourneyMode}
          tripType={tripDetails?.derivedTripType}
          selectedDuration={tripDetails?.selectedDuration}
          selectedDurationUnit={tripDetails?.selectedDurationUnit}
          actualTripStartTime={tripDetails?.actualTripStartTime}
          actualTripEndTime={tripDetails?.actualTripEndTime}
          couponCode={tripDetails?.couponCode}
        />
      </div>
    </PanelBarItem>
  );

  const renderB2BTripDetails = (
    <PanelBarItem title="Pricing">
      <div className="custom-template">
        <div className="custom-border-primary tw-p-4 tw-rounded-md">
          <div className="tw-mt-4 tw-flex-col tw-gap-8">
            <div>
              <p className="tw-text-[#919191] tw-mb-0 tw-text-sm">
                Actual Trip Distance (km)
              </p>
              <p>{tripDetails?.tripPathDetails?.actualDistance || "N.A."}</p>
            </div>
            <div>
              <p className="tw-text-[#919191] tw-mb-0 tw-text-sm">
                Trip Base Rate
              </p>
              <p>{tripDetails?.b2bTripFareDetails?.baseRate || "N.A."}</p>
            </div>
            <div>
              <p className="tw-text-[#919191] tw-mb-0 tw-text-sm">
                Night Charges
              </p>
              <p>{tripDetails?.b2bTripFareDetails?.nightCharges || "N.A."}</p>
            </div>
            <div>
              <p className="tw-text-[#919191] tw-mb-0 tw-text-sm">Total Fare</p>
              <p>{tripDetails?.b2bTripFareDetails?.totalFare || "N.A."}</p>
            </div>
            {/* <div>
          <p className="tw-text-[#919191] tw-mb-0 tw-text-sm">
            Config Version
          </p>
          <p>
            {tripDetails?.b2bTripFareDetails?.configVersion ||
              "N.A."}
          </p>
        </div> */}
          </div>
        </div>
      </div>
    </PanelBarItem>
  );

  return (
    <CardLayout
      title="Trip Management"
      tripDetails={tripDetails}
      ComponentRender={TagsList}
    >
      <div className="trip-details tw-relative tw-min-h-[80dvh]">
        <span className="tw-cursor-pointer" onClick={onGoBack}>
          <BackArrowIcon />
        </span>
        {loading ? (
          <LoadingPanel />
        ) : (
          <>
            <div className="trip-details-header mx-3 my-3">
              <div className="tw-flex tw-items-start tw-justify-between">
                <div className="tw-flex tw-items-start tw-gap-5">
                  <div>
                    <h4 className="tw-text-2xl tw-font-semibold">
                      Trip Id : {tripId}
                    </h4>
                    <p className="tw-text-sm">
                      Booking Created At :{" "}
                      {timeConverter(tripDetails?.tripCreationTime)}
                    </p>
                  </div>
                  <div className="tw-space-x-2">
                    {tripDetails?.tag === "CRITICAL_ZONE" && (
                      <div className="status-pill CANCELLED">
                        <span>Critical</span>
                      </div>
                    )}
                    {tripDetails?.stage && (
                      <div className="status-pill trip_acceptance_timer_running">
                        <span>{convertEnumToLabel(tripDetails?.stage)}</span>
                      </div>
                    )}
                  </div>
                </div>

                <div className="tw-flex tw-items-center tw-gap-5">
                  {isAllowedB2B && (
                    <>
                      {conditionalRendering(
                        CANCEL_TRIP_BUTTON.tripStages,
                        tripDetails?.stage,
                        <Button
                          rounded={"medium"}
                          themeColor={"error"}
                          onClick={() =>
                            handleModalOpener( "cancelledModal")
                          }
                        >
                          Cancel
                        </Button>
                      )}
                      {conditionalRendering(
                        RESCHEDULE_TRIP_BUTTON.tripStages,
                        tripDetails?.stage,
                        <Button
                          rounded={"medium"}
                          themeColor={"dark"}
                          onClick={() =>
                            handleModalOpener( "rescheduleModal")
                          }
                        >
                          Reschedule
                        </Button>
                      )}
                      <Link
                        className="hover:tw-text-white tw-px-3 tw-py-1.5 tw-rounded-md tw-text-white tw-bg-primary tw-text-sm"
                        to={`/home/trip/${tripId}/assign-driver`}
                      >
                        Assign Driver
                      </Link>
                    </>
                  )}
                  {isAllowedB2C && (
                    <>
                      {conditionalRendering(
                        BROADCASTING_DRIVER_BUTTON.tripStages,
                        tripDetails?.stage,
                        <>
                          <Button
                            rounded={"medium"}
                            themeColor={"dark"}
                            onClick={() =>
                              handleModalOpener(
                                "broadcastingModal"
                              )
                            }
                          >
                            Broadcast
                          </Button>
                          <Link
                            className="hover:tw-text-white tw-px-3 tw-py-1.5 tw-rounded-md tw-text-white tw-bg-primary tw-text-sm"
                            to={`/home/trip/${tripId}/assign-driver`}
                          >
                            Assign Driver
                          </Link>
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="trip-details-panel mx-3 my-4">
              {tripDetails?.clientType === TRIP_TYPES.B2B ? (
                <B2BTripManagementPage
                  tripDetails={tripDetails}
                  tripId={tripId}
                />
              ) : (
                <B2CTripManagementPage
                  tripDetails={tripDetails}
                  tripId={tripId}
                />
              )}

              {clientId === false && (
                <PanelBar
                  expanded={expanded}
                  onSelect={handleSelect}
                  expandMode={"single"}
                >
                  {/* <PanelBarItem title='Customer Details'>
                                <div className="plate">
                                    <p>Customer Name : <b>{replaceNullValues(tripDetails?.customerDetails?.fullName)}</b></p>
                                    <p>Phone Number : <b>{replaceNullValues(tripDetails?.customerDetails?.mobileNumber)}</b></p>
                                </div>
                            </PanelBarItem>
                            <PanelBarItem title='Trip Details'>
                                <div className="custom-template">
                                    <TripDetails tripDetails={tripDetails} />
                                </div>
                            </PanelBarItem> */}
                  <PanelBarItem
                    key="candidate-drivers"
                    title="Candidate Drivers"
                  >
                    <div className="custom-template">
                      <CandidateDrivers
                        tripId={tripId}
                        lat={tripDetails?.expectedSourceLocation?.lat}
                        lng={tripDetails?.expectedSourceLocation?.lng}
                        tripBookingMode={tripDetails?.tripBookingMode}
                        stage={tripDetails?.stage}
                      />
                    </div>
                  </PanelBarItem>
                  {!tripDetails?.b2bTripFareDetails
                    ? renderB2CTripDetails
                    : renderB2BTripDetails}
                  {panelJson.map(({ title, component, props }) => (
                    <PanelBarItem title={title} key={title}>
                      <div className="custom-template">
                        {React.cloneElement(component, props)}
                      </div>
                    </PanelBarItem>
                  ))}

                  {/* {
                                TripConfig.waiverStages.includes(tripDetails?.stage) && (
                                    <PanelBarItem title='Waivers'>
                                        <div className="custom-template">
                                            <Waivers tripId={tripId} />
                                        </div>
                                    </PanelBarItem>
                                )
                            } */}
                </PanelBar>
              )}
            </div>
          </>
        )}
      </div>

      {renderModal}
    </CardLayout>
  );
};

export default TripDetailPage;
