import React, { useState } from "react";
import CardLayout from "../components/common/CardLayout";
import DriverProfilesTable from "../components/driver-management/driver-profiles/DriverProfilesTable";

const AllDriverManagement = () => {
  const [dataState, setDataState] = useState({
    take: 10,
    skip: 0,
    otherParams: {},
  });

  return (
    <CardLayout
      title="Driver Management"
    >
      <DriverProfilesTable
        allData
        dataState={dataState}
        setDataState={setDataState}
      />
    </CardLayout>
  );
};

export default AllDriverManagement;
