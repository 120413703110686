import axiosService from "../../init/axios";
import { APIConfig } from "../../utils/constants/api.constants";
import { showErrorMessage } from "../../utils/helpers";

export const fetchGuestToken = async ({ payload }) => {
    //Fetch CSRF Token first
    return await axiosService.get(process.env.REACT_APP_APACHE_SUPERSET_BASE_URL + APIConfig.apacheSuperset.CSRF_TOKEN)
    .then(response => {
        try{
            //Pass the CSRF Token in headers
            return axiosService.post(process.env.REACT_APP_APACHE_SUPERSET_BASE_URL + APIConfig.apacheSuperset.GUEST_TOKEN,payload,{"X-CSRFToken": response?.data?.result})
        }catch(error){
            throw error;
        }
    }).catch(error =>{
        showErrorMessage(error);
    });
};