import { useEffect, useState } from "react";
import axiosService from "../../../init/axios";
import { APIConfig } from "../../../utils/constants/api.constants";

export function useFilterData({ regionId, showAllData }) {

    const [filterArr, setFilterArr] = useState([]);


    const getFilterData = async () => {
        let filterBaseUrl;
        if (regionId && !showAllData) {
            filterBaseUrl =
                process.env.REACT_APP_DRIVER_BASE_URL +
                APIConfig.driverManagement.getDriverStats +
                "?region_id=" + regionId +
                encodeURI(":in:string") +
                "&onboarding_stage=ONBOARDING_FORM_COMPLETE:eq:string&bgv_status=SUCCESS:eq:string&test_drive_status=SUCCESS:eq:string";
        } else {
            filterBaseUrl =
                process.env.REACT_APP_DRIVER_BASE_URL +
                APIConfig.driverManagement.getDriverStats;
        }
        await axiosService
            .get(filterBaseUrl)
            .then((data) => {
                setFilterArr(data.data)
            })
            .catch((error) => {
                console.error(error);
            });
    };

    useEffect(() => {
        getFilterData();
    }, [regionId])

    return [filterArr, getFilterData];

}