import { Button } from "@progress/kendo-react-buttons";
import { FormInput } from "../../../utils/forms/FormComponents";
import RadioTabs from "../RadioTabs";
import { CAR_TYPES, FUEL_TYPES, TRANSMISSION_TYPES } from "./trip-enums";

export function CarDetailsForm({
    formDetails,
    setFormDetails,
    isSubmitting,
    handleAddTask,
    // handleGoBack,
  }) {
    const handleSaveDetails = (key, value) => {
      setFormDetails((prev) => ({
        ...prev,
        otherDetails: {
          ...prev.otherDetails,
          [key]: value,
        },
      }));
    };
    const radioJson = [
      {
        label:"Car Type",
        options:CAR_TYPES, 
        value:formDetails?.otherDetails?.carType,
        key:"carType"
      },
      {
        label:"Transmission Type", 
        options:TRANSMISSION_TYPES,
        value:formDetails?.otherDetails?.transmissionType,
        key:"transmissionType",
      },
      {
        label:" Fuel Type",
        options:FUEL_TYPES,
        value:formDetails?.otherDetails?.fuelType,
        key:"fuelType", 
      }
    ]
    return (
      <>
      {
        radioJson.map((item, index) => {
          return (
            <div className={index?"tw-mt-5":""}>
              <label className="tw-text-sm tw-block tw-leading-none tw-text-gray-700 tw-font-medium">
                {item.label}
              </label>
              <div>
                <RadioTabs
                  options={item.options}
                  value={item.value}
                  onValueChange={(value) => {
                    handleSaveDetails(item.key, value);
                  }}
                />
              </div>
            </div>
          );
        }
      )}
        <div className="tw-my-5">
          <label className="tw-text-sm tw-block tw-leading-none tw-text-gray-700 tw-font-medium">
            Registration number (optional)
          </label>
          <div>
            <FormInput
              value={formDetails?.otherDetails?.registrationNumber}
              placeholder="Registration number"
              onChange={(e) =>
                handleSaveDetails("registrationNumber", e.target.value)
              }
            />
          </div>
        </div>
  
        {/* <Button
          onClick={handleGoBack}
          themeColor={null}
          className="tw-inline-block tw-mr-5 tw-mt-4 tw-w-[100px]"
        >
          Back
        </Button> */}
        <Button
          onClick={handleAddTask}
          disabled={isSubmitting}
          themeColor="primary"
          className="tw-inline-block tw-mt-4 tw-w-[150px]"
        >
          Create Task
        </Button>
      </>
    );
  }
  