export const PricingConfig = {
    pricing : [
        {
            label:'Base Rate',
            value:'baseRate'
        },
        {
            label:'One Way Per Km Charge',
            value:'oneWayPerKmCharges'
        },
        {
            label:'Max One Way Charge',
            value:'maxOneWayCharges'
        },
        {
            label:'Platform Fees',
            value:'platformFees'
        },
        {
            label:'Weekend Charges',
            value:'weekendCharges'
        },
        {
            label:'Night Charges',
            value:'nightCharges'
        },
        {
            label:'Outstation Fees',
            value:'outstationCharges'
        }
    ],

    gcd : [
        {
            label:'Base Rate',
            value:'originalBaseRate'
        },
        {
            label:'Pricing Diluter (%)',
            value:'discountPercentage'
        },
        {
            label:'Final Base Rate',
            value:'dilutedBaseRate'
        }
    ]
}