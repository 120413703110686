import React from "react";

export default function BackArrowIcon({ fill = "#0F0F10" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 32 32"
      fill="none"
    >
      <path
        d="M28.6894 16.0001C28.6894 16.2918 28.578 16.5716 28.3797 16.7778C28.1813 16.9841 27.9123 17.1 27.6318 17.1H6.91612L14.6304 25.1213C14.7287 25.2235 14.8067 25.3449 14.8598 25.4784C14.913 25.6119 14.9404 25.755 14.9404 25.8996C14.9404 26.0441 14.913 26.1872 14.8598 26.3207C14.8067 26.4542 14.7287 26.5756 14.6304 26.6778C14.5322 26.78 14.4155 26.861 14.2871 26.9163C14.1587 26.9716 14.0211 27.0001 13.8822 27.0001C13.7432 27.0001 13.6056 26.9716 13.4772 26.9163C13.3488 26.861 13.2321 26.78 13.1339 26.6778L3.61489 16.7783C3.51655 16.6761 3.43854 16.5548 3.38531 16.4213C3.33208 16.2877 3.30469 16.1446 3.30469 16.0001C3.30469 15.8555 3.33208 15.7124 3.38531 15.5788C3.43854 15.4453 3.51655 15.324 3.61489 15.2218L13.1339 5.32235C13.3323 5.11595 13.6015 5 13.8822 5C14.1628 5 14.432 5.11595 14.6304 5.32235C14.8289 5.52874 14.9404 5.80867 14.9404 6.10056C14.9404 6.39244 14.8289 6.67237 14.6304 6.87877L6.91612 14.9001H27.6318C27.9123 14.9001 28.1813 15.016 28.3797 15.2223C28.578 15.4286 28.6894 15.7083 28.6894 16.0001Z"
        fill={fill}
      />
    </svg>
  );
}
