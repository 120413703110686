import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
  name: "auth",
  initialState: {
    userLoggedIn: false,
    userDetails: null,
    clientDetails: null,
    token: null,
    userRoles: null,
    clientId: null,
  },
  reducers: {
    logIn: (state) => {
      state.userLoggedIn = true;
    },
    logOut: (state) => {
      state.userLoggedIn = false;
      state.userDetails = null;
      state.userRoles = null;
      state.clientId = null;
      state.token = null;
      state.clientDetails = null;
    },
    setUserDetails: (state, action) => {
      state.userDetails = action.payload;
    },
    setClientDetails: (state, action) => {
      state.clientDetails = action.payload;
    },
    setClientId: (state, action) => {
      state.clientId = action.payload;
    },
    setToken: (state, action) => {
      state.token = action.payload;
    },
    setUserRoles: (state, action) => {
      state.userRoles = action.payload;
    },
  },
});

export const {
  logIn,
  logOut,
  setUserDetails,
  setClientDetails,
  setToken,
  setUserRoles,
  setClientId,
} = authSlice.actions;
export default authSlice.reducer;
