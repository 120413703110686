import React, { useRef, useState } from 'react';
import ModalLayout from '../../common/ModalLayout';
import { Field, Form, FormElement } from '@progress/kendo-react-form';
import { FormInput, FormUpload } from '../../../utils/forms/FormComponents';
import { Button } from '@progress/kendo-react-buttons';
import { bankAccountNumberValidator, ifscCodeValidator, nameValidator, requiredValidator } from '../../../utils/forms/Validators';
import axiosService from '../../../init/axios';
import { APIConfig } from '../../../utils/constants/api.constants';
import LoadingSpinner from '../../common/LoadingSpinner';
import { removeExistingFileOnFileUpload, showErrorMessage, showToastMessage } from '../../../utils/helpers';

const DriverBankDetailsModificationModal = ({driverId,bankDetails,onClose}) => {
    const [loading,setLoading] = useState(false);
    const [cancelledCheque,setCancelledCheque] = useState('');
    const uploadFileRef = useRef('');

    const onUploadFileClick = (e) => {
      removeExistingFileOnFileUpload(e,setCancelledCheque,uploadFileRef);
    }

    const onCancelledChequeUpload = (e) => {
        const file = e.target.files[0];
        // If not an image selected, return
        if (!/^image\//.test(file.type)) {
            showToastMessage(`Uploaded file is not an image.`,false);
            setCancelledCheque('');
            uploadFileRef.current.value='';
            return false;
        }
        setCancelledCheque(file);
    }

    const handleSubmit = async (dataItem) => {
        setLoading(true);
        const formData = new FormData();
        formData.append(
          "file",
          cancelledCheque
        );
        formData.append(
          "tag",
          "CANCELLED_CHEQUE"
        );
        formData.append(
          "accountNumber",
          dataItem?.accountNumber
        );
        formData.append(
          "accountHoldersName",
          dataItem?.accountHoldersName
        );
        formData.append(
          "ifsc",
          dataItem?.ifsc
        );
        await axiosService.put(process.env.REACT_APP_DRIVER_BASE_URL + APIConfig.driverManagement.saveDriverBankDetails(driverId), formData,  { 'Content-Type': 'multipart/form-data' })
          .then(data => {
            setLoading(false);
            showToastMessage('Bank details updated successfully');
            onClose('e',true);
          })
          .catch(error => {
            setLoading(false);
            showErrorMessage(error);
            onClose();
          });
    }

  return (
    <ModalLayout title='Edit Bank Account Details' onClose={onClose}>
        <div className='edit-bank-details' style={{minWidth:700}}>
            <Form
              onSubmit={handleSubmit}
              initialValues={bankDetails}
              key={JSON.stringify(bankDetails)}
              render={(formRenderProps) => (
                <FormElement
                  style={{
                    width: '100%',
                    background: '#fafafa',
                    padding: '15px',
                    border: '1px solid rgba(0, 0, 0, 0.08)',
                  }}
                >
                    <Field
                        id={"accountNumber"}
                        name={"accountNumber"}
                        label={"Bank Account Number*"}
                        component={FormInput}
                        validator={bankAccountNumberValidator}
                    />
                    <Field
                        id={"ifsc"}
                        name={"ifsc"}
                        label={"IFSC Code"}
                        component={FormInput}
                        validator={ifscCodeValidator}
                    />
                    <Field
                        id={"accountHoldersName"}
                        name={"accountHoldersName"}
                        label={"Account Holder Name*"}
                        component={FormInput}
                        validator={nameValidator}
                    />
                    <Field
                        id={"cancelledCheque"}
                        name={"cancelledCheque"}
                        label={"Cancelled Cheque Image*"}
                        component={FormUpload}
                        inputRef={uploadFileRef}
                        accept="image/png, image/jpg, image/jpeg"
                        onChange={onCancelledChequeUpload}
                        onClick={onUploadFileClick}
                        validator={requiredValidator}
                    />
                    <div className="k-form-buttons">
                      <Button
                        themeColor={"primary"}
                        type={"submit"}
                        disabled={!formRenderProps.valid || !formRenderProps.modified || loading || uploadFileRef?.current?.value===''}
                      >
                        {loading ? <LoadingSpinner width={25} height={25} /> : 'Submit'}
                      </Button>
                    </div>
                </FormElement>
              )}
            />
        </div>
    </ModalLayout>
  )
}

export default DriverBankDetailsModificationModal