import React, { useEffect, useState } from 'react'
import { APIConfig } from '../../utils/constants/api.constants';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import GridLoader from '../common/GridLoader';

const PreviousOperations = ({ refresh, dataState, setDataState }) => {
    const baseUrl = process.env.REACT_APP_DRIVER_BASE_URL + APIConfig.bulkOperationsManagement.getBulkOperation;
    const [bulkOpsList, setBulkOpsList] = useState({
        data: [],
        total: 0
    });

    const columns = [
        {
            id: "fileId",
            field: "fileId",
            title: "File Id",
            width:100
        },
        {
            id: "fileName",
            field: "fileName",
            title: "File Name",
            width:300
        },
        {
            id: "action",
            field: "action",
            title: "Action",
            width:200
        },
        {
            id: "processed",
            field: "processed",
            title: "Processed",
            width:100
        },
        {
            id: "createdAt",
            field: "createdAt",
            title: "Created At",
            width:180
        },
        {
            id: "createdBy",
            field: "createdBy",
            title: "Created By",
            width: 300
        },
        {
            id: "updatedAt",
            field: "updatedAt",
            title: "Updated At",
            width:180
        },
        {
            id: "status",
            field: "status",
            title: "Status",
            width:100
        }
    ];

    // useEffect(()=>{
    //     setDataState({...dataState});
    //     setRefresh((prevState)=>!prevState?true:true);
    // },[refreshTable])


    const dataStateChange = e => {
        setDataState(e.dataState);
    };
    const dataReceived = dataObj => {
        const updatedOperations = dataObj?.data?.bulkOperations.map(op => {
            return {...op,processed:`${Number(op?.processedRecords)}/${Number(op?.totalRecords)}`}
        });
        setBulkOpsList({...dataObj,data:updatedOperations,total:0});
    };

    return (
        <>
            <Grid filterable={false} sortable={false} pageable={false} {...dataState}
                data={bulkOpsList}
                onDataStateChange={dataStateChange}>
                {
                    columns.map(column => (
                        <Column key={column?.id} field={column?.field} title={column?.title} width={column?.width} />
                    ))
                }
            </Grid>

            <GridLoader baseUrl={baseUrl} columns={columns} refreshTable={refresh} dataState={dataState} onDataReceived={dataReceived} />
        </>
    )
}

export default PreviousOperations;