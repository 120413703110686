import React from 'react'
import CardLayout from '../components/common/CardLayout'
import CustomerManagementHome from '../components/customer-management/CustomerManagementHome'

const CustomerManagement = () => {
  return (
    <CardLayout title="Customer Management">
        <CustomerManagementHome />
    </CardLayout>
  )
}

export default CustomerManagement