import React, { useEffect, useState } from "react";
import CardLayout from "../../components/common/CardLayout";
import { Link, useNavigate, useParams } from "react-router-dom";
import LoadingPanel from "../../components/common/LoadingPanel";
import axiosService from "../../init/axios";
import { APIConfig } from "../../utils/constants/api.constants";
import {
  convertEnumToLabel,
  showErrorMessage,
  showToastMessage,
} from "../../utils/helpers";
import AssignDriverMap from "../../components/maps/AssignDriverMap";
import AssignDriverTable from "../../components/trip-management/assign-driver/AssignDriverTable";
import { Button } from "@progress/kendo-react-buttons";
import ModalLayout from "../../components/common/ModalLayout";
import BackArrowIcon from "../../assets/icons/BackArrowIcon";
import AssignDriverTableB2C from "../../components/trip-management/assign-driver/AssignDriverTableB2C";
import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout";
import { validate } from "uuid";

const initalModalState = {
  view: false,
  data: null,
};

export default function AssignDriver() {
  const { tripId } = useParams();
  const [loading, setLoading] = useState({
    table: true,
    fit: true,
  });
  const [dataState, setDataState] = useState({
    take: 10,
    skip: 0,
  });

  const [isB2BTrip, setIsB2BTrip] = useState(true);
  const [tripDetails, setTripDetails] = useState({});
  const [selectedDriverController, setSelectedDriverController] =
    useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    getTripDetails();
  }, []);

  // 1st api call
  const getTripDetails = async () => {
    await axiosService
      .get(
        process.env.REACT_APP_TRIP_BASE_URL +
          APIConfig.tripManagement.getTripDetails(tripId)
      )
      .then((data) => {
        setTripDetails(data.data);
        let isB2BClient = data?.data?.clientType != "B2C";
        if (isB2BClient) {
          getEligibleDrivers();
        } else {
          setIsB2BTrip(false);
          setLoading({
            table: false,
            fit: false,
          });
        }
      })
      .catch((error) => {
        setLoading({
          table: false,
          fit: false,
        });
        showErrorMessage(error);
      });
  };

  const [driverProfiles, setDriverProfiles] = useState({ data: [], total: 0 });
  const [modalOpen, setModalOpen] = useState(initalModalState);

  const getDriversUrlB2B =
    process.env.REACT_APP_DRIVER_BASE_URL +
    APIConfig.driverManagement.getDriverForTripB2B(tripId);

  const getDriverForTripB2C =
    process.env.REACT_APP_DRIVER_BASE_URL +
    APIConfig.driverManagement.getDriverForTripB2C(tripId);

  const getFitUrl =
    process.env.REACT_APP_TRIP_BASE_URL +
    APIConfig.tripManagement.getDriversTripFit(tripId);

  const assignDriverUrlB2B =
    process.env.REACT_APP_TRIP_BASE_URL +
    APIConfig.tripManagement.allocateDriver(tripId);

  // 3rd Api call
  const getFitData = async (driverIds, data) => {
    await axiosService
      .post(getFitUrl, {
        driverIds: driverIds,
      })
      .then((e) => {
        const finalArray = data.map((item) => ({
          ...item,
          allocationFit:
            e.data.find((f) => f.driverId === item.driverId)?.allocationFit ||
            "",
        }));

        setDriverProfiles(finalArray);
        setLoading({
          table: false,
          fit: false,
        });
      })
      .catch((error) => {
        setLoading({
          table: false,
          fit: false,
        });
        showErrorMessage(error);
      });
  };

  // 2nd api call
  const getEligibleDrivers = async () => {
    await axiosService
      .get(getDriversUrlB2B)
      .then((e) => {
        const driverIds = e.data.map((item) => item?.driverId || "");
        const data = e.data;
        setDriverProfiles(data);
        setLoading({
          table: false,
          fit: true,
        });
        getFitData(driverIds, data);
      })
      .catch((error) => {
        setLoading({
          table: false,
          fit: false,
        });
        showErrorMessage(error);
      });
  };

  const handleClose = () => {
    setModalOpen(initalModalState);
  };

  const handleAssignDriver = async (validate) => {
    isB2BTrip ? assignDriverB2B() : assignDriverB2C(validate);
  };

  const assignDriverB2C = async (validate) => {
    const assignDriver =
      process.env.REACT_APP_TRIP_BASE_URL_NEW +
      APIConfig.tripManagement.allocateDriverB2C(
        tripId,
        selectedDriverController.driverId.toUpperCase(),
        validate
      );
    await axiosService
      .put(assignDriver)
      .then((e) => {
        handleClose();
        navigate(`/home/trip/${tripId}/details`);
        showToastMessage("Assigned Driver Successfully");
      })
      .catch((error) => {
        const _error = error?.response?.data?.error;
        if (_error?.code === "DRIVER_ELIGIBILITY_ERROR") {
          setModalOpen({
            view: true,
            data: _error?.data || [],
          });
        } else {
          showErrorMessage(error);
          handleClose();
        }
      });
  };

  const assignDriverB2B = async () => {
    await axiosService
      .post(assignDriverUrlB2B, {
        driverId: selectedDriverController.driverId,
      })
      .then((e) => {
        handleClose();
        navigate(`/home/trip/${tripId}/details`);
        showToastMessage("Assigned Driver Successfully");
      })
      .catch((error) => {
        showErrorMessage(error);
        handleClose();
      });
  };
  return (
    <CardLayout title="Assign Task">
      {loading.table ? (
        <LoadingPanel />
      ) : (
        <div className="tw-flex tw-gap-5 tw-h-full tw-w-full">
          <div className="tw-w-2/3">
            <div className="tw-flex tw-justify-between tw-gap-5 tw-items-center">
              <div className="tw-flex tw-gap-2">
                <Link to={`/home/trip/${tripId}/details`}>
                  <span className="tw-cursor-pointer">
                    <BackArrowIcon />
                  </span>
                </Link>
                <span className="tw-font-semibold">Trip ID: {tripId}</span>
              </div>
              <Button
                onClick={() =>
                  setModalOpen({ ...initalModalState, view: true })
                }
                themeColor={"secondary"}
                disabled={!selectedDriverController}
              >
                Assign
              </Button>
            </div>
            {tripId && (
              <>
                {isB2BTrip ? (
                  <AssignDriverTable
                    fitLoading={loading.fit}
                    driverProfiles={driverProfiles}
                    selectedDriverController={selectedDriverController}
                    setSelectedDriverController={setSelectedDriverController}
                    tripId={tripId}
                  />
                ) : (
                  <AssignDriverTableB2C
                    driverProfiles={driverProfiles}
                    selectedDriverController={selectedDriverController}
                    setSelectedDriverController={setSelectedDriverController}
                    tripId={tripId}
                    baseUrl={getDriverForTripB2C}
                    dataState={dataState}
                    setDataState={setDataState}
                    setDriverProfiles={setDriverProfiles}
                  />
                )}
              </>
            )}
          </div>

          <div className="tw-w-1/3 tw-bg-gray-200 tw-h-[80dvh] tw-relative">
            {tripDetails?.tripId && (
              <AssignDriverMap
                setSelectedDriverController={setSelectedDriverController}
                tripDetails={tripDetails}
                selectedDriverController={selectedDriverController}
                driverProfiles={driverProfiles}
              />
            )}
          </div>

          {modalOpen.view && (
            <Modal
              data={modalOpen.data}
              driver={selectedDriverController}
              handleClose={handleClose}
              handleAssignDriver={handleAssignDriver}
            />
          )}
        </div>
      )}
    </CardLayout>
  );
}

const Modal = ({ data, driver, handleClose, handleAssignDriver }) => {
  return (
    <ModalLayout title={"Confirm Assigned Driver"} onClose={handleClose}>
      <div className="tw-min-w-[40vw] tw-max-h-[70dvh] tw-overflow-y-auto">
        {data ? (
          <>
            <PanelBar expandMode={"multiple"}>
              {data?.map((item, index) => (
                <PanelBarItem
                  key={index}
                  title={convertEnumToLabel(item?.code)}
                >
                  <div className="custom-border-primary tw-p-3 tw-rounded-md">
                    <h6 className="tw-text-sm tw-text-[#262626]">Expected:</h6>
                    <span className="tw-block tw-text-[#717272] tw-text-xs">
                      {item.expected}
                    </span>
                    <h6 className="tw-text-sm tw-mt-3 tw-text-[#262626]">
                      Actual:
                    </h6>
                    <div className="tw-block tw-text-[#717272] tw-text-xs">
                      {item.actual.map((item, index) => (
                        <span key={index}>{item}</span>
                      ))}
                    </div>
                  </div>
                </PanelBarItem>
              ))}
            </PanelBar>
            <div className="tw-flex tw-mt-10 tw-justify-between">
              <Button onClick={handleClose} themeColor={"default"}>
                Select another
              </Button>
              <Button
                onClick={() => handleAssignDriver(false)}
                themeColor={"primary"}
              >
                Assign anyway
              </Button>
            </div>{" "}
          </>
        ) : (
          <>
            <p className="tw-text-base">
              Please confirm if you want to assign the following driver.
            </p>
            <h6 className="tw-font-normal tw-text-sm">{driver.fullName}</h6>
            <h6 className="tw-font-normal tw-text-sm">{driver.mobileNumber}</h6>
            <div className="tw-flex tw-mt-10 tw-justify-between">
              <Button onClick={handleClose} themeColor={"error"}>
                Cancel
              </Button>
              <Button
                onClick={() => handleAssignDriver(true)}
                themeColor={"primary"}
              >
                Confirm
              </Button>
            </div>
          </>
        )}
      </div>
    </ModalLayout>
  );
};
