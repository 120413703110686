import React, { useEffect, useState } from 'react'
import LoadingSpinner from './LoadingSpinner';
import { showToastMessage } from '../../utils/helpers';

const FileUploadAndView = ({ onUploadDriverContract, driverContractImagePath, getImage, loading }) => {
    const [showBrowseBtn, setShowBrowseBtn] = useState(true);

    useEffect(() => {
        if (driverContractImagePath) {
            setShowBrowseBtn(false);
        }
    }, [driverContractImagePath]);

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        if(selectedFile.size>10485760){
            showToastMessage('File size should be maximum 10MB.',false);
            return;
        }
        onUploadDriverContract(selectedFile);
    };

    const handleViewFile = async () => {
        getImage('Driver Contract', driverContractImagePath, 'pdf');
    };

    const handleRemoveFile = () => {
        setShowBrowseBtn(true);
    };

    return (
        <div style={{ padding: '10px' }}>
            {
                loading ? <LoadingSpinner color='#ff6358' width={50} height={50} /> : (
                    <>
                        {
                            showBrowseBtn ? (
                                <input type="file" accept=".pdf" title='' onChange={handleFileChange} style={{ color: 'transparent', width: '100px', overflow: 'hidden' }} />
                            ) : (
                                <span className='upload-driver-contract-div' >
                                    <span className='cursor-pointer' style={{ textDecoration: 'underline' }} onClick={handleViewFile}>Driver-Contract.pdf</span>
                                    <span><img className='cursor-pointer' src={require('../../assets/cross-icon.png')} alt='remove' onClick={handleRemoveFile} width={20} /></span>
                                </span>
                            )
                        }
                    </>
                )
            }
        </div>
    )
}

export default FileUploadAndView;