import React, { useState } from "react";
import {
  convertEnumToLabel,
  driverStatusTags,
  stringToSentenceCase,
} from "../../../utils/helpers";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import GridLoader from "../../common/GridLoader";

export default function AssignDriverTableB2C({
  driverProfiles,
  setSelectedDriverController,
  selectedDriverController,
  tripId,
  dataState,
  setDataState,
  setDriverProfiles,
  baseUrl,
}) {
  const columns = [
    {
      id: "driverId",
      field: "driverId",
      title: "Driver ID",
      filterable: true,
      filterKey: "driverId",
      filterType: "string",
      defaultFilterOperator: "eq",
    },
    {
      id: "fullName",
      field: "fullName",
      title: "Driver Name",
      filterable: false,
      filterKey: "full_name",
      filterType: "string",
      defaultFilterOperator: "lk",
    },
    {
      id: "mobileNumber",
      field: "mobileNumber",
      title: "Mobile Number",
      filterable: false,
      filterKey: "mobile_number",
      filterType: "string",
      defaultFilterOperator: "lk",
    },
    {
      id: "driverStatus",
      field: "driverStatus",
      title: "Status",
      filterable: false,
      cell: (cell) => (
        <td
          colSpan={cell.colSpan}
          role="gridcell"
          aria-colindex={cell.ariaColumnIndex}
          aria-selected="false"
          datagridcolindex={cell.columnIndex}
        >
          {driverStatusTags(cell?.dataItem?.driverStatus)}
        </td>
      ),
    },
  ];

  const handleRowClick = (e) => {
    setSelectedDriverController(e.dataItem);
  };

  const rowRender = (row, rowProps) => {
    const isSelected =
      rowProps?.dataItem?.driverId === selectedDriverController?.driverId;
    const rowClass = isSelected ? "selected-row" : "";
    return React.cloneElement(
      row,
      {
        className: `${row.props.className} ${rowClass}`, // Add the class to the existing ones
      },
      row.props.children
    );
  };
  const dataStateChange = (e) => {
    setDataState(e.dataState);
  };

  const dataReceived = (dataObj) => {
    const arr = dataObj.data.data.map((driver) => {
      let driverObj = {};

      if (driver?.lastKnownLat) {
        driverObj = {
          ...driverObj,
          lastKnownLat: driver?.lastKnownLat,
        };
      } else {
        driverObj = { ...driverObj, lastKnownLat: "" };
      }
      if (driver?.lastKnownLng) {
        driverObj = {
          ...driverObj,
          lastKnownLng: driver?.lastKnownLng,
        };
      } else {
        driverObj = { ...driverObj, lastKnownLng: "" };
      }

      if (driver?.fullName) {
        driverObj = {
          ...driverObj,
          fullName: stringToSentenceCase(driver?.fullName),
        };
      } else {
        driverObj = { ...driverObj, fullName: "" };
      }

      if (driver?.driverId) {
        driverObj = {
          ...driverObj,
          driverId: driver?.driverId,
        };
      } else {
        driverObj = { ...driverObj, driverId: "" };
      }

      if (driver?.driverStatus) {
        driverObj = {
          ...driverObj,
          driverStatus: stringToSentenceCase(driver?.driverStatus),
        };
      } else {
        driverObj = { ...driverObj, driverStatus: "" };
      }

      if (driver?.mobileNumber) {
        driverObj = {
          ...driverObj,
          mobileNumber: stringToSentenceCase(driver?.mobileNumber),
        };
      } else {
        driverObj = { ...driverObj, mobileNumber: "" };
      }

      return { ...driverObj };
    });

    setDriverProfiles({ ...dataObj, data: arr });
  };

  return (
    <div className="tw-mt-10">
      <Grid
        pageable={true}
        filterable={true}
        sortable={false}
        onRowClick={handleRowClick}
        {...dataState}
        data={driverProfiles}
        onDataStateChange={dataStateChange}
        rowRender={rowRender}
      >
        {columns.map((column) => (
          <Column
            key={column?.id}
            field={column?.field}
            title={column?.title}
            filterable={column?.filterable}
            width={column?.width}
            cell={column?.cell}
          />
        ))}
      </Grid>
      <GridLoader
        baseUrl={baseUrl}
        columns={columns}
        dataState={dataState}
        onDataReceived={dataReceived}
      />
    </div>
  );
}
