import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import React, { useEffect, useState } from "react";
import { process } from "@progress/kendo-data-query";
import { useNavigate } from "react-router-dom";
import {
  CategoryFilterCell,
  formatAddress,
  getLabelFromEnum,
  initialFilter,
  timeConverter,
} from "../../utils/helpers";
import GridLoader from "../common/GridLoader";
import { APIConfig } from "../../utils/constants/api.constants";
import { useSelector } from "react-redux";
import { SubscriptionConfig } from "../../config/subscriptionConfig";

export default function SubscriptionsTable({ dataState, setDataState }) {
  const baseUrl = process.env.REACT_APP_TRIP_BASE_URL + APIConfig.subscription.getAllSubscriptions;
  
  const navigate = useNavigate();

  const selectedSubscriptionRegionFilters = useSelector(
    (state) => state.region?.subscription
  );

  const [subscriptionList, setSubscriptionList] = useState({
    data: [],
    total: 0,
  });

  const columns = [
    {
      id: "id",
      field: "id",
      title: "Package ID",
      filterable: true,
      filterKey: "id",
      filterType: "string",
      defaultFilterOperator: "lk",
      width: 200,
    },
    {
      id: "customerName",
      field: "customerName",
      title: "Customer Name",
      filterable: true,
      filterKey: "customer_name",
      filterType: "string",
      defaultFilterOperator: "lk",
      width: 210,
    },
    {
      id: "customerMobile",
      field: "customerMobile",
      title: "Customer Number",
      filterable: true,
      filterKey: "customer_mobile",
      filterType: "string",
      defaultFilterOperator: "lk",
      width: 210,
    },
    {
      id: "status",
      field: "status",
      title: "Current Status",
      filterable: true,
      filterKey: "status",
      filterType: "string",
      defaultFilterOperator: "eq",
      data: SubscriptionConfig.status,
      width: 200,
    },
    {
      id: "driverId",
      field: "driverId",
      title: "Driver ID",
      filterable: true,
      filterKey: "driver_id",
      filterType: "string",
      defaultFilterOperator: "lk",
      width: 200,
    },
    {
      id: "driverName",
      field: "driverName",
      title: "Driver Name",
      filterKey: "driver_name",
      filterable: true,
      filterType: "string",
      defaultFilterOperator: "lk",
      width: 200,
    },
    {
      id: "noOfDays",
      field: "noOfDays",
      title: "Package days",
      filterable: false,
      filterKey: "no_of_days",
      filterType: "string",
      defaultFilterOperator: "lk",
      width: 200,
    },
    {
      id: "noOfHours",
      field: "noOfHours",
      title: "Package hours",
      filterable: false,
      filterKey: "package_hours",
      filterType: "string",
      defaultFilterOperator: "lk",
      width: 200,
    },
    {
      id: "createdAt",
      field: "createdAt",
      title: "Package creation time",
      filterable: false,
      width: 200,
    },
    {
      id: "startTime",
      field: "startTime",
      title: "Package start time",
      filterable: false,
      width: 200,
    },
    {
      id: "pickLocation",
      field: "pickLocation",
      title: "Pick location",
      filterable: false,
      width: 500,
    },
    {
      id: "region",
      field: "region",
      title: "Region",
      filterable: false,
      width: 400,
    },
    {
      id: "updatedAt",
      field: "updatedAt",
      title: "Updated At",
      filterable: false,
      width: 200,
    },
  ];

  const dataStateChange = (e) => {
    if (selectedSubscriptionRegionFilters) {
      setDataState({
        ...e.dataState,
        otherParams: {},
      });
    } else {
      if (
        dataState?.otherParams &&
        Object.keys(dataState?.otherParams)?.length > 0
      ) {
        setDataState({
          ...e.dataState,
          otherParams: {},
        });
      } else {
        setDataState(e.dataState);
      }
    }
  };

  const dataReceived = (dataObj) => {
    const arr = dataObj.data.items.map((subs) => {
      const updatedLabels = getLabelFromEnum(["status"], subs, "subscription");

      return {
        ...updatedLabels,
        createdAt: subs.createdAt ? timeConverter(subs.createdAt) : "",
        startTime: subs.startTime ? timeConverter(subs.startTime) : "",
        pickLocation: subs.pickupAddress ? formatAddress(subs.pickupAddress) : "",
        updatedAt: subs.updatedAt ? timeConverter(subs.updatedAt) : "",
      };

    });

    setSubscriptionList({ ...dataObj, data: arr });
  };

  const onSubscriptionRowClick = (item) => {
    navigate("/home/subscriptions/" + item?.dataItem?.id + "/details");
  };


  useEffect(() => {
    if (selectedSubscriptionRegionFilters?.regionId) {
      setDataState({
        ...dataState,
        otherParams: {},
      });
    }
  }, [selectedSubscriptionRegionFilters]);

 
  return (
    <>
      <Grid
        filter={initialFilter}
        filterable={true}
        sortable={false}
        pageable={true}
        {...dataState}
        data={subscriptionList}
        onDataStateChange={dataStateChange}
        onRowDoubleClick={onSubscriptionRowClick}
      >
        {columns.map((column) => (
          <Column
            key={column?.id}
            field={column?.field}
            title={column?.title}
            width={column?.width}
            filterable={column?.filterable}
            filterCell={
              column.defaultFilterOperator === "eq" &&
              ((props) => CategoryFilterCell(props, column, 250))
            }
          />
        ))}
      </Grid>
      <GridLoader
        baseUrl={baseUrl}
        columns={columns}
        dataState={dataState}
        onDataReceived={dataReceived}
      />
    </>
  );
}
