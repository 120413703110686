import axiosService from "../../../../../init/axios";
import { APIConfig } from "../../../../../utils/constants/api.constants";
import { getLabelValueObjectFromEnum, showErrorMessage } from "../../../../../utils/helpers";

export const useApi = ({ tripId='',setPricingAmount=()=>{},setWaiverConfig=()=>{},setLoading=()=>{},setWaiverDetails=()=>{}}) => {
  const getTripPricing = async () => {
    await axiosService
      .get(
        process.env.REACT_APP_TRIP_BASE_URL +
          APIConfig.tripManagement.getTripPricing(tripId)
      )
      .then((data) => {
        const actualFare = data?.data?.actualTripFare;
        const expectedFare = data?.data?.expectedTripFare;
        setPricingAmount(
          actualFare
            ? actualFare?.discountedAmount
            : expectedFare?.discountedAmount
        );
      })
      .catch((error) => {
        showErrorMessage(error);
      });
  };

  const getWaiverConfig = async () => {
    await axiosService
      .get(
        process.env.REACT_APP_TRIP_BASE_URL +
          APIConfig.tripManagement.configData +
          "?key=WAIVER_CONFIG"
      )
      .then((data) => {
        if (data?.data?.waiverConfig) {
          setWaiverConfig(data?.data?.waiverConfig);
        }
      })
      .catch((error) => {
        showErrorMessage(error);
      });
  };

  const getWaiverDetails = async () => {
    setLoading(true);
    await axiosService
      .get(
        process.env.REACT_APP_TRIP_BASE_URL +
          APIConfig.tripManagement.getWaiverDetails(tripId)
      )
      .then((data) => {
        if (data?.data?.waiverDetails) {
            const waiverData = { ...data?.data?.waiverDetails };
            const details = {
              ...waiverData,
              waiverReason: waiverData?.reason,
              waiverSubReason: waiverData?.subReason,
            };
            
            setWaiverDetails((prev) => {
              const updatedDetails = getLabelValueObjectFromEnum(
                ["waiverReason", "waiverSubReason"],
                {
                  ...details,
                  waiverStatus: prev?.status, 
                },
                "trip"
              );
            
              return updatedDetails; 
            });
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        showErrorMessage(error);
      });
  };

  return { getTripPricing, getWaiverConfig, getWaiverDetails }; 
};
