import React, { useState } from 'react'
import { APIConfig } from '../../../utils/constants/api.constants';
import axiosService from '../../../init/axios';
import { downloadFileFromAPI, showErrorMessage, showToastInfoMessage, showToastMessage } from '../../../utils/helpers';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import GridLoader from '../../common/GridLoader';

const DriverPayoutAllLogsTable = () => {
  const baseUrl = process.env.REACT_APP_DRIVER_BASE_URL + APIConfig.driverManagement.getDriverPayoutBatch;
  const [loading,setLoading] = useState(false);
  const [driverPayoutLogs, setDriverPayoutLogs] = useState({
    data: [],
    total: 0
  });
  const [dataState, setDataState] = useState({
    take: 10,
    skip: 0
  });

  const DriverPayoutCell = (props) => (
    <div onClick={(e) => onDriverPayoutClick(e, props.dataItem?.batchId)} style={{ cursor: 'pointer', color: 'blue', margin: '20px 10px' }}>{props.dataItem?.batchId}</div>
  );

  const columns = [
    {
      id: "batchId",
      title: "File",
      cell: DriverPayoutCell,
      width:200
    },
    {
      id: "createdAt",
      field: "createdAt",
      title: "Created At",
      filterable: false,
      width:200
    },
    {
      id: "status",
      field: "status",
      title: "Status",
      filterable: false,
      width:150
    },
    {
      id: "actionAt",
      field: "actionAt",
      title: "Action At",
      filterable: false,
      width:200 
    },
    {
      id: "actionBy",
      field: "actionBy",
      title: "Action By",
      filterable: false,
      width:250 
    },
    {
      id: "note",
      field: "note",
      title: "Note",
      filterable: false,
      width:600
    }
  ];

  const dataStateChange = e => {
    setDataState({ ...e.dataState });
  };
  const dataReceived = dataObj => {
    const updatedLogs = dataObj?.data?.data.map(data => {
      return {...data,note:data?.additionalDetails?.rejectionRemarks}
    })
    setDriverPayoutLogs({ ...dataObj, data: updatedLogs });
  };

  const onDriverPayoutClick = async (e, id) => {
    if(loading){
      return;
    }
    setLoading(true);
    showToastInfoMessage('Downloading payout...');
    await axiosService.get(process.env.REACT_APP_DRIVER_BASE_URL + APIConfig.driverManagement.getDriverPayoutBatchFile(id))
      .then(response => {
        downloadFileFromAPI(response?.data, id);
        showToastMessage('Payout downloaded successfully');
        setLoading(false);
      }).catch(error => {
        setLoading(false);
        showErrorMessage(error);
      });
  }

  return (
    <>
      <Grid filterable={false} sortable={false} pageable={true} {...dataState}
        data={driverPayoutLogs}
        onDataStateChange={dataStateChange}>
        {
          columns.map(column => (
            <Column key={column?.id} field={column?.field} title={column?.title} cell={column?.cell} width={column?.width} />
          ))
        }
      </Grid>
      <GridLoader baseUrl={baseUrl} columns={columns} dataState={dataState} onDataReceived={dataReceived} />
    </>
  )
}

export default DriverPayoutAllLogsTable;