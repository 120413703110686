import { Link } from "react-router-dom";
import { ACTIVITY_ACCORDIAN_TYPES } from "../../../config/driverConfig";
import { ChevronDownIcon } from "../../../assets/icons/ChevronDownIcon";
import { ChevronUpIcon } from "../../../assets/icons/ChevronUpIcon";

function TimeIntervalComponent({ start, end }) {
    return <div>
        <span className="tw-block tw-font-medium">{start.title}</span>
        <span className="tw-block tw-opacity-60 tw-text-xs">
            {start.value}
        </span>
        <span className="tw-block tw-font-medium tw-mt-2">
            {end.title}
        </span>
        <span className="tw-block tw-opacity-60 tw-text-xs">
            {end.value}
        </span>
    </div>
}


export function TreeNode({
    node,
    isOpen,
    onClick,
    handleSelectedTrip,
    openChild,
}) {

    const { driverReachingInfo, tripDetails } = node;
    return (
        <li>
            <div
                onClick={node.children && node.children.length > 0 ? onClick : null}
                className={`custom-border-primary tw-w-full tw-p-4 tw-mb-5 tw-flex tw-items-center tw-justify-between tw-rounded-md ${node.children && node.children.length > 0 && "tw-cursor-pointer"
                    }`}
            >
                <div>
                    {!!node.type && <div className="tw-flex tw-items-center tw-gap-3 tw-font-semibold">
                        <div style={{ backgroundColor: ACTIVITY_ACCORDIAN_TYPES[node.type]?.color }} className={`tw-h-[10px] tw-w-[10px] tw-rounded-full`}></div>
                        <div>
                            {node.name || ACTIVITY_ACCORDIAN_TYPES[node.type]?.label}
                            <span className="tw-block tw-opacity-60 tw-text-xs">
                                {node.startTime}
                            </span>
                            {node.tripId && (
                                <Link
                                    to={`/home/trip/${node.tripId}/details`}
                                    className="tw-block tw-mt-1 tw-opacity-80 tw-text-xs"
                                >
                                    {node.tripId}
                                </Link>
                            )}
                        </div>
                    </div>}
                    {!!driverReachingInfo && (
                        <TimeIntervalComponent {...driverReachingInfo} />
                    )}
                    {!!tripDetails && (
                        <TimeIntervalComponent {...tripDetails} />
                    )}
                </div>

                {node.children && node.children.length > 0 && (
                    <span>{!isOpen ? <ChevronDownIcon /> : <ChevronUpIcon />}</span>
                )}
            </div>
            {node.children && node.children.length > 0 && isOpen && (
                <ul>
                    {node.children.map((child, index) => (
                        <TreeNode
                            key={index}
                            node={child}
                            isOpen={index === openChild}
                            onClick={() => handleSelectedTrip(index, child)}
                            handleSelectedTrip={handleSelectedTrip}
                        />
                    ))}
                </ul>
            )}
        </li>
    );
};