import React, { useState } from "react";
import ModalLayout from "../common/ModalLayout";
import { FormDropDownList, FormInput } from "../../utils/forms/FormComponents";
import { Button } from "@progress/kendo-react-buttons";
import { BlockingConfig } from "../../config/blockingConfig";

const CustomerBlockingModel = ({ onClose, handleSubmit, blockingType }) => {
  const [customerBlockingReason, setCustomerBlockingReason] = useState(
    BlockingConfig.reason[0]
  );
  const [customerBlockingSubReason, setCustomerBlockingSubReason] =
    useState("");

  return (
    <ModalLayout title={"Customer Blocking/Unblocking"} onClose={onClose}>
      <div className="add-coupon-div" style={{ minWidth: 700 }}>
        <>
          <div className="row">
            <div className="col-sm-3">Reason</div>
            <div className="col-sm-5">
              <FormDropDownList
                data={BlockingConfig.reason}
                textField="label"
                dataItemKey="value"
                value={customerBlockingReason}
                onChange={(e) => setCustomerBlockingReason(e.target.value)}
                style={{ width: "200px" }}
              />
            </div>
          </div>
          <div className="row" style={{ marginTop: "20px" }}>
            <div className="col-sm-3">Sub Reason</div>
            <div className="col-sm-5">
              <div>
                <FormInput
                  style={{ width: "200px" }}
                  value={customerBlockingSubReason}
                  onChange={(e) => setCustomerBlockingSubReason(e.target.value)}
                />
              </div>
            </div>
          </div>
        </>
        {customerBlockingReason.value === "OTHER" &&
          customerBlockingSubReason.trim() === "" && (
            <span
              style={{
                display: "block",
                marginTop: "10px",
                color: "red",
                marginBottom: "-20px",
              }}
            >
              Please add a Sub Reason
            </span>
          )}

        <Button
          themeColor={"primary"}
          type={"button"}
          style={{
            marginTop: "20px",
            marginLeft: "auto",
            marginRight: "auto",
            display: "block",
          }}
          disabled={
            customerBlockingReason.value === "OTHER" &&
            customerBlockingSubReason.trim() === ""
          }
          onClick={(e) =>
            handleSubmit(
              customerBlockingReason?.value,
              customerBlockingSubReason
            )
          }
        >
          {blockingType} User
        </Button>
      </div>
    </ModalLayout>
  );
};

export default CustomerBlockingModel;
