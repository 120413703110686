import React, { useState } from "react";
import CardLayout from "../components/common/CardLayout";
import { useDispatch, useSelector } from "react-redux";
import { updateSubscriptionRegionFilter } from "../slices/regionSlice";
import { DropdownSearchSingleSelect } from "../utils/forms/NewFormComponents";
import SubscriptionsTable from "../components/subscription-package/SubscriptionsTable";
import { getFromLocalStorage } from "../utils/helpers";

export default function SubscriptionPackage() {
  const regions = useSelector((state) => state.region?.mainRegions);
  const selectedSubscriptionRegionFilter = useSelector(
    (state) => state.region?.subscription
  );

  const dispatch = useDispatch();
  const [dataState, setDataState] = useState({
    take: 10,
    skip: 0,
    otherParams: selectedSubscriptionRegionFilter
      ? {
          // parent_region:
          //   selectedSubscriptionRegionFilter?.regionId + ":in:string",
        }
      : {},
  });

  const onSubscriptionRegionFilterChange = (e) => {
    dispatch(updateSubscriptionRegionFilter(e));
    setDataState({
      take: 10,
      skip: 0,
      otherParams: {
        // parent_region: e.regionId + ":in:string",
      },
    });
  };

  const updatedRegions = regions?.slice()?.map((item, index) => ({
    ...item,
    label: item.child
      ? `${item.regionName} (${item.parentRegionName})`
      : item.regionName,
  }));

  const SubscriptionRegionFilter = () => (
    <div className="tw-w-full">
      <DropdownSearchSingleSelect
        options={updatedRegions}
        textField="label"
        onChange={onSubscriptionRegionFilterChange}
        value={selectedSubscriptionRegionFilter}
        backupMessage="No Options Available"
        parentClass="tw-relative tw-w-64"
      />
    </div>
  );
  return (
    <CardLayout title="Subscriptions" ComponentRender={SubscriptionRegionFilter}>
      <SubscriptionsTable dataState={dataState} setDataState={setDataState} />
    </CardLayout>
  );
}
