import React, { useEffect, useState } from "react";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import DriverProfilesTable from "./driver-profiles/DriverProfilesTable";
import DriverProfileForm from "./driver-profile/DriverProfileForm";
import DriverAccount from "./driver-account/DriverAccount";
import DriverOnlineActivity from "./driver-online-activity/DriverOnlineActivity";

const tabs = [
  { title: "Profile", component: <DriverProfileForm />, path: "profile" },
  { title: "Account", component: <DriverAccount />, path: "account" },
  {
    title: "Online Activity",
    component: <DriverOnlineActivity />,
    path: "online-activity",
  },
];

const DriverManagementNavigation = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { driverId } = useParams();
  const [searchParams] = useSearchParams();
  const [selectedTab, setSelectedTab] = useState(null);

  useEffect(() => {
    const path = location.pathname.split("/").at(-1);
    const tabIndex = tabs.findIndex((tab) => tab.path === path);
    if (tabIndex !== -1) {
      setSelectedTab(tabIndex);
    }
  }, [location.pathname]);

  const handleSelect = (index) => {
    const tab = tabs[index];
    navigate(
      `/home/driver/${driverId}/${tab.path}${
        searchParams.get("driverName")
          ? "?driverName=" + searchParams.get("driverName")
          : ""
      }`
    );
    setSelectedTab(index);
  };

  return (
    <>
      {selectedTab !== null && (
        <div>
          <div className="tw-flex tw-mb-2">
            {tabs.map((tab, index) => (
              <button
                key={index}
                onClick={() => handleSelect(index)}
                className={`tw-py-1.5 tw-rounded-md tw-px-3 tw-text-sm ${
                  selectedTab === index
                    ? "tw-border-transparent tw-bg-primary tw-text-white"
                    : "tw-border-transparent tw-bg-transparent"
                }`}
              >
                {tab.title}
              </button>
            ))}
          </div>
          <div className="tw-p-4 custom-border-primary tw-rounded-md">
            {driverId &&
            tabs[selectedTab].path === location.pathname.split("/").at(-1) ? (
              tabs[selectedTab].component
            ) : (
              <DriverProfilesTable />
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default DriverManagementNavigation;
