import { Button } from "@progress/kendo-react-buttons";
import { TextArea } from "@progress/kendo-react-inputs";
import React, { useState } from "react";
import ModalLayout from "../../common/ModalLayout";

const PayoutRejectReasonModal = ({ loading,selectedPayoutBatch,onClose,onApprovePayout }) => {
    const [reason, setReason] = useState('');

    const onSubmit = () => {
        onApprovePayout(selectedPayoutBatch,false,reason);
    }

    return (
        <ModalLayout title='Please enter reason' onClose={onClose}>
            <div style={{textAlign:'center'}}>
                <TextArea
                    rows={8}
                    maxLength={250}
                    value={reason}
                    style={{ width: 500 }}
                    onChange={(e) => setReason(e?.value)}
                />
                <br />
                <br />
                <Button
                    themeColor={"primary"}
                    type={"button"}
                    disabled={!reason || loading}
                    onClick={onSubmit}
                >
                    Submit
                </Button>
            </div>
        </ModalLayout>
    );
};

export default PayoutRejectReasonModal;
