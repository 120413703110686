import React, { useEffect, useState } from "react";
import CardLayout from "../components/common/CardLayout";
import AddressesTable from "../components/saved-address/AddressesTable";
import axiosService from "../init/axios";
import { APIConfig } from "../utils/constants/api.constants";
import { showErrorMessage } from "../utils/helpers";
import { useSelector } from "react-redux";

export default function AddressesPage() {
  const clientId = useSelector((state) => state.auth.clientId);
  const baseUrl =
    process.env.REACT_APP_USER_BASE_URL +
    APIConfig.clients.getClientsAddresses(clientId);

  const [dataState, setDataState] = useState({
    take: 10,
    skip: 0,
    otherParams: {},
  });

  //   const fetchSavedAddresses = async () => {
  //     axiosService
  //       .get(baseUrl)
  //       .then((response) => {
  //         const data = response?.data;
  //         const addresses = data?.addresses;
  //         setDataState(addresses);
  //       })
  //       .catch((err) => {
  //         showErrorMessage(err);
  //       });
  //   };

  //   useEffect(() => {
  //     fetchSavedAddresses();
  //   }, [clientId]);

  return (
    <CardLayout title={"Saved Addresses"}>
      {clientId && (
        <AddressesTable
          dataState={dataState}
          setDataState={setDataState}
          baseUrl={baseUrl}
        />
      )}
    </CardLayout>
  );
}
