import BulkOperationIcon from "../assets/icons/BulkOperationIcon";
import CouponManagementIcon from "../assets/icons/CouponManagementIcon";
import CustomerManagementIcon from "../assets/icons/CustomerManagementIcon";
import DriverManagementIcon from "../assets/icons/DriverManagementIcon";
import PricingLogicManagementIcon from "../assets/icons/PricingLogicManagementIcon";
import SavedAddressIcon from "../assets/icons/SavedAddressIcon";
import SubscriptionIcon from "../assets/icons/SubscriptionIcon";
import TripManagementIcon from "../assets/icons/TripManagementIcon";

export const drawerConfig = [
  {
    text: "Trip Management",
    selected: false,
    route: [
      "/home/trip",
      "/home/trip/tripId/details",
      "/home/trip/tripId/assign-driver",
    ],
    icon: TripManagementIcon,
  },
  // {
  //   text: "Fleet Management",
  //   selected: false,
  //   route: [
  //     "/home/driver",
  //     "/home/driver/driverId/profile",
  //     "/home/driver/driverId/account",
  //     "/home/driver/driverId/online-activity",
  //   ],
  //   icon: "",
  // },
  {
    text: "Driver Management",
    selected: false,
    route: [
      "/home/driver",
      "/home/driver/driverId/profile",
      "/home/driver/driverId/account",
      "/home/driver/driverId/online-activity",
    ],
    icon: DriverManagementIcon,
    children: [
      {
        text: "All Drivers",
        route: "/home/driver/all",
      },
      {
        text: "Fleet View",
        route: "/home/driver",
      },
      {
        text: "Driver Payout",
        route: "/home/driver/payout",
      },
    ],
  },
  {
    text: "Pricing Logic Management",
    selected: false,
    route: ["/home/pricing"],
    icon: PricingLogicManagementIcon,
  },
  {
    text: "Customer Management",
    selected: false,
    route: ["/home/customer-management"],
    icon: CustomerManagementIcon,
  },
  // {
  //   text: "Driver Payout",
  //   selected: false,
  //   route: ["/home/driver/payout"],
  //   icon: "",
  // },
  {
    text: "Bulk Operations",
    selected: false,
    route: ["/home/operations"],
    icon: BulkOperationIcon,
  },
  {
    text: "Coupon Management",
    selected: false,
    route: ["/home/coupon"],
    icon: CouponManagementIcon,
    children: [
      {
        text: "Grievance Coupon",
        route: "/home/coupon/grievance",
      },
      {
        text: "Referral Coupon",
        route: "/home/coupon/referral",
      },
    ],
  },
  {
    text: "Subscriptions",
    selected: false,
    route: ["/home/subscriptions", "/home/subscriptions/subId/details"],
    icon: SubscriptionIcon,
  },
  {
    text: "Saved Addresses",
    selected: false,
    route: ["/home/addresses"],
    icon: SavedAddressIcon,
  },
];
