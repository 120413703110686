import * as React from "react";
import { useLocation, useNavigate, Outlet, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import logo from "../assets/logo.svg";
import { LogoutButton } from "../auth/UmsController";
import {
  getFirstLetter,
  authorizatedRoutes,
  getFromLocalStorage,
} from "../utils/helpers";
import { drawerConfig } from "../config/drawerConfig";
import { ChevronUpIcon } from "../assets/icons/ChevronUpIcon";
import { ChevronDownIcon } from "../assets/icons/ChevronDownIcon";

export const DrawerContainer = (props) => {
  const isAuthenticated =
    useSelector((state) => state.auth.userLoggedIn) ||
    getFromLocalStorage("userLoggedIn");
  const user = useSelector((state) => state.auth?.userDetails);
  const clientId = useSelector((state) => state.auth?.clientId);
  const [isLoading, setIsLoading] = React.useState(true);
  React.useEffect(() => {
    if (user && clientId !== undefined) {
      setIsLoading(false);
    }
  }, [user, clientId]);
  return (
    <>
      <main className="tw-flex tw-items-start tw-h-[100dvh] tw-overflow-hidden">
        <Sidebar
          items={drawerConfig}
          isLoading={isLoading}
          user={user}
          clientId={clientId}
          isAuthenticated={isAuthenticated}
        />
        {props.children}
        <Outlet />
      </main>
    </>
  );
};

// New Sidebar
const Sidebar = ({ items, user, clientId, isLoading, isAuthenticated }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [expandedItem, setExpandedItem] = React.useState(null);

  const handleSelect = (item) => {
    if (item.children) {
      setExpandedItem(expandedItem === item.text ? null : item.text);
    } else {
      navigate(item.route[0]);
    }
  };

  const handleChildSelect = (child) => {
    navigate(child.route);
  };

  const isItemSelected = (item) => {
    return location.pathname.startsWith(item.route[0]);
  };

  const isChildSelected = (child) => {
    return location.pathname === child.route;
  };
  const hasClientChildren = (item) => {
    return item.children?.some((child) =>
      authorizatedRoutes(child.route, clientId, isAuthenticated)
    );
  };
  return (
    <>
      <aside className="tw-min-w-[250px] tw-max-w-[250px] tw-w-full tw-flex tw-flex-col tw-justify-between tw-h-full tw-relative">
        <div>
          {/* Sidebar Logo */}
          <div
            className="tw-p-5 tw-h-20 tw-flex tw-items-center"
            style={{ borderBottom: "1px solid #E8E8E8" }}
          >
            <Link className="tw-w-28" to={"/home/trip"}>
              <img src={logo} alt="Pathfinder" className="tw-w-full" />
            </Link>
          </div>

          {/* Sidebar Items */}
          {!isLoading && (
            <div className="tw-overflow-hidden tw-select-none tw-text-ellipsis">
              {items
                .filter(
                  (item) =>
                    authorizatedRoutes(
                      item.route[0],
                      clientId,
                      isAuthenticated
                    ) || hasClientChildren(item) // Filter parent items based on their children
                )
                .map((item, index) => (
                  <div key={index}>
                    <div
                      className={`${
                        item.children &&
                        "tw-flex tw-items-center tw-justify-between"
                      } tw-overflow-hidden tw-text-ellipsis tw-px-5 tw-cursor-pointer tw-py-3 ${
                        isItemSelected(item)
                          ? "tw-bg-primary tw-text-white"
                          : "hover:tw-bg-black/5"
                      }`}
                      onClick={() => handleSelect(item)}
                    >
                      <div className="tw-flex tw-items-center tw-gap-2">
                        <span className="tw-min-w-5 tw-max-w-5">
                          <item.icon
                            fill={isItemSelected(item) ? "white" : "#262626"}
                          />
                        </span>
                        <span
                          title={item.text}
                          className="tw-text-sm tw-whitespace-nowrap"
                        >
                          {item.text}
                        </span>
                      </div>
                      {item.children && (
                        <>
                          {expandedItem !== item.text ? (
                            <ChevronDownIcon
                              fill={isItemSelected(item) ? "white" : "black"}
                            />
                          ) : (
                            <ChevronUpIcon
                              fill={isItemSelected(item) ? "white" : "black"}
                            />
                          )}
                        </>
                      )}
                    </div>

                    {/* Render child items only if expanded and allowed */}
                    {expandedItem === item.text &&
                      item.children &&
                      item.children
                        .filter(
                          (child) =>
                            !clientId ||
                            authorizatedRoutes(
                              child.route,
                              clientId,
                              isAuthenticated
                            )
                        ) // Filter child items
                        .map((child, childIndex) => (
                          <div
                            key={childIndex}
                            className={`tw-pl-12 tw-pr-5 tw-py-2 tw-cursor-pointer tw-text-sm ${
                              isChildSelected(child)
                                ? "tw-bg-primary/20 tw-text-primary"
                                : "hover:tw-bg-black/5"
                            }`}
                            onClick={() => handleChildSelect(child)}
                          >
                            <span title={child.text} className="tw-text-sm">{child.text}</span>
                          </div>
                        ))}
                  </div>
                ))}
            </div>
          )}
        </div>

        {/* User Info and Sign Out */}
        {!isLoading && (
          <div
            className="tw-flex tw-p-5 tw-items-center tw-gap-2"
            style={{ borderTop: "1px solid #E8E8E8" }}
          >
            <div className="tw-w-14 tw-min-w-14 tw-max-w-14 tw-rounded-full tw-text-base tw-grid tw-place-content-center tw-bg-gray-300 tw-aspect-square">
              <span>
                {getFirstLetter(user?.firstName)}
                {getFirstLetter(user?.lastName)}
              </span>
            </div>
            <div className="tw-w-full tw-overflow-hidden">
              <span
                title={user?.email}
                className="tw-text-sm tw-mb-1 tw-cursor-default tw-text-ellipsis tw-whitespace-nowrap tw-overflow-hidden tw-block"
              >
                {user?.email}
              </span>

              <LogoutButton />
            </div>
          </div>
        )}
      </aside>
    </>
  );
};
