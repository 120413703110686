import React from 'react'
import CardLayout from '../components/common/CardLayout';
import DriverPayoutHome from '../components/driver-management/driver-payout/DriverPayoutHome';

const DriverPayoutManagement = () => {
  return (
    <CardLayout title="Driver Payout">
        <DriverPayoutHome />
    </CardLayout>
  )
}

export default DriverPayoutManagement;