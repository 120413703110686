import React from "react";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { useColumns } from "./useColumns";

export default function AssignDriverTable({
  driverProfiles,
  setSelectedDriverController,
  selectedDriverController,
  fitLoading,
  tripId,
}) {
  const {columns=[]} = useColumns({ fitLoading });

  const handleRowClick = (e) => {
    setSelectedDriverController(e.dataItem);
  };

  const rowRender = (row, rowProps) => {
    const isSelected =
      rowProps.dataItem.driverId === selectedDriverController?.driverId;

    const rowClass = isSelected ? "selected-row" : "";

    return React.cloneElement(
      row,
      {
        className: `${row.props.className} ${rowClass}`, // Add the class to the existing ones
      },
      row.props.children
    );
  };

  return (
    <div className="tw-mt-10">
      <Grid
        sortable={false}
        onRowClick={handleRowClick}
        data={driverProfiles}
        rowRender={rowRender}
      >
        {columns.map((column) => (
          <Column
            key={column.id}
            field={column.field}
            title={column.title}
            width={column.width}
            cell={column.cell}
          />
        ))}
      </Grid>
    </div>
  );
}
