import React, { useEffect } from "react";
import { GoogleMap } from "@react-google-maps/api";
import LoadingSpinner from "../common/LoadingSpinner";

const GoogleMapsWrapper = ({
  children,
  containerStyle,
  options,
  center,
  zoom,
  onLoad,
  onBoundsChanged,
  onDragEnd,
  onZoomChanged,
  mapRef,
  onTilesLoaded,
}) => {
  const mapIsLoaded = true;
  return mapIsLoaded ? (
    <>
      <div style={{ position: "relative", height: "100%" }}>
        <GoogleMap
          mapContainerStyle={containerStyle}
          options={options}
          center={center}
          zoom={zoom}
          onLoad={onLoad}
          onTilesLoaded={onTilesLoaded}
          onBoundsChanged={onBoundsChanged}
          onDragEnd={onDragEnd}
          onZoomChanged={onZoomChanged}
        >
          {children}
        </GoogleMap>
      </div>
    </>
  ) : (
    <div className="tw-grid tw-place-content-center tw-h-full">
      <LoadingSpinner />
    </div>
  );
};

export default GoogleMapsWrapper;
