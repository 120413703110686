import React, { useEffect, useState } from "react";
import { APIConfig } from "../../../utils/constants/api.constants";
import { useParams } from "react-router-dom";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import {
  initialFilter,
  isNotEmptyString,
  showErrorMessage,
  showToastMessage,
} from "../../../utils/helpers";
import GridLoader from "../../common/GridLoader";
import axiosService from "../../../init/axios";

const DriverAccountSelectedPeriodTable = ({
  currentMonthStart,
  currentMonthTillNow,
  from,
  to,
  updateSettlementSummary,
}) => {
  const { driverId } = useParams();
  
  const baseUrl =
    process.env.REACT_APP_DRIVER_BASE_URL +
    APIConfig.driverManagement.getDriverAccountDetails(driverId);
  const [driverAccountStatement, setDriverAccountStatement] = useState({
    data: [],
    total: 0,
  });

  const [dataState, setDataState] = React.useState({
    take: 10,
    skip: 0,
    otherParams: {
      from,
      to,
    },
  });

  const [dateChangedOnce, setDateChangedOnce] = useState(false);

  const CommandCell = (props) => (
    <td>
      <div
        onClick={(e) => onInvoiceClick(e, { ...props })}
        style={{ cursor: "pointer", color: "blue", margin: "20px 10px" }}
      >
        {props.dataItem?.invoiceDetails?.fileName}
      </div>
    </td>
  );

  const onInvoiceClick = async (e, props) => {
    if (!props?.dataItem?.invoiceDetails?.filePath) {
      showToastMessage(`Invoice not available.`, false);
    } else {
      await axiosService
        .get(
          process.env.REACT_APP_DRIVER_BASE_URL +
            APIConfig.driverManagement.getDriverDocumentImage +
            "?file-path=" +
            props?.dataItem?.invoiceDetails?.filePath
        )
        .then((data) => {
          if (data?.data) {
            openInvoice(data?.data?.preSignedUrl);
          }
        })
        .catch((error) => {
          showErrorMessage(error);
        });
    }
  };

  const openInvoice = async (filePath) => {
    if (filePath) {
      window.open(filePath);
    }
  };

  const columns = [
    {
      id: "ledgerId",
      field: "ledgerId",
      title: "Ledger ID",
      filterable: false,
      width: 150,
    },
    {
      id: "paymentPurpose",
      field: "paymentPurpose",
      title: "Nature of Transaction",
      filterable: false,
      width: 200,
    },
    {
      id: "inputType",
      field: "inputType",
      title: "Input",
      filterable: false,
      width: 100,
    },
    {
      id: "gatewayCollectionId",
      field: "gatewayCollectionId",
      title: "Gateway Collection Id",
      filterable: false,
      width: 180,
    },
    {
      id: "gatewayPaymentId",
      field: "gatewayPaymentId",
      title: "Gateway Payment Id",
      filterable: false,
      width: 150,
    },
    {
      id: "tripId",
      field: "tripId",
      title: "Trip Id",
      filterable: false,
      width: 100,
    },
    {
      id: "totalBookingAmount",
      field: "totalBookingAmount",
      title: "Booking Amount (Total)",
      filterable: false,
      width: 100,
    },
    {
      id: "customerPayableAmount",
      field: "customerPayableAmount",
      title: "Net Customer Amount",
      filterable: false,
      width: 100,
    },
    {
      id: "driverShare",
      field: "driverShare",
      title: "Driver Share",
      filterable: false,
      width: 80,
    },
    {
      id: "autopilotShare",
      field: "autopilotShare",
      title: "Autopilot Share",
      filterable: false,
      width: 80,
    },
    {
      id: "debit",
      field: "debit",
      title: "Debit",
      filterable: false,
      width: 80,
    },
    {
      id: "credit",
      field: "credit",
      title: "Credit",
      filterable: false,
      width: 80,
    },
    {
      id: "closingBalance",
      field: "closingBalance",
      title: "Balance",
      filterable: false,
      width: 100,
    },
    {
      id: "createdBy",
      field: "createdBy",
      title: "Created By",
      filterable: false,
      width: 200,
    },
    {
      id: "createdAt",
      field: "createdAt",
      title: "Created At",
      filterable: false,
      width: 150,
    },
    {
      id: "invoiceDetails",
      title: "Invoice",
      cell: CommandCell,
      width: 100,
    },
  ];

  useEffect(() => {
    if (
      String(currentMonthStart) !== String(from) ||
      String(currentMonthTillNow) !== String(to)
    ) {
      setDataState({ ...dataState, otherParams: { from, to } });
      if (!dateChangedOnce) {
        setDateChangedOnce(true);
      }
    }
    if (
      String(currentMonthStart) === String(from) &&
      String(currentMonthTillNow) === String(to) &&
      dateChangedOnce
    ) {
      setDataState({ ...dataState, otherParams: { from, to } });
    }
  }, [from, to]);

  const dataStateChange = (e) => {
    setDataState({ ...e.dataState, otherParams: { from, to } });
  };

  const dataReceived = (dataObj) => {
    updateSettlementSummary(dataObj.data);
    const arr = dataObj.data?.data.map((item) => {
      //Getting labels from enum
      let credit, debit, gatewayCollectionId, gatewayPaymentId;
      if (item?.settlementTxnType === "DEBIT") {
        credit = 0;
        debit = item?.settlementAmount;
      }
      if (item?.settlementTxnType === "CREDIT") {
        credit = item?.settlementAmount;
        debit = 0;
      }

      // if((item?.paymentPurpose === 'TRIP' && item?.amountCollectedBy === 'AUTOPILOT') || ['BGV_FEES','OUTSTANDING_BALANCE_PAYMENT'].includes(item?.paymentPurpose)){
      //     gatewayCollectionId = item?.referenceId;
      // }

      if (item?.additionalDetails?.tripId) {
        item.tripId = item?.additionalDetails?.tripId;
      }

      if (item?.additionalDetails?.orderId) {
        gatewayCollectionId = item?.additionalDetails?.orderId;
      }

      if (item?.paymentPurpose === "PAYOUT") {
        gatewayPaymentId = item?.referenceId;
      }

      if (item?.paymentPurpose === "TRIP") {
        item.paymentPurpose = `TRIP - ${item?.amountCollectedBy} COLLECT`;
      } else if (isNotEmptyString(item.remarks)) {
        item.paymentPurpose += ` (${item.remarks})`;
      }

      const updatedDetails = {
        ...item,
        credit: credit,
        debit: debit,
        gatewayPaymentId,
        gatewayCollectionId,
      };
      return updatedDetails;
    });
    setDriverAccountStatement({ ...dataObj, data: arr });
  };

  return (
    <div className="driver-accounts-table">
      <Grid
        filter={initialFilter}
        filterable={false}
        sortable={false}
        pageable={true}
        {...dataState}
        data={driverAccountStatement}
        onDataStateChange={dataStateChange}
      >
        {columns.map((column) => (
          <Column
            key={column?.id}
            field={column?.field}
            cell={column?.cell}
            title={column?.title}
            width={column?.width}
          />
        ))}
      </Grid>
      <GridLoader
        baseUrl={baseUrl}
        columns={columns}
        dataState={dataState}
        onDataReceived={dataReceived}
      />
    </div>
  );
};

export default DriverAccountSelectedPeriodTable;
