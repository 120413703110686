import React from "react";

function TagsList(props) {
    const tripId = props?.tripDetails?.tripId;
    const tripType = props?.tripDetails?.tripType;
    const isB2B = window.location.href.includes("b2b");
  
    return (
      <div className="tw-flex tw-gap-4 tw-items-center">
        {tripId && (
          <span className="tw-py-1 tw-px-4 tw-bg-blue-50 tw-text-blue-600 tw-text-[12px] tw-rounded-md">
            {tripId}
          </span>
        )}
        {tripType && (
          <span className="tw-py-1 tw-px-4 tw-bg-orange-50 tw-text-orange-600 tw-text-[12px] tw-rounded-md">
            {tripType}
          </span>
        )}
        <span className="tw-py-1 tw-px-4 tw-bg-pink-50 tw-text-pink-600 tw-text-[12px] tw-rounded-md">
          {isB2B ? "B2B" : "B2C"}
        </span>
      </div>
    );
  }

  export default TagsList;