import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logIn, setToken } from "../slices/authSlice";
import {
  getFromLocalStorage,
  saveToLocalStorage,
  showErrorMessage,
} from "../utils/helpers";
import logo from "../assets/logo.svg";
import LoginButton from "../auth/UmsController";
import { APIConfig } from "../utils/constants/api.constants";
import axios from "axios";
import LoadingSpinner from "../components/common/LoadingSpinner";

export const SignIn = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [newUserMessage, setNewUserMessage] = useState(false);
  const redirectUri = encodeURIComponent(
    process.env.REACT_APP_CURRENT_URL + "/login"
  );
  const loginBaseUrl = process.env.REACT_APP_UMS_BASE_URL + APIConfig.ums.login;
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const getAccessToken = (authCode) => {
    if (getFromLocalStorage("accessToken")) return;
    let headers = {
      "x-channel-name": "autopilot",
      client_id: process.env.REACT_APP_UMS_CLIENT_ID,
      client_secret: process.env.REACT_APP_UMS_CLIENT_SECRET,
    };

    axios
      .post(
        loginBaseUrl,
        {
          authorizationCode: authCode,
          identityProvider: "GOOGLE",
          redirectUri: redirectUri,
        },
        {
          headers,
        }
      )
      .then((response) => {
        const jwt = response?.data?.data?.accessToken;
        saveToLocalStorage("accessToken", jwt);
        saveToLocalStorage("userLoggedIn", true);
        saveToLocalStorage("refreshToken", response?.data?.data?.refreshToken);
        saveToLocalStorage("sessionToken", authCode);
        dispatch(logIn());
        dispatch(setToken(jwt));
      })
      .then(() => {
        navigate("/home/trip");
      })
      .catch((error) => {
        showErrorMessage(error);
        navigate("/login");
      });
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const code = params.get("code");
    const newUser = params.get("new-user");
    if (newUser === "true") {
      setNewUserMessage(true);
    }
    if (code && !getFromLocalStorage("accessToken")) {
      setLoading(true);
      getAccessToken(code);
    }
    setLoading(false);
  }, [location]);

  return (
    <div className="tw-grid tw-grid-cols-2 tw-min-h-screen">
      {loading && (
        <div className="tw-top-0 tw-left-0 tw-grid tw-place-content-center tw-text-white tw-absolute tw-h-full tw-w-full tw-bg-black/40 tw-z-10">
          <LoadingSpinner />
          <span className="tw-text-xl tw-font-semibold">
            Loading
            <span className="tw-animate-pulse tw-text-3xl tw-duration-100">
              .
            </span>
            <span className="tw-animate-pulse tw-text-3xl tw-duration-300">
              .
            </span>
            <span className="tw-animate-pulse tw-text-3xl tw-duration-500">
              .
            </span>
          </span>
        </div>
      )}
      <div className="tw-bg-black app-cover"></div>
      <section className="tw-flex tw-items-center tw-justify-center">
        <div className="tw-max-w-md tw-w-full tw-p-5">
          <img src={logo} alt="Pathfinder" className="tw-w-48 tw-block" />
          <h5 className="tw-text-[#848995] tw-mt-2 tw-mb-10 tw-font-normal">
            Sign in to your workplace{" "}
          </h5>
          <LoginButton loading={loading} redirectUri={redirectUri} />

          {newUserMessage && (
            <span className="tw-mt-5 tw-block tw-text-xs tw-bg-red-500/10 tw-text-red-500 tw-rounded-md tw-p-3 tw-text-center">
              Your user account is not set up. Please contact your administrator
              or reach out to support for assistance.
            </span>
          )}
        </div>
      </section>
    </div>
  );
};
