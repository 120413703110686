export function ChevronDownIcon({ fill = "#101010" }) {
    return (
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.6465 5.64586C12.693 5.5994 12.7481 5.56255 12.8088 5.53741C12.8695 5.51227 12.9346 5.49933 13.0003 5.49933C13.066 5.49933 13.131 5.51227 13.1917 5.53741C13.2524 5.56255 13.3076 5.5994 13.354 5.64586C13.4005 5.69231 13.4373 5.74746 13.4625 5.80816C13.4876 5.86885 13.5006 5.93391 13.5006 5.99961C13.5006 6.0653 13.4876 6.13036 13.4625 6.19105C13.4373 6.25175 13.4005 6.3069 13.354 6.35336L8.35403 11.3534C8.30759 11.3998 8.25245 11.4367 8.19175 11.4619C8.13105 11.487 8.06599 11.5 8.00028 11.5C7.93457 11.5 7.86951 11.487 7.80881 11.4619C7.74811 11.4367 7.69296 11.3998 7.64653 11.3534L2.64653 6.35336C2.55271 6.25954 2.5 6.13229 2.5 5.99961C2.5 5.86692 2.55271 5.73968 2.64653 5.64586C2.74035 5.55204 2.8676 5.49933 3.00028 5.49933C3.13296 5.49933 3.26021 5.55204 3.35403 5.64586L8.00028 10.2927L12.6465 5.64586Z"
          fill={fill}
        />
      </svg>
    );
  }