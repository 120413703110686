import React, { useEffect, useState } from "react";
import ModalLayout from "../../../../common/ModalLayout";
import {
  convertTo12HourFormat,
} from "../../../../../utils/helpers";
import axiosService from "../../../../../init/axios";
import { APIConfig } from "../../../../../utils/constants/api.constants";
import LoadingSpinner from "../../../../common/LoadingSpinner";

function CallHistoryAudioModal({ closeModal, selectedRow, handleCallHistoryModal }) {
  const [loading, setLoading] = useState(false);
  const [audioUrl, setAudioUrl] = useState(null);


  const getCallBlob = async (audioSrc) => {
    setLoading(true)
    const baseUrl =
      process.env.REACT_APP_TRIP_BASE_URL +
      APIConfig.tripManagement.getCallHistoryAudio(audioSrc);

    await axiosService.get(baseUrl, undefined, {}, "blob").then((response) => {
      const _audioUrl = URL.createObjectURL(response?.data);
      setAudioUrl(_audioUrl);
    }).finally(()=>{
      setLoading(false);
    });
  };


  useEffect(() => {
    if (selectedRow) {
      getCallBlob(selectedRow?.recordingUrl);
    }
  }, [selectedRow]);
  return (
    <ModalLayout
      title={"Call Recording"}
      onClose={() => {handleCallHistoryModal(null);closeModal();}}
    >
      <div className="tw-w-[320px]">
        {loading ? (
          <div className="d-flex justify-center items-center w-full">
            <LoadingSpinner color="lightgray" height={40} width={40} />
          </div>
        ) : (
          <>
            <p>
              <strong>Call Time:</strong> {convertTo12HourFormat(selectedRow?.callInitiatedAt)}
            </p>
            {audioUrl ? (
              <audio className="tw-block tw-mx-auto" controls>
                <source src={audioUrl} controls />
              </audio>
            ) : (
              <p>Audio not available</p>
            )}
          </>
        )}
      </div>
    </ModalLayout>
  );
}

export default CallHistoryAudioModal;
