export default function SavedAddressIcon({ fill }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="13"
      viewBox="0 0 12 13"
      fill="none"
      style={{ width: "100%", height: "100%" }}
    >
      <path
        d="M9.75 2H2.25C2.05109 2 1.86032 2.07902 1.71967 2.21967C1.57902 2.36032 1.5 2.55109 1.5 2.75V10.25C1.5 10.4489 1.57902 10.6397 1.71967 10.7803C1.86032 10.921 2.05109 11 2.25 11H9.75C9.94891 11 10.1397 10.921 10.2803 10.7803C10.421 10.6397 10.5 10.4489 10.5 10.25V2.75C10.5 2.55109 10.421 2.36032 10.2803 2.21967C10.1397 2.07902 9.94891 2 9.75 2ZM9 5.375H9.75V7.625H9V5.375ZM9.75 4.625H9V2.75H9.75V4.625ZM2.25 2.75H8.25V10.25H2.25V2.75ZM9.75 10.25H9V8.375H9.75V10.25ZM7.11328 8.28125C6.99193 7.82816 6.7042 7.43748 6.3075 7.18719C6.51817 6.97777 6.66192 6.71051 6.72052 6.41929C6.77912 6.12808 6.74994 5.82602 6.63667 5.55141C6.5234 5.2768 6.33115 5.04201 6.08428 4.87679C5.83742 4.71158 5.54705 4.62338 5.25 4.62338C4.95295 4.62338 4.66258 4.71158 4.41572 4.87679C4.16885 5.04201 3.9766 5.2768 3.86333 5.55141C3.75006 5.82602 3.72088 6.12808 3.77948 6.41929C3.83808 6.71051 3.98183 6.97777 4.1925 7.18719C3.79606 7.43776 3.50841 7.82832 3.38672 8.28125C3.36185 8.3776 3.37628 8.47988 3.42683 8.56559C3.47738 8.6513 3.5599 8.71342 3.65625 8.73828C3.7526 8.76315 3.85488 8.74872 3.94059 8.69817C4.0263 8.64762 4.08842 8.5651 4.11328 8.46875C4.23703 7.98828 4.72547 7.625 5.25 7.625C5.77453 7.625 6.26344 7.98734 6.38672 8.46875C6.41158 8.5651 6.4737 8.64762 6.55941 8.69817C6.64512 8.74872 6.7474 8.76315 6.84375 8.73828C6.9401 8.71342 7.02262 8.6513 7.07317 8.56559C7.12372 8.47988 7.13815 8.3776 7.11328 8.28125ZM4.5 6.125C4.5 5.97666 4.54399 5.83166 4.6264 5.70832C4.70881 5.58499 4.82594 5.48886 4.96299 5.43209C5.10003 5.37532 5.25083 5.36047 5.39632 5.38941C5.5418 5.41835 5.67544 5.48978 5.78033 5.59467C5.88522 5.69956 5.95665 5.8332 5.98559 5.97868C6.01453 6.12417 5.99968 6.27497 5.94291 6.41201C5.88614 6.54906 5.79001 6.66619 5.66668 6.7486C5.54334 6.83101 5.39834 6.875 5.25 6.875C5.05109 6.875 4.86032 6.79598 4.71967 6.65533C4.57902 6.51468 4.5 6.32391 4.5 6.125Z"
        fill={fill}
      />
    </svg>
  );
}
