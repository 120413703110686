import React, { useEffect, useState } from "react";
import CardLayout from "../common/CardLayout";
import { APIConfig } from "../../utils/constants/api.constants";
import { useNavigate, useParams } from "react-router-dom";
import {
  addHoursToTimestamp,
  calculateTripDuration,
  formatDateTime,
  getLabelByValue,
  getObjectByKey,
  showErrorMessage,
  timeConverter,
} from "../../utils/helpers";
import axiosService from "../../init/axios";
import LoadingSpinner from "../common/LoadingSpinner";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { SubscriptionConfig } from "../../config/subscriptionConfig";
import { Grouping } from "./SubscriptionDetails";
import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout";
import { format, addDays } from "date-fns";
import BackArrowIcon from "../../assets/icons/BackArrowIcon";

export default function SubscriptionCycleDetails() {
  const { subId, cycleId } = useParams();
  const navigate = useNavigate();

  const baseUrl =
    process.env.REACT_APP_TRIP_BASE_URL +
    APIConfig.subscription.getSubscriptionCycleDetails(subId, cycleId);

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const getSubscriptionCycleData = async () => {
    setLoading(true);
    await axiosService
      .get(baseUrl)
      .then((data) => {
        setData(data.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        showErrorMessage(error);
      });
  };

  useEffect(() => {
    if (subId && cycleId) {
      getSubscriptionCycleData();
    }
  }, [subId, cycleId]);

  const billSummary =
    data?.subscription?.cycles &&
    getObjectByKey(data?.subscription?.cycles, "id", cycleId)?.billSummary;
  return (
    <CardLayout title={"Subscriptions"}>
      {loading ? (
        <div className="tw-min-h-[80dvh] tw-grid tw-place-content-center">
          <LoadingSpinner color="#d5d5d5" />
        </div>
      ) : (
        <>
          <section className="tw-space-y-10">
            <div className="tw-flex tw-items-center tw-justify-between">
              <div className="tw-flex tw-gap-5 tw-items-center">
                <span
                  className="tw-cursor-pointer"
                  onClick={() =>
                    navigate(`/home/subscriptions/${subId}/details`)
                  }
                >
                  <BackArrowIcon />
                </span>
                <h4 className="tw-text-lg tw-capitalize tw-mt-2">
                  Cycle:{" "}
                  {getObjectByKey(
                    data?.subscription?.cycles,
                    "id",
                    cycleId
                  )?.name.toLowerCase() || ""}
                </h4>
                {/* <span className={`status-pill ${data?.subscription?.status}`}>
                  {getLabelByValue(
                    data?.subscription?.status,
                    SubscriptionConfig.status
                  ) || "N.A."}
                </span> */}
              </div>
            </div>

            <div className="tw-grid tw-grid-cols-2 tw-gap-10">
              <Grouping
                title="Customer Details"
                className={"columns-two"}
                details={[
                  {
                    label: "Customer Name",
                    value: data?.subscription?.customerName || "N.A.",
                  },
                  {
                    label: "Customer Number",
                    value: data?.subscription?.customerMobile || "N.A.",
                  },
                ]}
              />
              <Grouping
                title="Driver Details"
                className={"columns-two"}
                details={[
                  {
                    label: "Driver Name",
                    value: data?.subscription?.driverName || "N.A.",
                  },
                  {
                    label: "Driver Number",
                    value: data?.subscription?.driverMobile || "N.A.",
                  },
                ]}
              />
            </div>
            <div>
              <Grouping
                title="Package details"
                className={"columns-five"}
                details={[
                  {
                    label: "Package days:",
                    value: data?.subscription?.noOfDays || "N.A.",
                  },
                  {
                    label: "Package hours:",
                    value: data?.subscription?.noOfHours || "N.A.",
                  },
                  {
                    label: "Off days",
                    value:
                      data?.serviceDaysTrips?.filter(
                        (item) => item?.stage === "DRIVER_NOT_AVAILABLE"
                      )?.length || "N.A.",
                  },
                  {
                    label: "Day start time:",
                    value:
                      formatDateTime(data?.subscription?.startTime) || "N.A.",
                  },
                  {
                    label: "Day end time:",
                    value:
                      formatDateTime(
                        addHoursToTimestamp(
                          data?.subscription?.startTime,
                          data?.subscription?.noOfHours
                        )
                      ) || "N.A.",
                  },
                ]}
              />
            </div>
            <div className="custom-template">
              {/* //////// */}
              {/* billSummary */}

              <div className="custom-template">
                <table className="k-grid k-widget">
                  <thead className="k-grid-header">
                    <tr className="k-grid-header-row">
                      <th className="k-header tw-w-full">Pricing Details</th>
                      <th className="k-header tw-min-w-80">Base Value</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="k-master-row">
                      <td className="k-cell tw-w-full">Base Price</td>
                      <td className="k-cell tw-min-w-80">
                        {billSummary?.basePrice || 0}
                      </td>
                    </tr>
                    <tr className="k-master-row">
                      <td className="k-cell tw-w-full">Base Night Charges</td>
                      <td className="k-cell tw-min-w-80">
                        {billSummary?.baseNightCharge || 0}
                      </td>
                    </tr>
                    <tr className="k-master-row">
                      <td className="k-cell tw-w-full">Discount</td>
                      <td className="k-cell tw-min-w-80">
                        {billSummary?.discount || 0}
                      </td>
                    </tr>
                    <tr className="k-master-row custom-border-primary-bottom">
                      <td className="k-cell tw-w-full">GST</td>
                      <td className="k-cell tw-min-w-80">
                        {billSummary?.gst || 0}
                      </td>
                    </tr>
                    <tr className="k-master-row">
                      <td className="k-cell tw-w-full tw-text-base">
                        <b>Final Price</b>
                      </td>
                      <td className="k-cell tw-min-w-80 tw-text-base">
                        <b>₹ {billSummary?.finalPrice || 0}</b>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              {/* //////// */}
            </div>
            <PanelBar expandMode={"single"}>
              <PanelBarItem title="Driver history">
                {/* driver history */}
                <div className="custom-template">
                  <Grid data={data?.driverHistory}>
                    <Column
                      width={250}
                      title="Driver Name"
                      field="driverName"
                    />
                    <Column
                      width={250}
                      title="Driver Number"
                      field="driverMobile"
                    />
                    <Column width={250} title="Driver Id" field="driverId" />
                    {/* <Column width={200}
                  title="Status"
                  field="status"
                  cell={(e) => {
                    return (
                      <td>
                        {getLabelByValue(
                          e.dataItem?.status,
                          SubscriptionConfig.cycleStatus
                        ) || "N.A."}
                      </td>
                    );
                  }}
                /> */}
                    <Column
                      width={350}
                      field="startTime"
                      title="Changed On"
                      cell={(e) => (
                        <td>{timeConverter(e?.dataItem?.updatedAt)}</td>
                      )}
                    />
                    {/* <Column width={200}
                  field="endTime"
                  title="Cycle End Date"
                  cell={(e) => <td>{timeConverter(e?.dataItem?.endTime)}</td>}
                /> */}
                    <Column width={350} field="reason" title="Reason" />
                  </Grid>
                </div>
              </PanelBarItem>
              <PanelBarItem title="Payment history">
                <div className="custom-template">
                  <Grid
                    data={data?.paymentHistory?.filter(
                      (item) => item.basePrice !== null
                    )}
                  >
                    <Column title="Payment Id" field="id" />
                    <Column title="Charge Type" field="chargeType" />
                    <Column title="Status" field="status" />
                    <Column
                      title="Trip Date"
                      field="tripDate"
                      cell={(e) => {
                        return (
                          <td>
                            {timeConverter(e.dataItem?.tripDate) || "N.A."}
                          </td>
                        );
                      }}
                    />
                    <Column field="basePrice" title="Base Price" />
                  </Grid>
                </div>
              </PanelBarItem>
            </PanelBar>

            <CalendarView
              data={data?.serviceDaysTrips}
              handleDateClicked={(e) =>
                navigate(
                  `/home/subscriptions/${subId}/cycle/${cycleId}/day/${e}/details`
                )
              }
            />
          </section>
        </>
      )}
    </CardLayout>
  );
}
const CalendarView = ({ data, handleDateClicked }) => {
  if (!data) return;
  const currentDate = new Date();
  return (
    <div>
      {/* item.expectedTripStartTime */}
      <h2 className="tw-text-base tw-font-semibold">Service days</h2>
      <div className="tw-grid tw-grid-cols-7 tw-gap-1 tw-mt-5">
        {data.map((item, index) => {
          const isMonthChange =
            index === 0 || format(item?.expectedTripStartTime, "d") === "1";

          const isToday =
            format(item?.expectedTripStartTime, "yyyy-MM-dd") ===
            format(currentDate, "yyyy-MM-dd");
          return (
            <div
              onClick={() => handleDateClicked(item?.tripId)}
              key={item?.tripId}
              className={`custom-outline tw-rounded-md tw-aspect-square tw-cursor-pointer tw-duration-200 tw-p-5 tw-flex tw-justify-between tw-flex-col ${
                isToday
                  ? " tw-bg-primary/10"
                  : " hover:tw-bg-gray-100 tw-bg-gray-50/80"
              }`}
            >
              {isMonthChange && (
                <h5 className="tw-font-medium tw-text-base">
                  {format(item?.expectedTripStartTime, "d MMM")}
                </h5>
              )}
              {!isMonthChange && (
                <h5 className="tw-text-base tw-font-normal">
                  {format(item?.expectedTripStartTime, "d")}
                </h5>
              )}
              <div className="tw-text-sm">
                <span className="tw-block tw-font-medium">{item?.tripId}</span>
                <span className="tw-block tw-mb-3 tw-mt-1">
                  {item?.actualTripStartTime &&
                    calculateTripDuration(
                      item?.actualTripStartTime,
                      item?.actualTripEndTime
                    )}
                </span>
                {/*  */}
                {item?.stage === "TRIP_COMPLETED" && (
                  <div className="tw-px-4 tw-py-1 tw-text-[#14561B] tw-text-center tw-text-sm tw-rounded tw-bg-[#D5FDD9]">
                    Completed
                  </div>
                )}
                {item?.stage === "DRIVER_NOT_AVAILABLE" && (
                  <div className="tw-px-4 tw-py-1 tw-text-[#184C4F] tw-text-center tw-text-sm tw-rounded tw-bg-[#D5FBFD]">
                    Day off
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
