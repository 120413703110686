import { useEffect, useRef } from "react";
import { formatAddress } from "../helpers";
import AddressBookIcon from "../../../assets/addressBook.svg";

export const SavedAddressModal = ({ savedAddresses, setAddress, pickup }) => {
    const modalRef = useRef(null);
    const triggerRef = useRef(null);
  
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (
          modalRef.current &&
          !modalRef.current.contains(event.target) &&
          !triggerRef.current.contains(event.target)
        ) {
          modalRef.current.style.display = "none";
        }
      };
      document.addEventListener("mousedown", handleClickOutside);
  
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, []);
  
    const handleDropdownToggle = () => {
      if (
        modalRef.current.style.display === "none" ||
        modalRef.current.style.display === ""
      ) {
        modalRef.current.style.display = "block";
      } else {
        modalRef.current.style.display = "none";
      }
    };
    const handleSelectedAddress = (item) => {
      setAddress((prev) => ({
        ...prev,
        ...(pickup
          ? { pickup: { ...formatAddress(item), savedAddress: true } }
          : { drop: { ...formatAddress(item), savedAddress: true } }),
      }));
  
      handleDropdownToggle();
    };
    if (savedAddresses?.length <= 0) return;
    return (
      <>
        <img
          className="tw-w-6 tw-h-6 tw-cursor-pointer"
          src={AddressBookIcon}
          alt="address"
          ref={triggerRef}
          onClick={handleDropdownToggle}
        />
  
        <div
          ref={modalRef}
          style={{ display: "none" }}
          className="custom-border-primary tw-bg-white tw-rounded-md tw-shadow-xl tw-absolute tw-w-full tw-overflow-y-auto tw-top-9 tw-z-[1] tw-left-0 tw-p-3 tw-min-h-20 tw-max-h-80 tw-space-y-2"
        >
          {savedAddresses.map((item, index) => (
            <div
              key={index}
              onClick={() => handleSelectedAddress(item)}
              style={{
                borderRadius: "4px",
                padding: "8px 12px",
              }}
              className="tw-text-sm custom-border-primary tw-space-y-2 hover:tw-bg-black/5 tw-cursor-pointer tw-bg-white"
            >
              {item?.branchName && (
                <span className="tw-block tw-font-semibold">
                  {item?.branchName}
                </span>
              )}
              <span className="tw-block">{item.formattedAddress}</span>
            </div>
          ))}
        </div>
      </>
    );
  };