import AddressBookIcon from "../../../assets/addressBook.svg";

export const LocationInputLoading = () => {
    return (
      <>
        <input
          placeholder="Loading..."
          disabled
          style={{ width: "100%" }}
          type="text"
          className="tw-cursor-wait tw-px-2 tw-animate-pulse tw-py-1.5 custom-border-primary tw-rounded-md tw-text-sm"
        />
        <img
          className="tw-w-6 tw-h-6 tw-cursor-wait tw-animate-pulse"
          src={AddressBookIcon}
          alt="address"
        />
      </>
    );
  };