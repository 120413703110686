import { configureStore } from "@reduxjs/toolkit";
import authSlice from "../slices/authSlice";
import regionSlice from "../slices/regionSlice";
import componentStatesSlice from "../slices/componentStatesSlice";

const store = configureStore({
  reducer: {
    auth: authSlice,
    region: regionSlice,
    component: componentStatesSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});

export default store;
