import { Button } from "@progress/kendo-react-buttons";
import React from "react";
import { convertEnumToLabel } from "../../utils/helpers";
import { FormMultiSelect } from "../../utils/forms/FormComponents";

const FilterMultiSelectDropDown = (props) => {
  const onChange = (event) => {
    const modifiedValue =
      event?.value?.length > 0
        ? event?.value
            ?.reduce((acc, curr) => (acc = acc + curr?.value + ","), "")
            .slice(0, -1)
        : "";
    props.onChange({
      value: modifiedValue,
      operator: "eq",
      syntheticEvent: event.syntheticEvent,
    });
  };

  const onClearButtonClick = (event) => {
    event.preventDefault();
    props.onChange({
      value: "",
      operator: "eq",
      syntheticEvent: event,
    });
  };

  return (
    <div className="k-filtercell">
      <FormMultiSelect
        {...props}
        value={
          props?.value?.length > 0
            ? props?.value
                ?.split(",")
                .map((item) => ({
                  label: convertEnumToLabel(item),
                  value: item,
                }))
            : []
        }
        data={props.data}
        style={{ width: 170, height: "auto" }}
        textField="label"
        dataItemKey="value"
        filterable={false}
        onChange={onChange}
      />
      <Button
        title="Clear"
        disabled={props?.value?.length === 0}
        onClick={onClearButtonClick}
        icon="filter-clear"
      />
    </div>
  );
};

export default FilterMultiSelectDropDown;
