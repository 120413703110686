import React from "react";

 function RadioTabs({ options = [], value, onValueChange }) {
    return (
      <div className="tw-flex tw-items-center tw-gap-4 tw-p-1 custom-border-primary tw-rounded-md tw-flex-wrap">
        {options.map((item, index) => {
          const selected = value === item?.value;
          return (
            <div
              key={index}
              style={{ flex: 1, textAlign: "center" }}
              onClick={() => onValueChange(item?.value)}
              className={`tw-text-sm tw-py-1 tw-px-3 tw-cursor-pointer tw-rounded-md ${
                selected && "tw-bg-[#00000014]"
              }`}
            >
              {item?.text}
            </div>
          );
        })}
      </div>
    );
  }

  export default RadioTabs;
  