import React, { useEffect, useState } from "react";
import { APIConfig } from "../../../../../utils/constants/api.constants";
import { Button } from "@progress/kendo-react-buttons";
import {
  getLabelFromEnum,
  replaceNullValues,
  showErrorMessage,
  showToastMessage,
  stringToSentenceCase,
} from "../../../../../utils/helpers";
import GridLoader from "../../../../common/GridLoader";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import axiosService from "../../../../../init/axios";

const ReserveDrivers = ({
  tripId,
  stage,
  lat,
  lng,
  tripBookingMode,
  refreshTripDetails,
  setRefreshTripDetails,
  refreshTripCalled,
  setRefreshTripCalled,
}) => {
  const latLngObj = {
    lat: lat,
    lng: lng,
  };
  const requiredStageValues = ["SEARCHING_FOR_DRIVER"];
  const baseUrl =
    process.env.REACT_APP_DRIVER_BASE_URL +
    APIConfig.tripManagement.getReservedDrivers;
  const [showTable, setShowTable] = useState(false);
  const [loading, setLoading] = useState(false);
  // const [refreshTable,setRefreshTable] = useState(false);

  const [reservedDrivers, setReservedDrivers] = useState({
    data: [],
    total: 0,
  });
  const [dataState, setDataState] = useState({
    take: 10,
    skip: 0,
  });

  const CommandCell = (props) => (
    <td>
      {props.dataItem?.driverStatus === "Online" ? (
        <Button
          themeColor={"primary"}
          disabled={loading}
          onClick={(e) => onAssignDriver(e, { ...props })}
        >
          Assign
        </Button>
      ) : (
        <span></span>
      )}
    </td>
  );

  // useEffect(()=>{
  //     setDataState({...dataState});
  //     // setRefreshTable((prevState)=>!prevState?true:true);
  // },[refreshTable]);

  useEffect(() => {
    if (
      tripBookingMode === "SCHEDULED" &&
      requiredStageValues.includes(stage)
    ) {
      setShowTable(true);
    }
  }, []);

  const columns = [
    {
      id: "driverId",
      field: "driverId",
      title: "Driver Id",
      width: 150,
    },
    {
      id: "driverName",
      field: "driverName",
      title: "Driver Name",
      width: 300,
    },
    {
      id: "displacement",
      field: "displacement",
      title: "Displacement from origin",
      width: 200,
    },
    {
      id: "location",
      field: "location",
      title: "Last Location",
      width: 400,
    },
    {
      id: "driverStatus",
      field: "driverStatus",
      title: "Driver Status",
      width: 200,
    },
    {
      id: "updateButton",
      cell: CommandCell,
      title: "Assign",
      width: 200,
    },
  ];

  const dataStateChange = (e) => {
    setDataState(e.dataState);
  };
  const dataReceived = (dataObj) => {
    const updatedDrivers = dataObj.data?.reservedDrivers?.map((driver) => {
      const updatedDriverDetails = getLabelFromEnum(
        ["driverStatus"],
        driver,
        "driver"
      );
      let driverObj = {};
      if (driver?.driverName) {
        driverObj = {
          ...driverObj,
          driverName: stringToSentenceCase(driver?.driverName),
        };
      } else {
        driverObj = { ...driverObj, driverName: "" };
      }
      return {
        ...updatedDriverDetails,
        ...driverObj,
        location: driver.lat + ", " + driver.lng,
      };
    });
    setReservedDrivers({ ...dataObj, data: updatedDrivers });
  };

  const onAssignDriver = async (e, props) => {
    setLoading(true);
    await axiosService
      .put(
        process.env.REACT_APP_TRIP_BASE_URL +
          APIConfig.tripManagement.assignReservedDriver(tripId),
        JSON.stringify({ tripId: tripId, driverId: props?.dataItem?.driverId })
      )
      .then((res) => {
        setLoading(false);
        showToastMessage("Driver assigned successfully");
        if (!refreshTripCalled) {
          setRefreshTripCalled(true);
        }
        setRefreshTripDetails(!refreshTripDetails);
      })
      .catch((error) => {
        setLoading(false);
        showErrorMessage(error);
      });
    // setRefreshTable(!refreshTable);
  };

  return (
    <div className="reserved-drivers-table">
      {showTable ? (
        <>
          <Grid
            filterable={false}
            sortable={false}
            pageable={false}
            {...dataState}
            data={reservedDrivers}
            onDataStateChange={dataStateChange}
          >
            {columns.map((column) => (
              <Column
                key={column?.id}
                field={column?.field}
                title={column?.title}
                cell={column?.cell}
                width={column?.width}
              />
            ))}
          </Grid>
          <GridLoader
            baseUrl={baseUrl}
            columns={columns}
            dataState={dataState}
            onDataReceived={dataReceived}
            method="put"
            body={JSON.stringify({ ...latLngObj })}
          />
        </>
      ) : (
        <div>
          Not applicable as trip booking mode is:{" "}
          <b>
            {replaceNullValues(
              getLabelFromEnum(
                ["tripBookingMode"],
                { tripBookingMode: tripBookingMode },
                "trip"
              ).tripBookingMode
            )}
          </b>{" "}
          and trip stage is:{" "}
          <b>
            {replaceNullValues(
              getLabelFromEnum(["stage"], { stage: stage }, "trip").stage
            )}
          </b>
        </div>
      )}
    </div>
  );
};

export default ReserveDrivers;
