export const CouponConfig = {
    reason : [
        {
            label:'Customer Call - Grievance',
            value:'CUSTOMER_CALL_GRIEVANCE'
        }
    ],

    subReason : [
        {
            label:'Rash Driving',
            value:'RASH_DRIVING'
        },
        {
            label:'Rude Driver',
            value:'RUDE_DRIVER'
        },
        {
            label:'Driver Not Reachable',
            value:'DRIVER_NOT_REACHABLE'
        },
        {
            label:'Vehicle Damage',
            value:'VEHICLE_DAMAGE'
        }
    ]
}