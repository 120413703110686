import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./styles/kendo-overwrite.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store/store";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./styles/tailwind.css";

import packageJson from "../package.json";

const root = ReactDOM.createRoot(document.getElementById("root"));

const showVersion =
  process.env.REACT_APP_ENV === "dev" || process.env.REACT_APP_ENV === "qa";

root.render(
  <>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
    <ToastContainer
      autoClose={2000}
      hideProgressBar={true}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss={false}
      draggable
      pauseOnHover
    />
    {showVersion && (
      <span className="tw-block tw-fixed tw-bottom-0 tw-right-0 tw-z-[5] tw-text-xs tw-bg-black/80 tw-px-1.5 tw-py-0.5 tw-text-white">
        Build No. {packageJson.version}
      </span>
    )}
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
