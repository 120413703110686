import React from "react";
import googleIcon from "../assets/google-sso.svg";
import { APIConfig } from "../utils/constants/api.constants";
import {
  clearLocalStorage,
  getFromLocalStorage,
  saveToLocalStorage,
  showErrorMessage,
  showToastMessage,
} from "../utils/helpers";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logOut } from "../slices/authSlice";
import { Button } from "@progress/kendo-react-buttons";

export default function LoginButton({ loading, redirectUri }) {
  const handleLogin = () => {
    const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;

    const scope = encodeURIComponent(
      "https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile openid"
    );
    const responseType = "code";
    const accessType = "offline";
    const prompt = "consent";

    const oauthLink = `https://accounts.google.com/o/oauth2/v2/auth?redirect_uri=${redirectUri}&prompt=${prompt}&response_type=${responseType}&client_id=${clientId}&scope=${scope}&access_type=${accessType}`;

    window.location.assign(oauthLink);
  };
  return (
    <button
      disabled={loading}
      onClick={handleLogin}
      className="tw-bg-white disabled:tw-animate-pulse disabled:tw-cursor-wait tw-border-none tw-rounded custom-border-primary tw-py-3 tw-px-4 tw-flex tw-items-center tw-gap-3 tw-text-[#262626] tw-text-sm tw-font-medium tw-w-full"
    >
      <img src={googleIcon} alt="Google" className="tw-w-6" />
      Continue with Google
    </button>
  );
}

export function LogoutButton() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let logoutUrl = process.env.REACT_APP_UMS_BASE_URL + APIConfig.ums.logout;
  let headers = {
    "x-channel-name": "autopilot",
    client_id: process.env.REACT_APP_UMS_CLIENT_ID,
    client_secret: process.env.REACT_APP_UMS_CLIENT_SECRET,
  };
  const handleLogout = () => {
    axios
      .post(
        logoutUrl,
        {
          clientId: process.env.REACT_APP_UMS_CLIENT_ID,
          clientSecret: process.env.REACT_APP_UMS_CLIENT_SECRET,
          refreshToken: getFromLocalStorage("refreshToken"),
        },
        {
          headers,
        }
      )
      .then((res) => {
        showToastMessage("Logged out");
        clearLocalStorage();
        dispatch(logOut());
        navigate("/login");
      })
      .catch((error) => {
        showErrorMessage(error);
      });
  };
  return (
    // <button
    //   onClick={handleLogout}
    //   className="logout-btn"
    // >
    //   Logout
    // </button>
    <Button
      onClick={handleLogout}
      className="tw-text-xs"
      themeColor={"secondary"}
    >
      Logout
    </Button>
  );
}
