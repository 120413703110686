import React from 'react'
import ModalLayout from '../../common/ModalLayout'
import { Button } from '@progress/kendo-react-buttons'

const PricingChangeDiffModal = ({ changedConfigData, onClose }) => {
    return (
        <ModalLayout title={'Configuration Changes'} onClose={onClose}>
            <div className='config-changes-div' style={{ textAlign: 'center', minWidth: 500 }}>
                <div className='row my-3'>
                    <div className='col-sm-5'>
                        <b>Field</b>
                    </div>
                    <div className='col-sm-3'>
                        <b>Old Config</b>
                    </div>
                    <div className='col-sm-3'>
                        <b>New Config</b>
                    </div>
                </div>
                {
                    changedConfigData && changedConfigData?.length > 0 && changedConfigData?.map(config => (
                        <>
                            {
                                config?.field && config?.field !== '' && (
                                    <div className='row my-2'>
                                        <div className='col-sm-5'>
                                            {config?.field}
                                        </div>
                                        <div className='col-sm-3'>
                                            {config?.oldConfig}
                                        </div>
                                        <div className='col-sm-3'>
                                            {config?.newConfig}
                                        </div>
                                    </div>
                                )
                            }
                        </>
                    ))
                }
                <br />
                <div className='row my-2'>
                    <div className='col-lg-12 txtcenter'>
                        <Button
                            themeColor={"primary"}
                            type={"button"}
                            onClick={onClose}
                        >
                            Close
                        </Button>
                    </div>
                </div>
            </div>
        </ModalLayout>
    )
}

export default PricingChangeDiffModal