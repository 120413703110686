export const CancellationConfig = {
  reason: [
    {
      label: "Customer Dependent",
      value: "CUSTOMER_DEPENDENT",
    },
    {
      label: "Client Dependent",
      value: "CLIENT_DEPENDENT",
    },
    {
      label: "Driver Dependent",
      value: "DRIVER_DEPENDENT",
    },
    {
      label: "Others",
      value: "OTHERS",
    },
  ],

  subReason: [
    {
      label: "Car Not Available",
      value: "CAR_NOT_AVAILABLE",
      reason: "CUSTOMER_DEPENDENT",
    },
    {
      label: "Car Not in Moving Condition",
      value: "CAR_NOT_IN_MOVING_CONDITION",
      reason: "CUSTOMER_DEPENDENT",
    },
    {
      label: "Customer Rescheduled to later day",
      value: "CUSTOMER_RESCHEDULED",
      reason: "CUSTOMER_DEPENDENT",
    },
    {
      label: "Customer Not Available",
      value: "CUSTOMER_NOT_AVAILABLE",
      reason: "CUSTOMER_DEPENDENT",
    },
    {
      label: "Customer denied handover of the car",
      value: "CUSTOMER_DENIED_HANDOVER",
      reason: "CUSTOMER_DEPENDENT",
    },
    {
      label: "Customer address is incorrect",
      value: "CUSTOMER_ADDRESS_INCORRECT",
      reason: "CUSTOMER_DEPENDENT",
    },
    {
      label: "Document - Not Available",
      value: "DOCUMENT_NOT_AVAILABLE",
      reason: "CUSTOMER_DEPENDENT",
    },
    {
      label: "Deal Lost",
      value: "DEAL_LOST",
      reason: "CLIENT_DEPENDENT",
    },
    {
      label: "Payment Not Completed",
      value: "PAYMENT_NOT_COMPLETED",
      reason: "CLIENT_DEPENDENT",
    },
    {
      label: "Document - QC reject",
      value: "DOCUMENT_QC_REJECT",
      reason: "CLIENT_DEPENDENT",
    },
    {
      label: "VTF not signed",
      value: "VTF_NOT_SIGNED",
      reason: "CLIENT_DEPENDENT",
    },
    {
      label: "Delivery Payment Not Done",
      value: "DELIVERY_PAYMENT_NOT_DONE",
      reason: "CLIENT_DEPENDENT",
    },
    {
      label: "Ops - Driver shortage",
      value: "OPS_DRIVER_SHORTAGE",
      reason: "DRIVER_DEPENDENT",
    },
    {
      label: "Ops - Driver not willing to go",
      value: "OPS_DRIVER_NOT_WILLING",
      reason: "DRIVER_DEPENDENT",
    },
    {
      label: "The driver has denied completing the task",
      value: "DRIVER_DENIED_TASK",
      reason: "DRIVER_DEPENDENT",
    },
    {
      label: "Bad Weather",
      value: "BAD_WEATHER",
      reason: "OTHERS",
    },
  ],
};
