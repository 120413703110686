import { Button } from "@progress/kendo-react-buttons";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import React from "react";
import { convertEnumToLabel } from "../../utils/helpers";

const FilterDropDown = (props) => {
  let hasValue = (value) => Boolean(value && value !== props.defaultItem.value);
  const onChange = (event) => {
    hasValue = hasValue(event.target.value);
    props.onChange({
      value: hasValue ? event.target.value?.value : "",
      operator: "eq",
      syntheticEvent: event.syntheticEvent,
    });
  };
  const onClearButtonClick = (event) => {
    event.preventDefault();
    props.onChange({
      value: "",
      operator: "eq",
      syntheticEvent: event,
    });
  };

  return (
    <div className="k-filtercell">
      <DropDownList
        {...props}
        value={
          props.value
            ? {
                label: props.dontConvertLabel
                  ? props.value
                  : convertEnumToLabel(props.value),
                value: props.value,
              }
            : props.defaultItem
        }
        popupSettings={{
          width: props?.width ? props?.width : 200,
          height: 400,
        }}
        textField="label"
        dataItemKey="value"
        filterable={false}
        onChange={onChange}
      />
      <Button
        title="Clear"
        disabled={!hasValue(props.value)}
        onClick={onClearButtonClick}
        icon="filter-clear"
      />
    </div>
  );
};

export default FilterDropDown;
