import React, { Fragment, useEffect, useState } from "react";
import {
  getLabelFromEnum,
  replaceNullValues,
  showErrorMessage,
  stringToSentenceCase,
  showToastMessage,
  convertEnumToLabel,
  timeConverter,
} from "../../utils/helpers";
import { Button } from "@progress/kendo-react-buttons";
import axiosService from "../../init/axios";
import { APIConfig } from "../../utils/constants/api.constants";
import TripDetailsMap from "../../components/trip-management/trip-details/TripDetailsMap";

export default function B2CTripManagementPage({ tripId, tripDetails }) {
  const [updatedTripDetails, setUpdatedTripDetails] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const updatedDetails = getLabelFromEnum(
      ["derivedTripType", "journeyMode", "stage", "tripBookingMode"],
      { ...tripDetails },
      "trip"
    );
    setUpdatedTripDetails(updatedDetails);
  }, []);

  const markDriverNotFound = async () => {
    setLoading(true);
    await axiosService
      .put(
        process.env.REACT_APP_TRIP_BASE_URL +
          APIConfig.tripManagement.markDnf(tripId)
      )
      .then((data) => {
        setLoading(false);
        showToastMessage("Marked DNF successfully");
      })
      .catch((error) => {
        setLoading(false);
        showErrorMessage(error);
      });
  };
  const stops = tripDetails?.stops?.stops;

  return (
    <>
      <div
        style={{
          marginBottom: "32px",
          fontSize: "14px",
        }}
        className="tw-grid tw-gap-5 tw-grid-cols-6"
      >
        <div className="tw-col-span-4 tw-grid tw-grid-cols-2 tw-gap-5">
          <div>
            <h5 className="tw-text-[16px]">Driver Details</h5>
            <div className="custom-border-primary tw-p-4 tw-rounded-md">
              <div className="tw-flex tw-justify-between">
                <div>
                  <p className="tw-text-[#919191] tw-mb-0 tw-text-[14px]">
                    Driver Name
                  </p>
                  <p className="tw-mb-0">
                    {replaceNullValues(
                      stringToSentenceCase(
                        updatedTripDetails?.driverDetails?.fullName
                      )
                    )}
                  </p>
                </div>
                <div>
                  <p className="tw-text-[#919191] tw-mb-0 tw-text-[14px]">
                    Driver contact number
                  </p>
                  <p className="tw-mb-0">
                    {replaceNullValues(
                      stringToSentenceCase(tripDetails?.driverMobile)
                    )}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div>
            <h5 className="tw-text-[16px]">Vehicle Details</h5>
            <div className="custom-border-primary tw-p-4 tw-rounded-md">
              <div className="tw-flex tw-justify-between">
                <div>
                  <p className="tw-text-[#919191] tw-mb-0 tw-text-[14px]">
                    Car type:
                  </p>
                  <p className="tw-mb-0">
                    {convertEnumToLabel(tripDetails?.vehicleDetails?.carType) ||
                      "N.A"}
                  </p>
                </div>
                <div>
                  <p className="tw-text-[#919191] tw-mb-0 tw-text-[14px]">
                    Transmission type:
                  </p>
                  <p className="tw-mb-0">
                    {convertEnumToLabel(
                      tripDetails?.vehicleDetails?.transmissionType
                    ) || "N.A"}
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* PICKUP DETAILS */}
          <div className="tw-col-span-2">
            <h5 className="tw-text-[16px] tw-mt-4">Pickup Details</h5>
            <div className="custom-border-primary tw-p-4 tw-rounded-md">
              <div className="tw-grid tw-grid-cols-3 tw-gap-4">
                <div>
                  <p className="tw-text-[#919191] tw-mb-0 tw-text-[14px]">
                    Customer Name
                  </p>
                  <p>
                    {replaceNullValues(
                      stringToSentenceCase(updatedTripDetails?.customerName)
                    )}
                  </p>
                </div>
                <div>
                  <p className="tw-text-[#919191] tw-mb-0 tw-text-[14px]">
                    Customer Phone
                  </p>
                  <p>{replaceNullValues(updatedTripDetails?.customerMobile)}</p>
                </div>
                <div>
                  <p className="tw-text-[#919191] tw-mb-0 tw-text-[14px]">
                    Mark DNF
                  </p>
                  <Button onClick={markDriverNotFound} themeColor={"primary"}>
                    Mark DNF
                  </Button>
                </div>
                <div>
                  <p className="tw-text-[#919191] tw-mb-0 tw-text-[14px]">
                    Trip Type
                  </p>
                  <p>
                    {replaceNullValues(updatedTripDetails?.derivedTripType)}
                  </p>
                </div>
                <div>
                  <p className="tw-text-[#919191] tw-mb-0 tw-text-[14px]">
                    Trip sub type
                  </p>
                  <p>{replaceNullValues(updatedTripDetails?.journeyMode)}</p>
                </div>
                <div>
                  <p className="tw-text-[#919191] tw-mb-0 tw-text-[14px]">
                    Trip booking mode
                  </p>
                  <p>
                    {replaceNullValues(updatedTripDetails?.tripBookingMode)}
                  </p>
                </div>
                <div>
                  <p className="tw-text-[#919191] tw-mb-0 tw-text-[14px]">
                    Expected trip start time
                  </p>
                  <p>
                    {timeConverter(updatedTripDetails?.expectedTripStartTime)}
                  </p>
                </div>
                <div>
                  <p className="tw-text-[#919191] tw-mb-0 tw-text-[14px]">
                    Actual trip start time
                  </p>
                  <p>
                    {timeConverter(updatedTripDetails?.actualTripStartTime)}
                  </p>
                </div>
              </div>
              <div className="tw-grid tw-grid-cols-2 tw-gap-4 tw-mt-4">
                <div>
                  <p className="tw-text-[#37A82B] tw-mb-0 tw-text-[14px]">
                    Address:
                  </p>
                  <p>
                    {updatedTripDetails?.actualSourceLocation?.heading
                      ? updatedTripDetails?.actualSourceLocation?.heading +
                        (updatedTripDetails?.actualSourceLocation?.subHeading
                          ? ", " +
                            updatedTripDetails?.actualSourceLocation?.subHeading
                          : "")
                      : replaceNullValues(
                          updatedTripDetails?.expectedSourceLocation?.heading
                            ? updatedTripDetails?.expectedSourceLocation
                                ?.heading +
                                (updatedTripDetails?.expectedSourceLocation
                                  ?.subHeading
                                  ? ", " +
                                    updatedTripDetails?.expectedSourceLocation
                                      ?.subHeading
                                  : "")
                            : null
                        )}
                  </p>
                </div>
                <div>
                  <p className="tw-text-[#B4272E] tw-mb-0 tw-text-[14px]">
                    Drop:
                  </p>
                  <p>
                    {updatedTripDetails?.actualDestinationLocation?.heading
                      ? updatedTripDetails?.actualDestinationLocation?.heading +
                        (updatedTripDetails?.actualDestinationLocation
                          ?.subHeading
                          ? ", " +
                            updatedTripDetails?.actualDestinationLocation
                              ?.subHeading
                          : "")
                      : replaceNullValues(
                          updatedTripDetails?.expectedDestinationLocation
                            ?.heading
                            ? updatedTripDetails?.expectedDestinationLocation
                                ?.heading +
                                (updatedTripDetails?.expectedDestinationLocation
                                  ?.subHeading
                                  ? ", " +
                                    updatedTripDetails
                                      ?.expectedDestinationLocation?.subHeading
                                  : "")
                            : null
                        )}
                  </p>
                </div>
              </div>
              <div className="tw-grid tw-grid-cols-3 tw-gap-6 tw-mt-4">
                {stops &&
                  stops.length > 0 &&
                  stops.map((item, index) => {
                    return (
                      <div>
                        <p className="tw-text-[#919191] tw-mb-0 tw-text-[14px]">
                          Pitstop {index + 1}
                        </p>
                        <p>{item?.subHeading || item.heading}</p>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
        {tripDetails && <TripDetailsMap tripDetails={tripDetails} />}
      </div>
    </>
  );
}
