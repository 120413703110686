import React, { useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { FormDropDownList } from "../../../utils/forms/FormComponents";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import {
  dateAndTimeToRequiredFormat,
  prefixZeroToValue,
} from "../../../utils/helpers";
import DriverAccountSelectedPeriodTable from "./DriverAccountSelectedPeriodTable";
import BackArrowIcon from "../../../assets/icons/BackArrowIcon";

const DriverAccount = () => {
  const navigate = useNavigate();
  const date = new Date();
  const currentMonthStart = date.getFullYear() + "-" + prefixZeroToValue(Number(date.getMonth() + 1)) + "-01";
  const currentMonthTillNow = date.getFullYear() + "-" + prefixZeroToValue(Number(date.getMonth() + 1)) + "-" + prefixZeroToValue(date.getDate());

  const [searchParams, setSearchParams] = useSearchParams();
  const { driverId } = useParams();


  const [settlementSummary, setSettlementSummary] = useState({
    driverCollect: "-",
    walletRecharges: "-",
    payouts: "-",
    walletBalance: "-",
    netEarning: "-",
    closingBalance: "-",
    openingBalance: "-",
  });

  const [selectedPayoutCycle, setSelectedPayoutCycle] = useState("Current Month");
  const [customFromDate, setCustomFromDate] = useState(currentMonthStart);
  const [customToDate, setCustomToDate] = useState(currentMonthTillNow);
  const [dateRange, setDateRange] = useState({
    from: currentMonthStart,
    to: currentMonthTillNow,
  });

  const onPayoutCycleChange = (e) => {
    if (e.target.value === "Current") {
      setDateRange({
        from: currentMonthStart,
        to: currentMonthTillNow,
      });
    }
    if (e.target.value === "Previous") {
      const fromDateArr = dateAndTimeToRequiredFormat(
        new Date(date.getFullYear(), Number(date.getMonth() - 1), 1)
      );
      const fromDate = [fromDateArr[2], fromDateArr[1], fromDateArr[0]].join(
        "-"
      );
      const toDateArr = dateAndTimeToRequiredFormat(
        new Date(date.getFullYear(), date.getMonth(), 0)
      );
      const toDate = [toDateArr[2], toDateArr[1], toDateArr[0]].join("-");
      setDateRange({
        from: fromDate,
        to: toDate,
      });
    }
    if (e.target.value === "Current Month") {
      setDateRange({
        from: currentMonthStart,
        to: currentMonthTillNow,
      });
    }
    setSelectedPayoutCycle(e.target.value);
  };

  const onUpdateSettlementSummary = (data) => {
    setSettlementSummary((prevState) => ({
      ...prevState,
      driverCollect: data.driverCollectAmount,
      walletRecharges: data.totalRechargesAmount,
      payouts: data.totalPayoutAmount,
      netEarnings: data.netEarnings,
      openingBalance: data.openingBalance,
      closingBalance: data.closingBalance,
      currentWalletBalance: data?.currentBalance,
      walletBalance: data?.closingBalance,
    }));
  };

  const handleFromDateChange = (event) => {
    if (event?.nativeEvent?.type === "click") {
      const [day, month, year] = dateAndTimeToRequiredFormat(event.value);
      setDateRange({
        from: [year, month, day].join("-"),
        to: customToDate,
      });
      setCustomFromDate([year, month, day].join("-"));
    }
  };

  const handleToDateChange = (event) => {
    if (event?.nativeEvent?.type === "click") {
      const [day, month, year] = dateAndTimeToRequiredFormat(event.value);
      setDateRange({
        from: customFromDate,
        to: [year, month, day].join("-"),
      });
      setCustomToDate([year, month, day].join("-"));
    }
  };

  return (
    <div className="driver-account">
      <span className="tw-cursor-pointer" onClick={() => navigate("/home/driver")}>
        <BackArrowIcon />
      </span>
      <br />
      <br />
      <div className="row">
        <div className="col-sm-4">
          <div className="dflex jcsp alc">
            <p>
              Driver ID : <b>{driverId || "N.A"}</b>
            </p>
            <p>
              Driver Name :{" "}
              <b>
                {searchParams.get("driverName")
                  ? searchParams.get("driverName")
                  : "N.A"}
              </b>
            </p>
          </div>
        </div>
      </div>
      <br />
      <div className="current-wallet-balance">
        <div className="row">
          <div
            className="col-sm-3 dflex jcsp alc"
            style={{ paddingRight: "0px" }}
          >
            <h4>Current Wallet Balance : </h4>
            <h4 className="tw-whitespace-nowrap">
              {"₹"} {settlementSummary?.currentWalletBalance}
            </h4>
          </div>
        </div>
      </div>
      <hr style={{ width: "100%" }} />

      <div className="row">
        <div className="col-sm-3">
          <div className="payout-cycle dflex jcsp alc">
            <p>
              <b>Payout Cycle</b>
            </p>
            <FormDropDownList
              data={["Current Month", "Custom"]}
              value={selectedPayoutCycle}
              onChange={onPayoutCycleChange}
              style={{ width: "200px" }}
            />
          </div>
        </div>
      </div>
      <br />
      <div
        className="custom-date-filter-div dflex jcsp alc"
        style={
          selectedPayoutCycle === "Custom"
            ? { width: "40%", display: "flex" }
            : { display: "none" }
        }
      >
        <DatePicker
          placeholder="From"
          max={new Date(customToDate)}
          value={new Date(customFromDate)}
          onChange={handleFromDateChange}
          format={"dd/MM/yyyy"}
        />
        <span className="mx-4">to</span>
        <DatePicker
          placeholder="To"
          min={new Date(customFromDate)}
          max={new Date()}
          value={new Date(customToDate)}
          onChange={handleToDateChange}
          format={"dd/MM/yyyy"}
        />
      </div>
      <br />

      <div className="settlement-summary">
        <h5>Settlement Summary (For selected period)</h5>
        <div className="row">
          <div className="col-sm-3">
            <div className="row">
              <div className="col-6 my-1 px-4">
                <span>Driver Collect</span>
              </div>
              <div className="col-3 my-1">
                <span>
                  <b>{settlementSummary?.driverCollect}</b>
                </span>
              </div>
              <div className="col-6 my-1 px-4">
                <span>Wallet Recharges</span>
              </div>
              <div className="col-3 my-1">
                <span>
                  <b>{settlementSummary?.walletRecharges}</b>
                </span>
              </div>
              <div className="col-6 my-1 px-4">
                <span>Payouts</span>
              </div>
              <div className="col-3 my-1">
                <span>
                  <b>{settlementSummary?.payouts}</b>
                </span>
              </div>
              <div className="col-6 my-1 px-4">
                <span>Wallet Balance</span>
              </div>
              <div className="col-3 my-1">
                <span>
                  <b>{settlementSummary?.walletBalance}</b>
                </span>
              </div>
              <div
                className="col-6 my-1 px-4"
                style={{ backgroundColor: "lightblue" }}
              >
                <span>Net Earnings</span>
              </div>
              <div
                className="col-3 my-1"
                style={{ backgroundColor: "lightblue" }}
              >
                <span>
                  <b>{settlementSummary?.netEarnings}</b>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <div className="driver-accounts-table-div">
        <h5>Account Statement (For selected period)</h5>
        <br />
        <p>
          Closing Balance : <b>{settlementSummary?.closingBalance}</b>
        </p>
        <DriverAccountSelectedPeriodTable
          currentMonthStart={currentMonthStart}
          currentMonthTillNow={currentMonthTillNow}
          from={dateRange.from}
          to={dateRange.to}
          updateSettlementSummary={onUpdateSettlementSummary}
        />
        <br />
        <p>
          Opening Balance : <b>{settlementSummary?.openingBalance}</b>
        </p>
      </div>
    </div>
  );
};

export default DriverAccount;
