import React, { useEffect, useRef, useState } from "react";
import { Field, Form, FormElement } from "@progress/kendo-react-form";
import {
  FormDropDownList,
  FormInput,
  FormNumericTextBox,
  FormTextArea,
} from "../../../../../utils/forms/FormComponents";
import {
  checkMatchingRoles,
  handleOnWheel,
} from "../../../../../utils/helpers";
import { TripConfig } from "../../../../../config/tripConfig";
import { requiredValidator } from "../../../../../utils/forms/Validators";
import { useSelector } from "react-redux";
import { userPermissions } from "../../../../../config/userPermissionConfig";
import { Button } from "@progress/kendo-react-buttons";

const RefundForm = ({ handleSubmit = () => {}, refundDetails = {} }) => {
  const [selectedRefundReason, setSelectedRefundReason] = useState({
    label: "Driver Issue",
    value: "DRIVER_ISSUE",
  });

  const [selectedRefundSubReason, setSelectedRefundSubReason] = useState({
    label: "Driver Drunk",
    value: "DRUNK",
    tag: "DRIVER_INFRACTION",
  });
  const numericTextBoxRef = useRef();
  const userRoles = useSelector((state) => state.auth?.userRoles);
  const onSelectedRefundReasonChange = (e, formRenderProps) => {
    setSelectedRefundReason(e.target.value);
    formRenderProps.onChange("refundSubReason", {
      value: TripConfig.refundSubReason.filter(
        (item) => item.reason === e.target.value?.value
      )[0],
    });
    setSelectedRefundSubReason(
      TripConfig.refundSubReason.filter(
        (item) => item.reason === e.target.value?.value
      )[0]
    );
  };
  //To prevent default behaviour of mouseWheel down of Numeric Text Box
  useEffect(() => {
    const ref = numericTextBoxRef?.current;
    ref?.element?.addEventListener("wheel", handleOnWheel);

    return () => {
      ref?.element?.removeEventListener("wheel", handleOnWheel);
    };
  });
  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={refundDetails}
      key={JSON.stringify(refundDetails)}
      render={(formRenderProps) => (
        <FormElement
          style={{
            width: "100%",
            // padding: '0px 16px 0px 16px',
            // border: '1px solid rgba(0, 0, 0, 0.08)',
          }}
        >
          <div className="row">
            <div className="col-lg-6">
              <div className="row">
                <div className="col-lg-6">Refund Amount:</div>
                <div className="col-lg-6">
                  <Field
                    id={"amount"}
                    name={"amount"}
                    value={refundDetails?.amount}
                    format={"n0"}
                    component={FormNumericTextBox}
                    min={1}
                    validator={requiredValidator}
                    refP={(el) => (numericTextBoxRef.current = el)}
                  />
                </div>
              </div>
              <br />
              <div className="row">
                <div className="col-lg-6">Refund Status:</div>
                <div className="col-lg-6">
                  <Field
                    id={"refundStatus"}
                    name={"refundStatus"}
                    component={FormDropDownList}
                    data={
                      !checkMatchingRoles(
                        userRoles,
                        userPermissions.refundModification
                      )
                        ? TripConfig.refundStatusNonFinanceUser
                        : TripConfig.refundStatusFinanceUser
                    }
                    textField="label"
                    dataItemKey="value"
                    // defaultValue={TripConfig.refundStatus[0]}
                    validator={requiredValidator}
                  />
                </div>
              </div>
              <br />
              <div className="row">
                <div className="col-lg-4">Remarks:</div>
                <div className="col-lg-8">
                  <Field
                    id={"remarks"}
                    name={"remarks"}
                    value={refundDetails?.remarks}
                    component={FormTextArea}
                    rows={3}
                    validator={requiredValidator}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row">
                <div className="col-lg-6">Refund Reason:</div>
                <div className="col-lg-6">
                  <Field
                    id={"refundReason"}
                    name={"refundReason"}
                    component={FormDropDownList}
                    data={TripConfig.refundReason}
                    textField="label"
                    dataItemKey="value"
                    // defaultValue={TripConfig.refundReason[0]}
                    onChange={(e) =>
                      onSelectedRefundReasonChange(e, formRenderProps)
                    }
                    validator={requiredValidator}
                  />
                </div>
              </div>
              <br />
              <div className="row">
                <div className="col-lg-6">Refund Sub Reason:</div>
                <div className="col-lg-6">
                  <Field
                    id={"refundSubReason"}
                    name={"refundSubReason"}
                    component={FormDropDownList}
                    data={TripConfig.refundSubReason.filter(
                      (item) => item.reason === selectedRefundReason.value
                    )}
                    textField="label"
                    dataItemKey="value"
                    // defaultValue={TripConfig.refundSubReason.filter(item => item.reason === selectedRefundReason.value)[0]}
                    validator={requiredValidator}
                  />
                </div>
              </div>
              <br />
              <div className="row">
                <div className="col-lg-6">Transaction Id:</div>
                <div className="col-lg-6">
                  <Field
                    id={"transactionId"}
                    name={"transactionId"}
                    component={FormInput}
                    value={refundDetails?.transactionId}
                  />
                </div>
              </div>
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col-lg-12 txtcenter">
              <Button
                themeColor={"primary"}
                type={"submit"}
                disabled={!formRenderProps.valid}
              >
                Submit
              </Button>
            </div>
          </div>
        </FormElement>
      )}
    />
  );
};

export default RefundForm;
