import React from 'react';
import { Dialog } from "@progress/kendo-react-dialogs";

const DocumentImageModal = ({ title, imageUrl, onClose }) => {

  const addDefaultSrc = ({ currentTarget }) => {
    currentTarget.onerror = null;
    currentTarget.src = 'https://cdn.vectorstock.com/i/preview-1x/65/30/default-image-icon-missing-picture-page-vector-40546530.jpg';
  }

  return (
    <Dialog title={title} onClose={onClose}>
      <div style={{ padding: '10px' }}>
        <img style={{ maxWidth: 600, maxHeight: 600 }} src={imageUrl} alt={title} onError={addDefaultSrc} />
      </div>
    </Dialog>
  )
}

export default DocumentImageModal;