import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import React, { useState } from 'react'
import { APIConfig } from '../../../../../utils/constants/api.constants';
import GridLoader from '../../../../common/GridLoader';
import { replaceNullValues } from '../../../../../utils/helpers';

const AllocationLogs = ({ tripId }) => {
  const baseUrl = process.env.REACT_APP_DRIVER_BASE_URL + APIConfig.tripManagement.getAllocationLogs(tripId);
  const [allocationLogs, setAllocationLogs] = useState({
    data: [],
    total: 0
  });
  const [dataState, setDataState] = useState({
    take: 10,
    skip: 0
  });

  const columns = [
    {
      id: "driverId",
      field: "driverId",
      title: "Driver Id",
    },
    {
      id: "driverAction",
      field: "driverAction",
      title: "Driver Action",
    },
    {
      id: "location",
      field: "location",
      title: "Location",
    },
    {
      id: "broadcastedAt",
      field: "broadcastedAt",
      title: "Broadcasted At",
    },
    {
      id: "updatedAt",
      field: "updatedAt",
      title: "Updated At",
    }
  ];

  const dataStateChange = e => {
    setDataState(e.dataState);
  };
  const dataReceived = dataObj => {
    const arr = dataObj.data.allocationDetails.map(driver => {
      return { ...driver, location: driver.driverLat + ', ' + driver.driverLng, broadcastedAt: driver.createdAt };
    })
    setAllocationLogs({ ...dataObj, data: arr });
  };
  return (
    <div className='allocation-logs-table'>
      {/* <p>Trip Schedule Type : Scheduled(15 May 2023 08:00:00)</p>
      <br /> */}
      <Grid filterable={false} sortable={false} pageable={false} {...dataState}
        data={allocationLogs}
        onDataStateChange={dataStateChange}>
        {
          columns.map(column => (
            <Column key={column?.id} field={column?.field} title={column?.title} />
          ))
        }
      </Grid>

      <GridLoader baseUrl={baseUrl} columns={columns} dataState={dataState} onDataReceived={dataReceived} />

    </div>
  )
}

export default AllocationLogs