import { DropDownList } from "@progress/kendo-react-dropdowns";
import { TripConfig as config } from "../../../config/tripConfig";
import React, { useEffect, useRef, useState } from "react";
import { FormInputComponent } from "../PricingConfigurationForm";
import { Form, FormElement } from "@progress/kendo-react-form";
import { Button } from "@progress/kendo-react-buttons";
import axiosService from "../../../init/axios";
import { APIConfig } from "../../../utils/constants/api.constants";
import { showErrorMessage, showToastMessage } from "../../../utils/helpers";
import LoadingSpinner from "../../common/LoadingSpinner";

export default function PricingOtherChanges({ regionId }) {
  const [derivedTripType, setDerivedTripType] = useState(
    config.derivedTripType[0]
  );
  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState({
    cancellationCharges: 0,
    rescheduleCharges: 0,
    insuranceCharges: 0,
    // minimumTripDurationInMinutes: 0,
    // roundTripThresholdRadiusInMeters: 0,
  });

  const getData = () => {
    setLoading(true);
    axiosService
      .get(
        process.env.REACT_APP_PRICING_BASE_URL +
          APIConfig.pricingManagement.getPricingVAC(
            derivedTripType.value,
            regionId
          )
      )
      .then((e) => {
        setFormData({
          cancellationCharges: e?.data?.vacConfig?.cancellationCharges || 0,
          rescheduleCharges: e?.data?.vacConfig?.rescheduleCharges || 0,
          insuranceCharges: e?.data?.vacConfig?.insuranceCharges || 0,
          // minimumTripDurationInMinutes:
          //   e?.data?.vacConfig?.minimumTripDurationInMinutes || 0,
          // roundTripThresholdRadiusInMeters:
          //   e?.data?.vacConfig?.roundTripThresholdRadiusInMeters || 0,
        });
        setLoading(false);
      })
      .catch((err) => {
        showErrorMessage(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    getData();
  }, [derivedTripType]);

  const handleSubmit = (data) => {
    const payload = {
      derivedTripType: derivedTripType.value,
      regionId: regionId,
      fareConfigValue: data,
    };

    axiosService
      .post(
        process.env.REACT_APP_PRICING_BASE_URL +
          APIConfig.pricingManagement.changePricingLogs,
        payload
      )
      .then((e) => {
        setFormData(e?.data || data);
        showToastMessage("Updated Successfully");
        setLoading(false);
      })
      .catch((err) => {
        showErrorMessage(err);
        setLoading(false);
      });
  };

  const numericTextBoxesRef = useRef([]);

  return (
    <>
      {loading && (
        <div className="tw-absolute tw-top-0 tw-left-0 tw-bg-white/70 tw-z-10 tw-grid tw-place-content-center tw-w-full tw-h-full">
          <LoadingSpinner color={"#d5d5d5"} />
        </div>
      )}
      <div className="tw-w-full tw-max-w-60 tw-mb-10">
        <label className="tw-text-sm tw-whitespace-nowrap tw-block">
          Trip type
        </label>
        <DropDownList
          data={config.derivedTripType}
          textField="label"
          dataItemKey="value"
          value={derivedTripType}
          onChange={(e) => setDerivedTripType(e.target.value)}
        />
      </div>
      <div className="pricing-config">
        <Form
          onSubmit={handleSubmit}
          initialValues={{
            ...formData,
          }}
          key={JSON.stringify(formData)}
          render={(formRenderProps) => (
            <>
              <FormElement className="tw-grid tw-grid-cols-2 tw-gap-5">
                <FormInputComponent
                  id={"cancellationCharges"}
                  label={"Cancellation Charges"}
                  numericTextBoxesRef={numericTextBoxesRef}
                  value={formData?.cancellationCharges}
                  refIndex={0}
                />

                <FormInputComponent
                  id={"rescheduleCharges"}
                  label={"Reschedule Charges"}
                  numericTextBoxesRef={numericTextBoxesRef}
                  value={formData?.rescheduleCharges}
                  refIndex={1}
                />

                <FormInputComponent
                  id={"insuranceCharges"}
                  label={"Insurance Charges"}
                  numericTextBoxesRef={numericTextBoxesRef}
                  value={formData?.insuranceCharges}
                  refIndex={2}
                />

                {/* <FormInputComponent
                  id={"minimumTripDurationInMinutes"}
                  label={"Minimum Trip Duration (mins)"}
                  numericTextBoxesRef={numericTextBoxesRef}
                  value={formData?.minimumTripDurationInMinutes}
                  refIndex={3}
                  charges={false}
                />

                <FormInputComponent
                  id={"roundTripThresholdRadiusInMeters"}
                  label={"Round Trip Threshold Radius (mtrs)"}
                  numericTextBoxesRef={numericTextBoxesRef}
                  value={formData?.roundTripThresholdRadiusInMeters}
                  refIndex={4}
                  charges={false}
                /> */}
                <div className="tw-col-start-1">
                  <Button
                    themeColor={"primary"}
                    type={"submit"}
                    disabled={!formRenderProps.allowSubmit}
                  >
                    Save
                  </Button>
                </div>
              </FormElement>
            </>
          )}
        />
      </div>
    </>
  );
}
