import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Marker } from "@react-google-maps/api";
import { mapDefaults } from "./defaults";
import GoogleMapsWrapper from "./GoogleMapsWrapper";
import driverCurrentIcon from "../../assets/driver-current-location.png";
import startPointIcon from "../../assets/start-point.png";
import endPointIcon from "../../assets/end-point.png";
import driverStartedIcon from "../../assets/driver-started.png";
import axiosService from "../../init/axios";
import { APIConfig } from "../../utils/constants/api.constants";
import { showErrorMessage } from "../../utils/helpers";
import LoadingSpinner from "../common/LoadingSpinner";

const DriverViewMap = ({
  currentDriverStatus,
  actualPathToggle,
  selectedTrip,
  driverId,
  pitstops = [],
}) => {
  const [loading, setLoading] = useState(false);
  const [pathDetails, setPathDetails] = useState({
    TRIP_STARTED: [],
    TRIP_END: [],
    TRIP_COMPLETED: [],
    DRIVER_ARRIVED: [],
    DRIVER_ON_THE_WAY: [],
  });

  const containerStyle = { width: "100%", height: "100%" };
  const mapRef = useRef(null);
  const polylineRef = useRef(null);
  const actualPolylineRefs = useRef({});
  const arrowPolylineRef = useRef(null);
  const markerRef = useRef(null);

  const pickup =
    selectedTrip?.actualSourceLocation || selectedTrip?.expectedSourceLocation;
  const drop =
    selectedTrip?.actualDestinationLocation ||
    selectedTrip?.expectedDestinationLocation;

  const center = useMemo(
    () => ({
      lat: selectedTrip
        ? (pickup?.lat + drop?.lat) / 2
        : currentDriverStatus?.lat || mapDefaults.driverTimeline.center.lat,
      lng: selectedTrip
        ? (pickup?.lng + drop?.lng) / 2
        : currentDriverStatus?.lng || mapDefaults.driverTimeline.center.lng,
    }),
    [
      pickup,
      drop,
      currentDriverStatus?.lat,
      currentDriverStatus?.lng,
      selectedTrip,
    ]
  );

  const fitBounds = useCallback(() => {
    const map = mapRef.current;
    if (map && selectedTrip && pickup && drop) {
      const bounds = new window.google.maps.LatLngBounds();
      bounds.extend(pickup);
      bounds.extend(drop);
      pitstops?.forEach((stop) => bounds.extend(stop));
      selectedTrip?.driverBeginLocation?.lat &&
        bounds.extend(selectedTrip.driverBeginLocation);
      map.fitBounds(bounds);
    }
  }, [pickup, drop, pitstops, selectedTrip]);

  useEffect(() => {
    fitBounds();
  }, [pickup, drop, fitBounds, selectedTrip]);

  useEffect(() => {
    if (mapRef.current) {
      fitBounds();
    }
  }, [fitBounds, mapRef.current]);

  const onLoad = useCallback(
    (map) => {
      mapRef.current = map;
      fitBounds();
    },
    [fitBounds]
  );

  const options = useMemo(() => mapDefaults.fleetViewMap.options, []);

  const [currentMarkerPosition, setCurrentMarkerPosition] = useState({
    lat: currentDriverStatus?.lat,
    lng: currentDriverStatus?.lng,
  });

  useEffect(() => {
    setCurrentMarkerPosition({
      lat: currentDriverStatus?.lat,
      lng: currentDriverStatus?.lng,
    });
  }, [currentDriverStatus]);

  const path = useMemo(() => {
    if (selectedTrip && pickup && drop) {
      return [
        { lat: pickup.lat, lng: pickup.lng },
        ...pitstops.map((item) => ({ lat: item.lat, lng: item.lng })),
        { lat: drop.lat, lng: drop.lng },
      ];
    }
    return [];
  }, [pickup, drop, pitstops, selectedTrip]);

  const removePolyline = useCallback(() => {
    if (polylineRef?.current) {
      polylineRef.current.setMap(null);
      polylineRef.current = null;
    }
    if (arrowPolylineRef?.current) {
      arrowPolylineRef.current.setMap(null);
      arrowPolylineRef.current = null;
    }
  }, []);
  useEffect(() => {
    if (mapRef?.current) {
      if (!currentMarkerPosition?.lat && markerRef.current) {
        markerRef.current.setMap(null);
        markerRef.current = null;
        return;
      }

      if (currentMarkerPosition?.lat) {
        const marker = new window.google.maps.Marker({
          position: currentMarkerPosition,
          map: mapRef.current,
          icon: {
            url: driverCurrentIcon,
            scaledSize: new window.google.maps.Size(40, 40),
            origin: new window.google.maps.Point(0, 0),
            anchor: new window.google.maps.Point(20, 20),
          },
        });
        markerRef.current = marker;
        return () => {
          marker.setMap(null);
          markerRef.current = null;
        };
      }
    }
  }, [mapRef, currentMarkerPosition, driverCurrentIcon]);
  const removeActualPolyline = useCallback(() => {
    if (actualPolylineRefs?.current) {
      Object.keys(actualPolylineRefs.current).forEach((key) => {
        if (actualPolylineRefs.current[key]) {
          actualPolylineRefs.current[key].setMap(null);
          delete actualPolylineRefs.current[key];
        }
      });
    }
  }, [actualPathToggle]);

  useEffect(() => {
    if (mapRef.current) {
      if (path.length > 0 && !actualPathToggle) {
        if (!polylineRef.current) {
          const polyline = new window.google.maps.Polyline({
            path,
            strokeColor: "#000",
            strokeOpacity: 0,
            icons: [
              {
                icon: {
                  path: "M 0,-1 0,1",
                  strokeOpacity: 1,
                  scale: 4,
                },
                offset: "0",
                repeat: "20px",
              },
            ],
            strokeWeight: 2,
          });
          polyline.setMap(mapRef.current);
          polylineRef.current = polyline;
        } else {
          polylineRef.current.setPath(path);
        }
      } else {
        removePolyline();
      }
    }
  }, [path, removePolyline]);

  const baseUrl =
    process.env.REACT_APP_DRIVER_BASE_URL +
    APIConfig.driverManagement.getTripPath(driverId, selectedTrip?.tripId);

  const getActualPath = () => {
    setLoading(true);
    axiosService
      .get(baseUrl)
      .then((e) => {
        const obj = {
          TRIP_STARTED: e.data.pathDetails?.TRIP_STARTED?.path || [],
          TRIP_END: e.data.pathDetails?.TRIP_END?.path || [],
          TRIP_COMPLETED: e.data.pathDetails?.TRIP_COMPLETED?.path || [],
          DRIVER_ARRIVED: e.data.pathDetails?.DRIVER_ARRIVED?.path || [],
          DRIVER_ON_THE_WAY: e.data.pathDetails?.DRIVER_ON_THE_WAY?.path || [],
        };
        setLoading(false);
        setPathDetails(obj);
      })
      .catch((err) => {
        setLoading(false);
        showErrorMessage(err);
      });
  };

  useEffect(() => {
    if (actualPathToggle) {
      removePolyline();
      getActualPath();
    } else {
      removeActualPolyline();
    }
  }, [actualPathToggle, removeActualPolyline]);
  const createArrowPolyline = useCallback(() => {
    if (arrowPolylineRef.current) {
      arrowPolylineRef.current.setMap(null);
      arrowPolylineRef.current = null;
    }

    if (
      selectedTrip?.driverBeginLocation?.lat &&
      selectedTrip?.driverBeginLocation?.lng &&
      pickup &&
      mapRef.current
    ) {
      const arrowPolyline = new window.google.maps.Polyline({
        path: [
          {
            lat: selectedTrip.driverBeginLocation.lat,
            lng: selectedTrip.driverBeginLocation.lng,
          },
          { lat: pickup.lat, lng: pickup.lng },
        ],
        strokeColor: "#6F6E6E",
        strokeOpacity: 1.0,
        strokeWeight: 2,
        icons: [
          {
            icon: {
              path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
              scale: 3,
              strokeColor: "#6F6E6E",
            },
            offset: "50%",
          },
        ],
      });
      arrowPolyline.setMap(mapRef.current);
      arrowPolylineRef.current = arrowPolyline;

      return () => {
        if (arrowPolyline) {
          arrowPolyline.setMap(null);
        }
      };
    }
  }, [pickup, selectedTrip?.driverBeginLocation]);

  const renderPolylines = () => {
    if (!actualPathToggle || selectedTrip?.stage !== "TRIP_COMPLETED") {
      removeActualPolyline();
      createArrowPolyline();
      return;
    }

    const segmentPaths = [
      { key: "tripStarted", path: pathDetails.TRIP_STARTED, color: "#008400" },
      {
        key: "driverOnTheWay",
        path: pathDetails.DRIVER_ON_THE_WAY,
        color: "#0C534F",
      },
      {
        key: "driverArrived",
        path: pathDetails.DRIVER_ARRIVED,
        color: "#0C534F",
      },
      {
        key: "tripCompleted",
        path: pathDetails.TRIP_COMPLETED,
        color: "#008400",
      },
      { key: "tripEnd", path: pathDetails.TRIP_END, color: "#008400" },
    ];

    segmentPaths.forEach(({ key, path, color }) => {
      if (path.length > 0) {
        if (!actualPolylineRefs.current[key]) {
          const polyline = new window.google.maps.Polyline({
            path,
            strokeColor: color,
            strokeOpacity: 1.0,
            strokeWeight: 2,
          });
          polyline.setMap(mapRef.current);
          actualPolylineRefs.current[key] = polyline;
        } else {
          actualPolylineRefs.current[key].setPath(path);
          actualPolylineRefs.current[key].setOptions({ strokeColor: color });
        }
      } else {
        if (actualPolylineRefs.current[key]) {
          actualPolylineRefs.current[key].setMap(null);
          actualPolylineRefs.current[key] = null;
        }
      }
    });
  };

  // Call this function in the effect or wherever appropriate
  useEffect(() => {
    renderPolylines();
  }, [pathDetails, selectedTrip?.stage, actualPathToggle]); // Adjust dependencies as needed

  useEffect(() => {
    if (selectedTrip?.driverBeginLocation && pickup) {
      const cleanup = createArrowPolyline();
      return () => {
        if (cleanup) cleanup();
      };
    }
  }, [createArrowPolyline]);

  return (
    <>
      {loading && (
        <div className="tw-absolute tw-top-0 tw-left-0 tw-z-[1] tw-h-full tw-w-full tw-grid tw-place-content-center tw-bg-black/20">
          <LoadingSpinner />
        </div>
      )}
      <GoogleMapsWrapper
        containerStyle={containerStyle}
        options={options}
        center={center}
        onLoad={onLoad}
        zoom={mapDefaults.fleetViewMap.zoom}
        mapRef={mapRef}
      >
        {selectedTrip && (
          <>
            <Marker
              icon={{
                url: startPointIcon,
                scaledSize: new window.google.maps.Size(40, 40),
                origin: new window.google.maps.Point(0, 0),
                anchor: new window.google.maps.Point(20, 20),
              }}
              position={{ lat: pickup.lat, lng: pickup.lng }}
            />
            <Marker
              icon={{
                url: endPointIcon,
                scaledSize: new window.google.maps.Size(40, 40),
                origin: new window.google.maps.Point(0, 0),
                anchor: new window.google.maps.Point(20, 20),
              }}
              position={{ lat: drop.lat, lng: drop.lng }}
            />
            {/* driver points */}
            {/* driverBeginLocation */}
            {selectedTrip?.driverBeginLocation?.lat &&
              selectedTrip?.driverBeginLocation?.lng && (
                <Marker
                  icon={{
                    url: driverStartedIcon,
                    scaledSize: new window.google.maps.Size(40, 40),
                    origin: new window.google.maps.Point(0, 0),
                    anchor: new window.google.maps.Point(20, 20),
                  }}
                  position={{
                    lat: selectedTrip.driverBeginLocation.lat,
                    lng: selectedTrip.driverBeginLocation.lng,
                  }}
                />
              )}
          </>
        )}
        {renderPolylines}
        {/* {combinedPath?.map((point, index) => {
          if (index >= 10) return;
          return (
            <Marker
              key={index}
              position={{ lat: point.lat, lng: point.lng }}
              label={{
                text: String(index + 1), // Display the point number
                color: "white",
                fontWeight: "bold",
              }}
              icon={{
                path: window.google.maps.SymbolPath.CIRCLE,
                scale: 8, // Adjust size of the dot
                fillColor: "#0000FF", // Blue color for the dots
                fillOpacity: 1,
                strokeWeight: 0,
              }}
            />
          );
        })} */}
      </GoogleMapsWrapper>
    </>
  );
};

export default DriverViewMap;
