import { Button } from "@progress/kendo-react-buttons";
import { DropdownSearchSingleSelect } from "../../../utils/forms/NewFormComponents";

export const ClientSelection = ({
    clients,
    selectedClient,
    handleClientChange,
    onProceed,
  }) => {
    return (
      <>
        <label>Select Client</label>
        {clients.length > 0 ? (
          <DropdownSearchSingleSelect
            options={clients}
            textField="label"
            value={selectedClient || ""}
            onChange={handleClientChange}
            backupMessage="No Options Available"
            parentClass="tw-relative tw-w-full"
          />
        ) : (
          <input
            type="text"
            disabled
            placeholder="Loading..."
            className="tw-rounded-md tw-cursor-wait tw-outline-none tw-border tw-border-primaryBorder tw-px-2 tw-py-1 tw-w-full tw-animate-pulse"
          />
        )}
        <Button
          onClick={onProceed}
          disabled={!selectedClient}
          themeColor="primary"
          className="tw-mt-5 tw-w-full"
        >
          Next
        </Button>
      </>
    );
  };