import React, { useEffect, useState } from "react";
import {
  convertEnumToLabel,
  replaceNullValues,
  showErrorMessage,
  showToastMessage,
  stringToSentenceCase,
} from "../../utils/helpers";
import { FormInput } from "../../utils/forms/FormComponents";
import axiosService from "../../init/axios";
import { APIConfig } from "../../utils/constants/api.constants";
import { Button } from "@progress/kendo-react-buttons";
import LoadingPanel from "../common/LoadingPanel";
import { TripConfig } from "../../config/tripConfig";
import CouponSubmissionModal from "./CouponSubmissionModal";
import UserCouponLogs from "./UserCouponLogs";

const GrievanceCouponHomePage = () => {
  const [loading, setLoading] = useState(false);
  const [customerDetailsLoading, setCustomerDetailsLoading] = useState(false);
  const [selectedCoupon, setSelectedCoupon] = useState(TripConfig.coupons[0]);
  const [couponDetails, setCouponDetails] = useState({});
  const [mobileNumber, setMobileNumber] = useState("");
  const [customerDetails, setCustomerDetails] = useState({});
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    getCouponDetails();
  }, []);

  const getCouponDetails = async () => {
    setLoading(true);
    await axiosService
      .get(
        process.env.REACT_APP_PRICING_BASE_URL +
          APIConfig.couponManagement.getCouponDetails(selectedCoupon)
      )
      .then((data) => {
        if (data?.data) {
          setCouponDetails(data?.data);
        } else {
          setCouponDetails({});
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        showErrorMessage(error);
      });
  };

  const fetchCustomerDetails = async () => {
    setCustomerDetailsLoading(true);
    let payload = {
      mobileNumber: mobileNumber,
      couponCode: selectedCoupon,
    };
    await axiosService
      .put(
        process.env.REACT_APP_PRICING_BASE_URL +
          APIConfig.couponManagement.getCouponStatusOfCustomer,
        JSON.stringify(payload)
      )
      .then((data) => {
        setCustomerDetailsLoading(false);
        if (data?.data) {
          setCustomerDetails({
            ...data?.data,
            couponAssociationStatus: convertEnumToLabel(
              data?.data?.couponAssociationStatus
            ),
          });
        }
      })
      .catch((error) => {
        setCustomerDetailsLoading(false);
        showErrorMessage(error);
      });
  };

  const onActivateCoupon = async (reason, subReason) => {
    closeCouponModal();
    setCustomerDetails(true);
    let payload = {
      mobileNumber: mobileNumber,
      couponCode: selectedCoupon,
      reason: reason,
      subReason: subReason,
    };
    await axiosService
      .post(
        process.env.REACT_APP_PRICING_BASE_URL +
          APIConfig.couponManagement.issueNewCouponToCustomer,
        JSON.stringify(payload),
        { "Content-Type": "application/json" }
      )
      .then((data) => {
        setCustomerDetails({
          ...customerDetails,
          couponAssociationStatus: convertEnumToLabel("ACTIVE"),
        });
        showToastMessage("Coupon activated successfully");
        setCustomerDetailsLoading(false);
      })
      .catch((error) => {
        setCustomerDetails(false);
        showErrorMessage(error);
      });
  };

  const openCouponSubmissionModal = () => {
    setModalOpen(true);
  };

  const closeCouponModal = () => {
    setModalOpen(false);
  };


  return (
    <div className="coupon-homepage">
      <div className="coupon-discount-details">
        <h5>DISCOUNT DETAILS</h5>
        {loading ? (
          <LoadingPanel />
        ) : (
          <div className="row">
            <div className="col-sm-4">
              <div className="row">
                <div className="col-sm-6">Discount Value:</div>
                <div className="col-sm-6">
                  <b>{replaceNullValues(couponDetails?.couponValue)}</b>
                </div>
              </div>
              <br />
              <div className="row">
                <div className="col-sm-6">Discount Capping:</div>
                <div className="col-sm-6">
                  <b>{replaceNullValues(couponDetails?.maxDiscountAmount)}</b>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <br />
      <br />
      <h5>CUSTOMER DETAILS</h5>
      <div className="row">
        <div className="col-lg-2">
          <span>Search By Phone Number:</span>
        </div>
        <div className="col-lg-2">
          <FormInput
            value={mobileNumber}
            onChange={(e) => setMobileNumber(e.target.value)}
          />
        </div>
        <div className="col-lg-2">
          <Button themeColor={"primary"} onClick={fetchCustomerDetails}>
            Search
          </Button>
        </div>
      </div>
      <br />
      {customerDetailsLoading ? (
        <LoadingPanel />
      ) : (
        <>
          {Object.keys(customerDetails)?.length > 0 && (
            <div className="customer-details">
              {loading ? (
                <LoadingPanel />
              ) : (
                <div className="row">
                  <div className="col-sm-4">
                    <div className="row">
                      <div className="col-sm-6">Customer Name:</div>
                      <div className="col-sm-6">
                        <b>
                          {replaceNullValues(
                            stringToSentenceCase(customerDetails?.customerName)
                          )}
                        </b>
                      </div>
                    </div>
                    <br />
                    <div className="row">
                      <div className="col-sm-6">Discount Status:</div>
                      <div className="col-sm-6">
                        <b>
                          {replaceNullValues(
                            stringToSentenceCase(
                              customerDetails?.couponAssociationStatus
                            )
                          )}
                        </b>
                      </div>
                    </div>
                    <br />
                    {customerDetails?.couponAssociationStatus !== "Active" && (
                      <div className="row">
                        <div className="col-sm-6">
                          <Button
                            themeColor={"primary"}
                            onClick={openCouponSubmissionModal}
                          >
                            {customerDetails?.couponAssociationStatus ===
                            "Not Issued"
                              ? "Activate"
                              : "Re-Activate"}
                          </Button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
              <br />
              <UserCouponLogs mobileNumber={mobileNumber} couponCategory={"CC_COUPON"} />
            </div>
          )}
        </>
      )}
      {modalOpen && (
        <CouponSubmissionModal
          onClose={closeCouponModal}
          handleSubmit={onActivateCoupon}
        />
      )}
    </div>
  );
};

export default GrievanceCouponHomePage;
