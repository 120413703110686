import { PanelBar, PanelBarItem } from '@progress/kendo-react-layout';
import React, { useState } from 'react';
import PennyDropLogs from './PennyDropLogs';
import SupersetPanel from '../../../common/SupersetPanel';
import DriverAdditionalDetails from './DriverAdditionalDetails';

const DriverAdditionalDetailsHome = ({driverId,driverDetails}) => {
    const [expanded, setExpanded] = useState([""]);
    const driverLogsPayload = {
        "resources": [
            {
              "type": "dashboard",
              "id": process.env.REACT_APP_SUPERSET_DRIVER_LOGS_ID
            }
          ],
          "rls": [{ "clause": `driver_id = '\''${driverId}'\''` }], //Add driverId
    }

    const handleSelect = event => {
        if (event.expandedItems) {
            setExpanded(event.expandedItems);
        }
    };
    return (
        <div className='driver-additional-details-home'>
            <PanelBar expanded={expanded} onSelect={handleSelect} expandMode={'single'}>
                <PanelBarItem title='Additional Details'>
                    <div className="custom-template">
                        <DriverAdditionalDetails driverId={driverId} driverDetails={driverDetails} />
                    </div>
                </PanelBarItem>
                <PanelBarItem title='Penny Drop Logs'>
                    <div className="custom-template">
                        <PennyDropLogs driverId={driverId} />
                    </div>
                </PanelBarItem>
                {/* <PanelBarItem title='Driver Profile Change Logs'>
                    <div className="custom-template">
                        <SupersetPanel dashboardId={process.env.REACT_APP_SUPERSET_DRIVER_LOGS_ID} payload={driverLogsPayload} />
                    </div>
                </PanelBarItem> */}
            </PanelBar>
        </div>
    )
}

export default DriverAdditionalDetailsHome