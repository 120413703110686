import { Dialog } from '@progress/kendo-react-dialogs'
import React from 'react'

const DocumentPdfModal = ({ title, url, onClose }) => {
    return (
        <Dialog title={title} onClose={onClose}>
            <div style={{ maxWidth: '600px', maxHeight: '600px' }}>
                <iframe
                    src={url}
                    width="600"
                    height="600"
                    title="PDF Viewer"
                ></iframe>
            </div>
        </Dialog>
    )
}

export default DocumentPdfModal