export function ChevronUpIcon({ fill = "#101010" }) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.35347 10.3541C3.30702 10.4006 3.25187 10.4374 3.19117 10.4626C3.13047 10.4877 3.06542 10.5007 2.99972 10.5007C2.93402 10.5007 2.86897 10.4877 2.80827 10.4626C2.74758 10.4374 2.69243 10.4006 2.64597 10.3541C2.59952 10.3077 2.56267 10.2525 2.53752 10.1918C2.51238 10.1311 2.49944 10.0661 2.49944 10.0004C2.49944 9.9347 2.51238 9.86964 2.53752 9.80894C2.56267 9.74825 2.59952 9.6931 2.64597 9.64664L7.64597 4.64664C7.69241 4.60015 7.74755 4.56328 7.80825 4.53811C7.86895 4.51295 7.93401 4.5 7.99972 4.5C8.06543 4.5 8.13049 4.51295 8.19119 4.53811C8.25189 4.56328 8.30704 4.60015 8.35347 4.64664L13.3535 9.64664C13.4473 9.74046 13.5 9.86771 13.5 10.0004C13.5 10.1331 13.4473 10.2603 13.3535 10.3541C13.2597 10.448 13.1324 10.5007 12.9997 10.5007C12.867 10.5007 12.7398 10.448 12.646 10.3541L7.99972 5.70727L3.35347 10.3541Z"
        fill={fill}
      />
    </svg>
  );
}