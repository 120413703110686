import { FormInput } from "../../utils/forms/FormComponents";
import { Input } from "@progress/kendo-react-inputs";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { getFormattedDate } from "../../utils/helpers";
import { TIME_SLOTS } from "./shared/trip-enums";


export default function AddTaskForm({
  formDetails,
  setFormDetails,
  isPickupForm,
  checkListData = [],
}) {
  const filterTimeSlots = () => {
    const selectedDate = isPickupForm
      ? formDetails?.pickupDetails?.date
      : formDetails?.dropDetails?.date;

    if (!selectedDate) return TIME_SLOTS;

    const selectedDateTime = new Date(`${selectedDate}T00:00:00`);
    const currentDateTime = new Date();
    const twoHoursLater = new Date(
      currentDateTime.getTime() + 2 * 60 * 60 * 1000
    );

    if (selectedDateTime.toDateString() === currentDateTime.toDateString()) {
      return TIME_SLOTS.filter((slot) => {
        const slotDateTime = new Date(`${selectedDate}T${slot.value}`);
        return slotDateTime >= twoHoursLater;
      });
    } else if (selectedDateTime > currentDateTime) {
      return TIME_SLOTS;
    }

    return [];
  };

  const handleSaveDetails = (key, value) => {
    let isValid = true;
    let validationMessage = "";

    if (key === "customerPhoneNumber" || key === "clientNumber") {
      const phoneRegex = /^[0-9]{10}$/;
      if (!phoneRegex.test(value)) {
        isValid = false;
        validationMessage = "Please enter a valid 10-digit phone number.";
      }
    }

    // Update form details and validation state
    setFormDetails((prevDetails) => ({
      ...prevDetails,
      [isPickupForm ? "pickupDetails" : "dropDetails"]: {
        ...prevDetails[isPickupForm ? "pickupDetails" : "dropDetails"],
        [key]: value,
        [`${key}Valid`]: isValid,
        [`${key}ValidationMessage`]: validationMessage,
      },
    }));
  };

  const selectedTimeSlot = isPickupForm
    ? TIME_SLOTS.find(
        (item) => item.value === formDetails?.pickupDetails?.timeSlot
      )
    : TIME_SLOTS.find(
        (item) => item.value === formDetails?.dropDetails?.timeSlot
      );

  return (
    <>
      <div className="tw-mt-5">
        <h5 className="tw-text-base tw-font-semibold">Customer details</h5>
        <div className="tw-grid tw-grid-cols-1 tw-gap-5 custom-border-primary tw-p-3 tw-rounded-md">
          <div>
            <label className="tw-text-sm tw-block tw-leading-none tw-text-gray-700 tw-font-medium">
              Customer Name
            </label>

            <FormInput
              value={
                isPickupForm
                  ? formDetails?.pickupDetails?.customerName
                  : formDetails?.dropDetails?.customerName
              }
              placeholder="Customer Name"
              name="customerName"
              maxLength={30}
              onChange={(e) =>
                handleSaveDetails("customerName", e.target.value)
              }
            />
          </div>

          <div>
            <label className="tw-text-sm tw-block tw-leading-none tw-text-gray-700 tw-font-medium">
              Enter primary number
            </label>

            <FormInput
              name="customerPhoneNumber"
              value={
                isPickupForm
                  ? formDetails?.pickupDetails?.customerPhoneNumber
                  : formDetails?.dropDetails?.customerPhoneNumber
              }
              valid={
                isPickupForm
                  ? formDetails?.pickupDetails?.customerPhoneNumberValid
                  : formDetails?.dropDetails?.customerPhoneNumberValid
              }
              validationMessage={
                isPickupForm
                  ? formDetails?.pickupDetails
                      ?.customerPhoneNumberValidationMessage
                  : formDetails?.dropDetails
                      ?.customerPhoneNumberValidationMessage
              }
              placeholder="99999 99999"
              maxLength={10}
              onChange={(e) =>
                handleSaveDetails("customerPhoneNumber", e.target.value)
              }
            />
          </div>

          <div>
            <label className="tw-text-sm tw-block tw-leading-none tw-text-gray-700 tw-font-medium">
              Enter transaction time (minutes)
            </label>

            <FormInput
              name="txnTimeMinutes"
              value={
                isPickupForm
                  ? formDetails?.pickupDetails?.txnTimeMinutes
                  : formDetails?.dropDetails?.txnTimeMinutes
              }
              valid={
                isPickupForm
                  ? formDetails?.pickupDetails?.txnTimeMinutesValid
                  : formDetails?.dropDetails?.txnTimeMinutesValid
              }
              // validationMessage={
              //   isPickupForm
              //     ? formDetails?.pickupDetails?.txnTimeMinutesValidationMessage
              //     : formDetails?.dropDetails?.txnTimeMinutesValidationMessage
              // }
              placeholder="30"
              maxLength={3}
              onChange={(e) =>
                handleSaveDetails("txnTimeMinutes", e.target.value)
              }
            />
          </div>
          {/* <div>
            <label className="tw-text-sm tw-block tw-leading-none tw-text-gray-700 tw-font-medium">
              Enter secondary number
            </label>
            <FormInput
              value={
                isPickupForm
                  ? formDetails?.pickupDetails?.customerSecondaryPhoneNumber
                  : formDetails?.dropDetails?.customerSecondaryPhoneNumber
              }
              placeholder="99999 99999"
              onChange={(e) =>
                handleSaveDetails(
                  "customerSecondaryPhoneNumber",
                  e.target.value
                )
              }
            />
          </div> */}

          <div>
            <label className="tw-text-sm tw-block tw-leading-none tw-text-gray-700 tw-font-medium">
              QC Url
            </label>
            <FormInput
              type="url"
              value={
                isPickupForm
                  ? formDetails?.pickupDetails?.qcUrl
                  : formDetails?.dropDetails?.qcUrl
              }
              placeholder="QC URL"
              onChange={(e) => handleSaveDetails("qcUrl", e.target.value)}
            />
          </div>
          {/* timeslot only for pickup rn */}
          {isPickupForm && (
            <div>
              <label className="tw-text-sm tw-block tw-leading-none tw-text-gray-700 tw-font-medium">
                Choose Slot
              </label>
              <div className="tw-grid tw-grid-cols-2 tw-gap-4">
                <Input
                  type="date"
                  min={getFormattedDate()}
                  className="tw-max-w-fit"
                  placeholder="Select date"
                  value={
                    isPickupForm
                      ? formDetails?.pickupDetails?.date
                      : formDetails?.dropDetails?.date
                  }
                  onChange={(e) => {
                    handleSaveDetails("date", e.target.value);
                  }}
                />
                <DropDownList
                  placeholder="Select time slot"
                  value={selectedTimeSlot?.label || "Time Slot"}
                  data={filterTimeSlots().map((item) => item.label)}
                  onChange={(e) => {
                    const timeSlotValue = TIME_SLOTS.find(
                      (slot) => slot.label === e.target.value
                    );
                    handleSaveDetails("timeSlot", timeSlotValue.value);
                  }}
                />
              </div>
            </div>
          )}
        </div>
      </div>
      {/*  */}
      <h5 className="tw-text-base tw-font-semibold tw-mt-5">Checklist</h5>
      <DropDownList
        backupMessage="No Options Available"
        parentClass="tw-relative tw-w-full tw-text-sm"
        textField="checklistId"
        data={
          isPickupForm
            ? checkListData.filter((item) => item.checklistType === "PICKUP")
            : checkListData.filter((item) => item.checklistType === "DROP")
        }
        value={
          isPickupForm
            ? formDetails?.pickupDetails?.checklistId
            : formDetails?.dropDetails?.checklistId
        }
        onChange={(e) => {
          handleSaveDetails("checklistId", e.target.value);
        }}
      />

      {/*  */}
      <h5 className="tw-text-base tw-font-semibold tw-mt-5">
        Client POC details
      </h5>
      <div className="tw-grid tw-grid-cols-1 tw-gap-5 custom-border-primary tw-p-3 tw-rounded-md">
        <div>
          <label className="tw-text-sm tw-block tw-leading-none tw-text-gray-700 tw-font-medium">
            Client Name
          </label>
          <FormInput
            maxLength={30}
            value={
              isPickupForm
                ? formDetails?.pickupDetails?.clientName
                : formDetails?.dropDetails?.clientName
            }
            placeholder="Client name"
            onChange={(e) => handleSaveDetails("clientName", e.target.value)}
          />
        </div>
        <div>
          <label className="tw-text-sm tw-block tw-leading-none tw-text-gray-700 tw-font-medium">
            Client Number
          </label>
          {/* <FormInput
            value={
              isPickupForm
                ? formDetails?.pickupDetails?.clientNumber
                : formDetails?.dropDetails?.clientNumber
            }
            placeholder="Client number"
            onChange={(e) => handleSaveDetails("clientNumber", e.target.value)}
          /> */}
          <FormInput
            name="clientNumber"
            maxLength={10}
            value={
              isPickupForm
                ? formDetails?.pickupDetails?.clientNumber
                : formDetails?.dropDetails?.clientNumber
            }
            valid={
              isPickupForm
                ? formDetails?.pickupDetails?.clientNumberValid
                : formDetails?.dropDetails?.clientNumberValid
            }
            validationMessage={
              isPickupForm
                ? formDetails?.pickupDetails?.clientNumberValidationMessage
                : formDetails?.dropDetails?.clientNumberValidationMessage
            }
            placeholder="99999 99999"
            onChange={(e) => handleSaveDetails("clientNumber", e.target.value)}
          />
        </div>
      </div>
    </>
  );
}

